import * as React from "react"

import { SkillsTable } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

const skillsListingScreen: React.SFC<any> = (props): React.ReactElement<any> => {

  const renderTable = () => (<SkillsTable />)
  return (
    <ConnectListingScreen
      {...props}
      renderListComponent={renderTable}
    />
  )
}

export const SkillsListingScreen: React.SFC<any> = skillsListingScreen
