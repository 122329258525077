import * as React from "react"
import { Link } from "react-router-dom"
import { Button, Row } from "reactstrap"

interface IProps {
  buttonText: string,
  linkTo: string,
}

const BackButton = (props: IProps) => {

  return (
    <Link
      to={props.linkTo}
    >
      <Button
        type="submit"
      >
        {props.buttonText}
      </Button>
    </Link>
  )
}

export { BackButton }
