import * as React from "react"
import {
  EAssigned,
  EHireable,
  FilterSideBar,
  IField as IStateField,
} from "~/components/presenters/FilterBar/FilterSideBar"
import { ISelectItem } from "~/components/presenters/InputFields/AutoCompleteMulti"

interface IState {
  [IStateField.bioOr]: string,
  [IStateField.bioAnd]: string,
  [IStateField.location]: string,
  [IStateField.skills]: Array<string>,
  [IStateField.assigneeFilter]: EAssigned,
  [IStateField.isHireable]: EHireable,
}

interface IProps {
  isLoading: boolean,
  handleChange: (object: object) => void,
  onSkillSelect: (e: any) => void,
  skillsResultsMapper: ({ data: { data } }) => any,
  handleReset: () => void,
  fetchList: () => void,
  query: object,
  location: any,
  selectedSkills?: Array<ISelectItem> | null,
  page: number,
}

class FilterSideBarContainer extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  public preventSubmit(e) {
    e.preventDefault()
  }

  public handleKeyPress(event) {
    if (event.key === "Enter") {
      this.props.fetchList()
    }
  }

  public render(): React.ReactNode {
      return (
        <FilterSideBar
          handleChange={this.props.handleChange}
          onSkillSelect={this.props.onSkillSelect}
          handleKeyPress={this.handleKeyPress}
          handleReset={this.props.handleReset}
          preventSubmit={this.preventSubmit}
          isLoading={this.props.isLoading}
          fetchList={this.props.fetchList}
          skillsResultsMapper={this.props.skillsResultsMapper}
          query={this.props.query}
          selectedSkills={this.props.selectedSkills}
        />
      )
    }
  }

export { FilterSideBarContainer }
