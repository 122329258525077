import React from 'react'
import { authState } from '~/samx/states/auth'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'


const AssignButton = (props) => {

  const assignee = props.contributor.assignee || {}
  const assignedMe = authState.id === assignee.id
  const assignedNone = !assignee.id || !assignee.firstName
  const map = {
    none: {
      children: "Assign to Me",
      color: "warning",
      onClick: () => props.handleAssignToMe(props.contributor.id),
    },
    other: {
      color: "dark",
      children: (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>{assignee.firstName}</span>
          <i
            className="fa fa-times-circle"
            onClick={() => props.handleUnassign(props.contributor.id)}
          />
        </div>
      ),
    },
    mine: {
      children: "Unassign",
      color: "danger",
      onClick: () => props.handleUnassign(props.contributor.id),
    },
  }

  const type = assignedNone ? "none" : (assignedMe ? "mine" : "other")

  return (
    <React.Fragment>
      <Badge {...map[type]} className="assign-btn" />
    </React.Fragment>
  )
}

AssignButton.propTypes = {
  contributor: PropTypes.object,
  handleAssignToMe: PropTypes.func,
  handleUnassign: PropTypes.func,
}


export { AssignButton }
