import { capitalize, toLower } from "lodash"
import * as React from "react"
import { Col, FormGroup, Label } from "reactstrap"

interface IDropDownProps {
  name: string,
  labelText: string,
  value: string | object | boolean,
  options: Array<string>,
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => any,
}

const formatOption = (option: string) => capitalize(toLower(option.replace("_", "-")))

export const DropDown: React.FC<IDropDownProps> = (props) => {
  const options = props.options.map( (option) => {
    return <option key={option} value={option}>{formatOption(option)}</option>
  })

  return (
    <FormGroup>
      <Col>
        <Label className="section-title">{props.labelText}</Label>
        <select
          className="form-control"
          id={`dropDown-${props.name}`}
          name={props.name}
          onChange={props.handleChange}
          value={props.value as any}
        >
          {options}
        </select>
      </Col>
    </FormGroup>
  )
}
