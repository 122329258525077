import { flow, omit } from "lodash/fp"
import normalizeUrl from "normalize-url"
import { HUBSPOT_DEAL_URL } from "~/config/env"
import { convertToISODate, filterUndefineds, mapPropIfExists, safeMap } from "~/helpers/operations"

export const ProfileOutgoing = (profile) => {
  const remote = profile.isRemoteOnly === "Yes" ? true : false
  const longTerm = profile.isLongTermProject === "Yes" ? true : false

  const mapProfilePropIfExists = mapPropIfExists(profile)

  const mapExperiences = safeMap(
    (exp) => ({
      id: exp.id || undefined,
      company: {
        id: exp.companyId,
      },
      jobTitle: exp.jobTitle,
      startDate: exp.startDate,
      endDate: exp.endDate,
      description: exp.description,
      location: {
        id: exp.cityId,
      },
    }),
  )

  const mapEducations = safeMap(
    (education) => ({
      id: education.id || undefined,
      school: {
        id: education.schoolId,
      },
      degree: education.degree,
      startDate: education.startDate,
      endDate: education.endDate,
      description: education.description,
      location: {
        id: education.cityId,
      },
    }),
  )

  const mapPortfolios = safeMap(
    (portfolio) => ({
      id: portfolio.id || undefined,
      title: portfolio.title,
      description: portfolio.description,
      href: portfolio.href,
      file: portfolio.file,
    }),
  )

  const mapSpokenLanguages = safeMap(
    (language) => ({
      id: language.id || undefined,
      language: {
        id: language.languageId,
      },
      proficiency: language.proficiency,
    }),
  )

  const mapSkills = safeMap(
    (skill, index) => ({
      id: skill.id || undefined,
      skill: {
        id: skill.skillId,
      },
      startedAt: (!!skill.startedAt) ? convertToISODate(skill.startedAt) : "",
      order: skill.order,
    }),
  )

  const mapCommunications = safeMap(
    (communication) => ({
      id: communication.id || undefined,
      value: communication.value,
      type: communication.type,
    }),
  )

  const experiences = flow(mapProfilePropIfExists("experiences"), filterUndefineds)(mapExperiences)
  const educations = flow(mapProfilePropIfExists( "educations"), filterUndefineds)(mapEducations)
  const portfolios = flow(mapProfilePropIfExists( "portfolios"), filterUndefineds)(mapPortfolios)
  const spokenLanguages = flow(mapProfilePropIfExists("spokenLanguages"), filterUndefineds)(mapSpokenLanguages)
  const skills = flow(mapProfilePropIfExists("skills"), filterUndefineds)(mapSkills)
  const communications = flow(mapProfilePropIfExists("communications"), filterUndefineds)(mapCommunications)
  const convertedProfile = {
    ...profile,
    id: profile.id || null,
    slug: profile.slug.replace(/-+$/, ""),
    location: {
      id: profile.location.id,
    },
    locationVat: {
      id: profile.locationVat.id,
    },
    isRemoteOnly: remote,
    isLongTermProject: longTerm,
    experiences,
    educations,
    portfolios,
    spokenLanguages,
    skills,
    testedSkills: profile.testedSkills,
    communications,
    hubspotDealId: !!profile.hubspotDealUrl &&
      normalizeUrl(profile.hubspotDealUrl).replace(`${HUBSPOT_DEAL_URL}/`, "") || "",
  }

  return omit(["hubspotDealUrl"])(convertedProfile) as any
}
