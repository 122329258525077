import * as React from "react"
import Tooltip from "react-tooltip-lite"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap"
import { AutoCompleteMulti } from "~/components/presenters/InputFields/AutoCompleteMulti"
import { queryFields, queryResource } from "~/services/api"

export enum EAssigned {
  filterAll = "FILTER_ALL",
  filterUnassigned = "FILTER_UNASSIGNED",
  filterAssignedMe = "FILTER_ASSIGNED_ME",
}

export enum EHireable {
  filterNo = "HIREABLE_NO",
  filterYes = "HIREABLE_YES",
  filterAll = "HIREABLE_ALL",
}

export enum EType {
  filterUnknown = "CONTRIBUTOR_TYPE_UNKNOWN",
  filterFreelancer = "CONTRIBUTOR_TYPE_FREELANCER",
  filterEmployee = "CONTRIBUTOR_TYPE_FULL_TIME_EMPLOYEE",
  filterAll = "CONTRIBUTOR_TYPE_ALL",
}

export enum IField {
  bioOr = "bioOr",
  bioAnd = "bioAnd",
  name = "name",
  location = "location",
  score = "score",
  skills = "skills",
  assigneeFilter = "assigneeFilter",
  isHireable = "isHireable",
  page = "page",
  sortBy = "sortBy",
  isAscending= "isAscending",
}

const FilterSideBar = (props) => {

  return (
    <Card>
      <Form onSubmit={props.preventSubmit}>
        <CardHeader>
          <strong>Search Filters</strong>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="e.g. John Doe"
                  onChange={props.handleChange}
                  onKeyPress={props.handleKeyPress}
                  disabled={props.isLoading}
                  defaultValue={props.query.name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type="text"
                  name="location"
                  placeholder="e.g. Berlin,London,Paris"
                  onChange={props.handleChange}
                  onKeyPress={props.handleKeyPress}
                  disabled={props.isLoading}
                  defaultValue={props.query.location}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Assigned Status</Label>
                <Input
                  type="select"
                  name="assigneeFilter"
                  onChange={props.handleChange}
                  disabled={props.isLoading}
                  value={props.query.assigneeFilter}
                >
                  <option value={EAssigned.filterAll}>All</option>
                  <option value={EAssigned.filterUnassigned}>Unassigned</option>
                  <option value={EAssigned.filterAssignedMe}>Assigned to Me</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>Only Hireable?</Label>
                <Input
                  type="select"
                  name="isHireable"
                  onChange={props.handleChange}
                  disabled={props.isLoading}
                  value={props.query.isHireable}
                >
                  <option value={EHireable.filterAll}>Any</option>
                  <option value={EHireable.filterNo}>No</option>
                  <option value={EHireable.filterYes}>Yes</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Skills</Label>
                <AutoCompleteMulti
                  resourceName="contributions/languages"
                  resourceField="name"
                  stateName=""
                  resourceQueryMethod={queryResource}
                  queryFields={queryFields}
                  onSelected={props.onSkillSelect}
                  resultsMapper={props.skillsResultsMapper}
                  isMulti={true}
                  values={props.selectedSkills}
                />
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>
                  <React.Fragment key="bio_search">
                    <Tooltip
                      content={
                        <p>
                          This search works with Logical OR functionality. Search terms should be separated by a comma.
                          <br />For example: freelance, freelancer, data
                      </p>
                      }
                      direction="down"
                      background="linear-gradient(#3c26de, #291a97)"
                      color="white"
                    >
                      Search Bio (OR)&nbsp;<i className="fa fa-question-circle" />
                    </Tooltip>
                  </React.Fragment>
                </Label>
                <Input
                  type="text"
                  name="bioOr"
                  placeholder="Search for terms in Bio..."
                  onChange={props.handleChange}
                  onKeyPress={props.handleKeyPress}
                  disabled={props.isLoading}
                  defaultValue={props.query.bioOr}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <FormGroup>
                <Label>
                  <React.Fragment key="bio_search_and">
                    <Tooltip
                      content={
                        <p>
                          This search works with Logical AND functionality. Search terms should be separated by a comma.
                          <br/>For example: React, Angular
                      </p>
                      }
                      direction="down"
                      background="linear-gradient(#3c26de, #291a97)"
                      color="white"
                    >
                      Search Bio (AND)&nbsp;<i className="fa fa-question-circle" />
                    </Tooltip>
                  </React.Fragment>
                </Label>
                <Input
                  type="text"
                  name="bioAnd"
                  placeholder="Search for terms in Bio..."
                  onChange={props.handleChange}
                  onKeyPress={props.handleKeyPress}
                  disabled={props.isLoading}
                  defaultValue={props.query.bioAnd}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Button
            type="button"
            size="sm"
            color="success"
            onClick={props.fetchList}
          >
            <i className="fa cui-magnifying-glass" /> Search
          </Button>
          <Button
            type="reset"
            size="sm"
            color="danger"
            onClick={props.handleReset}
            style={{ marginLeft: "5px" }}
          >
            <i className="fa fa-ban" /> Reset
          </Button>
        </CardFooter>
      </Form>
    </Card>
  )
}

export { FilterSideBar }
