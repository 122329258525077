import { Propose } from "samx"
import { IQueryMeta } from "~/common/interfaces"
import { ISearchBarLocalState } from "~/components/presenters/InputFields/SearchBar"
import { AddQuery } from "~/samx/proposals/queries"

const proposer = Propose("queries")

const saveQueryFactory = <T>(key: string) => (query: T) => proposer(AddQuery(query as any, key))
const saveQueryParams = saveQueryFactory<ISearchBarLocalState>("params")
const saveQueryMeta = saveQueryFactory<IQueryMeta>("meta")

export {
  saveQueryParams,
  saveQueryMeta,
}
