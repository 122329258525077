import React from 'react'
import PropTypes from 'prop-types'

const RepoTableRow = (props) => {
  const linesAdded = props.data.totalLinesAdded ? `+${props.data.totalLinesAdded}` : ""
  const linesRemoved = props.data.totalLinesRemoved ? `-${props.data.totalLinesRemoved}` : ""

  return (
    <tr>
      <td className="dev-row">
        <a href={props.data.repository.link}>{props.data.repository.id}</a>
      </td>
      <td className="dev-row">{props.data.repository.githubStars}</td>
      <td className="dev-row" style={{color: "#3AC47D"}}>{linesAdded}</td>
      <td className="dev-row" style={{color: "red"}}>{linesRemoved}</td>
      <td className="dev-row">{props.data.repository.mainLanguage}</td>
      <td className="dev-row">{props.data.repository.githubForks}</td>
      <td className="dev-row">{props.data.repository.githubWatchers}</td>
    </tr>
  )
}

RepoTableRow.propTypes = {
  data: PropTypes.object,
}

export { RepoTableRow }
