import { map, uniq } from "lodash"
import * as React from "react"
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import * as logo from "~/assets/expertlead-logo.png"
import { Apache2, Bsd2, Bsd3, Cco1, CoreUiIconsPro, FontAwesomeFree, Mit } from "~/components/screens/Licenses/templates"
import * as packages from "./packages.json"

const transformDataForRendering = (data) => data.reduce((acc, curr) => {
  const { name, version, license, copyrights } = curr

  const copyrightsTrimmed = map(copyrights, (_, key) => key)

  const item = {
    name,
    version,
    // @ts-ignore
    copyrights: uniq(copyrightsTrimmed),
  }

  acc[license] = [...(acc[license] || []), item]
  return acc
}, {})

const renderPackagesTable = (group) => (
  <Table striped={true} borderless={true}>
    <thead>
    <tr>
      <th>Component Name</th>
      <th>Version</th>
      <th>Copyright</th>
    </tr>
    </thead>

    <tbody>
    {map(group, (item) => {
      const { name, version, copyrights } = item

      return (
        <tr key={JSON.stringify(item)}>
          <td>{name}</td>
          <td>{version}</td>
          <td>{copyrights.join(", ")}</td>
        </tr>
      )
    })}
    </tbody>
  </Table>
)

const renderLicenseSection = ({ license, data }) => (
  <div key={license} className={"main-section"}>
    <h3>{license}</h3>

    {renderPackagesTable(data)}

    <div className="license-content">
      {renderLicenseContent(license)}
    </div>
  </div>
)

const renderLicenseContent = (license) => {
  const contentMap = {
    "MIT": Mit,
    "BSD 2-Clause": Bsd2,
    "BSD-3-Clause": Bsd3,
    "Font Awesome Free License": FontAwesomeFree,
    "Apache-2.0": Apache2,
    "CC0-1.0": Cco1,
    "CoreUI Icons Free License": CoreUiIconsPro,
  }

  const Component = contentMap[license]

  return <Component />
}

const Licenses = (): React.ReactElement<any> => {
  const ossData = transformDataForRendering(packages)

  return (
    <Container className={"third-party"}>
      <p className="logo-container">
        <a href="/">
          <img src={logo} alt=""/>
        </a>
      </p>

      <Row>
        <Col xs={12}>
          <Card>
            <CardBody>
              <h2 className={"main-heading"}>Copyright notices and licenses for Open Source Software</h2>

              <p>
                The internet page <strong>https://admin.expertlead.io</strong> uses Open Source Software (OSS).
                Insofar as the applicable license terms specify an obligation to publish, the copyright notices and
                license texts referring to the OSS components used are shown below:
              </p>

              {map(ossData, (data, license) => renderLicenseSection({ data, license }))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export { Licenses }
