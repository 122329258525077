import { Model } from "samx"

Model("collections", {
  schema: {
    places: [],
    profiles: [],
    skills: [],
    notifications: [],
    spokenLanguages: [],
    invitations: [],
    users: [],
  },
})
