import * as React from "react"

import { get } from "lodash"
import { capitalize, flow } from "lodash/fp"
import { Link } from "react-router-dom"
import { Button, CardBody } from "reactstrap"
import { View } from "samx"
import { statusLabelMap } from "~/common/interfaces"
import { Datatable, PlaceSearchBar } from "~/components/presenters"
import { buildQueryString } from "~/helpers"
import { getPlaces } from "~/samx/actions/collections"
import { listFilteredPlaces } from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"

import { PAGINATION_RESULTS_PER_PAGE } from "~/config/constants"

class PlacesTableBase extends React.Component<any, any> {
  constructor(props: any) {
    super(props as any)

    this.state = {
      activePage: 1,
      perPage: PAGINATION_RESULTS_PER_PAGE,
      total: 0,
    }

    this.onPageChange = this.onPageChange.bind(this)
  }

  public async componentDidMount() {
    const res = await getPlaces()
    const total = get(res, "total", 0)
    this.setState({
      total,
    })
  }

  public async onPageChange(page) {
    this.setState({
      activePage: page,
    })

    const obj = {
      page,
      perPage: this.state.perPage,
    }

    const query = buildQueryString(obj)
    const res = await listFilteredPlaces(query)
    this.setState({
      total: res.total,
    })
  }

  public onRequest = (query) => {
    listFilteredPlaces(query)
  }

  public render() {
    const datatableProps = {
      list: connectState.places,
      columns: ["name", "location", "status", "controls"],
      columnsHeads: ["Name", "Location", "Status", " "],
      renderColumns: {
        location: (_, item) => {
          const accent = get(item, "city.accent", "")
          const country = get(item, "city.country.name", "")

          return accent && `${capitalize(accent)}, ${capitalize(country)}`
        },
        status: (_, item) => statusLabelMap[item.status],
        controls: (_, item) => (
          <Link to={`/connect/places/edit/${item.id}`}>
            <i className="fa fa-edit fa-2x" />
          </Link>
        ),
      },
      renderNewButton: () => (
        <CardBody>
          <Link to="/connect/place/new">
            <Button size="md" color="success" className="form__button">
              New Place <i className="fa fa-plus-square" />
            </Button>
          </Link>
          <PlaceSearchBar onSearch={this.onRequest} />
        </CardBody>
      ),
      pagination: {
        activePage: this.state.activePage,
        itemsCountPerPage: this.state.perPage,
        totalItemsCount: this.state.total,
        itemClass: "page-item",
        linkClass: "page-link",
        onChange: this.onPageChange,
      },
    }

    return (
      <React.Fragment>
        <Datatable {...datatableProps} />
      </React.Fragment>
    )
  }
}

export const PlacesTable = flow(View)(PlacesTableBase) as any
