import { request } from "~/services/api/resources/request"

import { IProfile } from "~/common/interfaces/profile"
import { ProfileOutgoing } from "~/services/api/adapters/ProfileOutgoing"

const listProfiles = async () => request({ method: "GET", url: "profile" })

const listFilteredProfiles = async (query: string) => request({ method: "GET", url: `profile?${query}` })

const fetchProfile = async (id: string) => request({
  method: "GET",
  url: `admin/profile/${id}`,
})

const addProfile = async (profileData: IProfile) => request({
  method: "POST",
  url: "profile",
  data: ProfileOutgoing(profileData),
})

const updateProfile = async (profileData: IProfile) => request({
  method: "PATCH",
  url: `admin/profile/${profileData.id}`,
  data: ProfileOutgoing(profileData),
})

const getProfileChangelog = async (profileId: string) => request({
  method: "GET",
  url: `changelog/resource/${profileId}`,
})

const deleteProfile = async (id: string) => request ({
  method: "DELETE",
  url: `profile/${id}`,
})

export {
  addProfile,
  getProfileChangelog,
  updateProfile,
  fetchProfile,
  listProfiles,
  listFilteredProfiles,
  deleteProfile,
}
