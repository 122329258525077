import * as H from "history"
import * as React from "react"

import { flow, get, isUndefined, negate, omit } from "lodash/fp"
import { Redirect, withRouter } from "react-router-dom"
import { toJS } from "samx"
import { computed, decorate, when } from "samx"
import { View } from "samx"
import { profileformValidation } from "~/common/validation-schema/profileform"
import {
  IProfileFormProps,
  ProfileForm,
} from "~/components/presenters"
import {
  Spinner,
} from "~/components/presenters"
import { mapCollection, safeMap } from "~/helpers/operations"
import {
  getProfile,
  getProfiles,
  updateProfile,
} from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"
import { getInitialState, IProfileContainerState, ProfileContainer } from "./common"

const PreValidationProfile = (profile: any) => {
  const mapExperience = safeMap((experience: any) => ({
      id: experience.id,
      companyId: experience.companyId,
      cityId: experience.cityId,
      jobTitle: experience.jobTitle,
      endDate: experience.endDate,
      startDate: experience.startDate,
      description: experience.description,
    }),
  )

  const mapEducation = safeMap((education: any) => ({
      id: education.id,
      degree: education.degree,
      schoolId: education.schoolId,
      cityId: education.cityId,
      endDate: education.endDate,
      startDate: education.startDate,
      description: education.description,
    }),
  )

  const mapSkill = safeMap((skill: any) => ({
      id: skill.id,
      skillId: skill.skillId,
      startedAt: skill.startedAt,
      order: skill.order,
    }),
  )

  const educations = mapCollection(profile.educations)(mapEducation)
  const skills = mapCollection(profile.skills)(mapSkill)
  const experiences = mapCollection(profile.experiences)(mapExperience)

  return {
    ...profile,
    experiences,
    educations,
    skills,
  }
}

class EditProfileFormClass extends ProfileContainer {
  public state: IProfileContainerState = getInitialState()
  public props: {
    profileId: string,
    location: H.Location,
  }

  constructor(props) {
    super(props)
  }

  get doesProfileExist() {
    return this.props.profileId in connectState.profilesObj
  }

  get isProfileReady() {
    return flow(
      get(this.props.profileId),
      negate(isUndefined),
    )(connectState.profilesObj)
  }

  get profile() {
    return toJS(get(this.props.profileId)(connectState.profilesObj))
  }

  public copyReactiveProfileWhenReady() {
    when(
      () => this.isProfileReady,
      () => {
        this.setState({
          ...this.state,
          profile: this.profile,
        })
      },
    )
  }

  public async componentDidMount() {
    await getProfile(this.props.profileId)

    this.copyReactiveProfileWhenReady()
    this.setState({
      isLoaded: true,
    })
  }

  public prepDataToSubmit() {
    const profile = flow(
      PreValidationProfile,
      omit(["location.country", "location.accent"]),
    )(this.state.profile)

    return profile
  }

  public async saveData(profileData: any) {
    this.setState({
      isSubmitEnabled: false,
    })

    const profile = await updateProfile(profileData as any)

    this.setState({
      isSubmitEnabled: true,
    })

    this.setFullProfile(profile)
  }

  public prepareProps() {
    const onSubmit = this.onSubmit.bind(this)
    const onInputChange = this.onInputChange.bind(this)
    const onFocusChange = this.onFocusChange.bind(this)
    const onNumberInputChange = this.onNumberInputChange.bind(this)
    const onChildInput = this.onChildInput.bind(this)
    const saveRef = this.saveRef.bind(this)
    const onInputPictureAdded = this.onInputPictureAdded.bind(this)
    const onInputPictureRemoved = this.onInputPictureRemoved.bind(this)
    const onAvailabilityChange = this.onAvailabilityChange.bind(this)

    return {
      profile: this.state.profile,
      onSubmit,
      onInputChange,
      onFocusChange,
      onNumberInputChange,
      onChildInput,
      isSubmitEnabled: this.state.isSubmitEnabled,
      validationError: this.state.validationError,
      isSubmitSuccessful: this.state.isSubmitSuccessful,
      validationSchema: profileformValidation,
      saveRef,
      onInputPictureAdded,
      onInputPictureRemoved,
      onAvailabilityChange,
    } as IProfileFormProps
  }

  public render() {

    if (!this.isProfileReady || !this.state.isLoaded) {
      return <Spinner />
    }

    if (this.state.isLoaded && !this.doesProfileExist) {
      return <Redirect to="/404" />
    }

    return <ProfileForm {...this.prepareProps()} />
  }
}

export const EditProfileForm = flow(
  withRouter,
  (component: any) => decorate(component, {
    profile: computed,
    isProfileReady: computed,
  }),
  View,
)(EditProfileFormClass as any) as any
