import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { NotesTableRow } from './NotesTableRow'
import TableHeader from "./TableHeader"

const NotesTable = (props) => {

  const style = {
    grey: {
      color: "grey",
    },
    delete: {
      color: "red",
    },
  }

  const rows = props.notes.map(note => (
    <NotesTableRow 
      data={note}
      key={note.id}
      deleteNote={props.deleteNote}
    />
  ))

  return (
    <div>
      <Table striped={true} responsive={true}>
        <TableHeader names={[
          {
            name: "Author",
            style: style.grey.color,
          },
          {
            name: "Date",
            style: style.grey.color,
          },
          {
            name: "Note",
            style: style.grey.color,
          },
          {
            name: "",
            style: style.delete.color,
          },
        ]} />
        <tbody>
          { props.notes.length > 0 ?
            rows
            :
            (
              <div>
                <p style={{margin: "15px 15px"}}>This person has no notes yet...</p>
              </div>
            )
          }
        </tbody>
      </Table>
    </div>
  )
}

NotesTable.propTypes = {
  notes: PropTypes.array,
  deleteNote: PropTypes.func,
}

export { NotesTable }
