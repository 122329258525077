import * as React from "react"
import {
  toJS,
  View,
} from "samx"

import { INotification } from "~/common/interfaces"
import {
  deleteNotification,
  initializeNotificationManager,
} from "~/samx/actions"
import { connectState } from "~/samx/states/connect"

import { Notification } from "./Notification"

const notificationStyles = {
  modal: {
    position: "fixed",
    top: "1%",
    zIndex: "999999999",
    left: "50%",
    transform: "translate(-50%, 0%)",
  },
}

class NotificationComponent extends React.Component {
  public constructor(props) {
    super(props)
    initializeNotificationManager()
  }

  public render() {
    return (
      <div style={notificationStyles.modal as React.CSSProperties}>
        {connectState.notifications.map((notification: INotification, i) => {
          return <Notification
            key={notification.id}
            text={notification.message}
            header={notification.header}
            timeout={notification.timeout}
            notificationType={notification.notificationType}
            onClose={this.onCloseModal(notification)}
          />
        })}
      </div>
    )
  }

  private onCloseModal = (notification) => () => deleteNotification(notification)
}

const NotificationManager = View(NotificationComponent)
export { NotificationManager }
