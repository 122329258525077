import * as React from "react"

import { Col, Input, Label, Row } from "reactstrap"
import { Switcher } from "~/components/hocs"

import { actualMargin, roundup } from "~/helpers/margin-calculations"

interface IHourlyRateProps {
  value: number | undefined,
  handleChange: (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => any,
  shouldRenderMarginFields: boolean,
}

interface IProps {
  rate: number,
  margin: number,
}

class HourlyRate extends React.Component<IHourlyRateProps, IProps> {
  constructor(props) {
    super(props)

    const rate = (this.props.value && Number(this.props.value)) || 0
    this.state = {
      rate,
      margin: 20,
    }

    this.handleMarginChange = this.handleMarginChange.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)

  }

  public handleValueChange(e) {
    this.props.handleChange(e)
    this.setState({ rate: Number(e.target.value) })
  }

  public handleMarginChange(e) {
    this.setState({ margin: Number(e.target.value) })
  }

  public render() {
    const rate = this.state.rate
    const margin = this.state.margin / 100

    const editValue = this.props.value !== 0 ? Number(this.props.value) : 0
    const { shouldRenderMarginFields } = this.props

    return (
      <Col className="col-sm-14">
        <Row>
          <Col className={(!shouldRenderMarginFields) ? "col-sm-2" : ""}>
            <Label className="section-title">Hourly Rate*</Label>
            <Input
              type="number"
              onChange={this.handleValueChange}
              value={editValue}
            />
          </Col>

          <Switcher
            shouldRenderComponent={shouldRenderMarginFields}
            component={
              <React.Fragment>
                <Col className="col-sm-2">
                  <Label className="section-title">Margin(%)</Label>
                  <Input
                    type="number"
                    value={this.state.margin}
                    onChange={this.handleMarginChange}
                  />
                </Col>

                <Col className="col-sm-2">
                  <Label className="section-title">Roundup</Label>
                  <Input
                    type="number"
                    disabled={true}
                    value={roundup(rate, margin)}
                  />
                </Col>

                <Col className="col-sm-2">
                  <Label className="section-title">Actual Margin(%)</Label>
                  <Input
                    type="number"
                    disabled={true}
                    value={actualMargin(rate, roundup(rate, margin)).toFixed(2)}
                  />
                </Col>
              </React.Fragment>
            }
          />
        </Row>
      </Col>
    )
  }
}

export { HourlyRate }
