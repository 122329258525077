import * as React from "react"

import { withRouter } from "react-router-dom"
import { Button, Col, Container, Row } from "reactstrap"

const UnauthorizedComponent: React.FC<any> = ({ history }): React.ReactElement<any> => {
  const redirectToHomePage = () => {
    history.push("/")
  }

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="page404">
              <div className="clearfix">
                <h1 className="float-left display-3 mr-4">403</h1>
                <h4 className="pt-3">YOU SHALL NOT PASS</h4>
              </div>
              <img
                src="https://i.imgur.com/hLwq2vH.gif"
                alt="traingandalf"
              />
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="button404">
              <Button onClick={redirectToHomePage}>
                Return to Home Page
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const Unauthorized = withRouter(UnauthorizedComponent)

export {
  Unauthorized,
}
