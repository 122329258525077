import * as React from "react"

import { Component } from "react"
import { AutoCompleteMulti, ISelectItem } from "~/components/presenters"

import { flow, get } from "lodash/fp"
import { Col, Label } from "reactstrap"
import { View } from "samx"
import { ICountry } from "~/common/interfaces"
import { queryCountry, queryFields, queryResource } from "~/services/api"

import { EStateField } from "~/components/containers/ProfileForm/common"
import { has, isObjectEmpty } from "~/helpers/operations"

const hasName = has("name")
const getPropIfExists = (propName: string) =>
  (data: object) => !isObjectEmpty(data) && hasName(data) ? get(propName)(data) : ""
const retrieveNameIfCountryExists = flow(get("data"), getPropIfExists("name"))

type Country = ICountry & {
  id: string,
  name: string,
}

export interface ICountriesDropdownProps {
  labelText?: string,
  inputName?: string,
  value: Country,
  setCountry: (country: Country) => any,
}

export interface ICountriesDropdownState {
  input: string,
  country: [ Country ] // Array notation because of Autocomplete controlled prop 'value',
}

class CountriesDropdownComponent extends Component<ICountriesDropdownProps, ICountriesDropdownState> {
  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps() as ICountriesDropdownState
    this.onCountrySelected = this.onCountrySelected.bind(this)
    this.onInput = this.onInput.bind(this)
    this.toCountry = this.toCountry.bind(this)
    this.toOption = this.toOption.bind(this)
    this.arePropsValid = this.arePropsValid.bind(this)
  }

  public componentDidUpdate(prevProps) {
    const shouldUpdate = prevProps.value !== this.props.value
    if (shouldUpdate) {
      const derivedState = this.retrieveStateFromProps()
      this.setState(derivedState as ICountriesDropdownState)
    }
  }

  public shouldComponentUpdate(nextProps, nextState) {
    const arePropsUpdated = !isObjectEmpty(this.props.value)
      && !isObjectEmpty(nextProps.value)
      && (this.props.value.name !== nextProps.value.name || this.props.value.id !== nextProps.value.id)

    const isStateUpdated = this.state.country !== nextState.country
      || this.state.country[0].id !== nextState.country[0].id
      || this.state.country[0].name !== nextState.country[0].name
      || this.state.input !== nextState.input

    return arePropsUpdated || isStateUpdated
  }

  public handleCountryChanged(value) {
    if (value) {
      const { id, name } = value
      const nextState: any = {
        input: name,
        country: [{ id, name }],
        countryIdReady: true,
      }
      const effect = () => {
        this.props.setCountry({ id, name } as any)
      }

      this.setState(nextState, effect)
    }
  }

  public resultsMapper = ({ data }) => {
    let items = []

    try {
      items = data.data.map(this.toOption)
    } catch (e) {
      console.error(e)
    }

    return items
  }

  public onCountrySelected(field: EStateField, data: Array<ISelectItem>, meta: object) {
    try {
      const { value } = data[0]
      this.setState((prevState) => ({
        ...prevState,
        country: [value],
      }), () => {
        const { id, name } = value
        this.props.setCountry({ id, name } as any)
      })
    } catch (e) {
      console.error(e)
    }
  }

  public onInput(input: string, meta: object) {
    this.setState({ input })
  }

  public render() {

    const options = [this.state.country.map(this.toOption)]

    const props: any = {
      placeholder: "Select a country",
      isMulti: false,
      resourceName: "country",
      resourceField: "name",
      stateName: "",
      resourceQueryMethod: queryResource,
      queryFields,
      resultsMapper: this.resultsMapper,
      onSelected: this.onCountrySelected,
      defaultInputValue: this.state.input,
      defaultOptions: options,
      defaultValue: options,
    }

    return (
      <Col>
        <Label className="section-title">{this.props.labelText}</Label>
        <AutoCompleteMulti<Country> {...props} />
      </Col>
    )
  }

  private arePropsValid() {
    return this.props.value !== undefined
      && this.props.value !== null
      && typeof this.props.value.id !== undefined
      && typeof this.props.value.name !== undefined
  }

  private retrieveStateFromProps() {
    const arePropsValid = this.arePropsValid()

    if (arePropsValid) {
      return {
        input: this.props.value.name,
        country: [
          {
            id: this.props.value.id || "",
            name: this.props.value.name || "",
          },
        ],
      }
    } else {
      return {
        input: "",
        country: [
          {
            id: "",
            name: "",
          },
        ],
      }
    }
  }

  private toOption(country: Country): ISelectItem<Country> {
    return {
      label: country.name,
      value: {
        id: country.id,
        name: country.name,
      } as any,
    }
  }

  private toCountry(option: ISelectItem<Country>): Country {
    return {
      id: option.value.id,
      name: option.value.name,
    } as Country
  }
}

const CountriesDropdown = View(CountriesDropdownComponent)

export {
  CountriesDropdown,
}
