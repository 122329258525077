import { Service } from "axios-middleware"
import { IMiddleware } from "./middleware"

export * from "./auth"
export * from "./common"

export const registerMiddlewares = (axios, middlewares: Array<IMiddleware>) => {
  const service = new Service(axios)

  service.register(middlewares)

  return axios
}
