import { memoize } from "lodash"
import { capitalize } from "lodash"
import { get } from "lodash/fp"
import * as React from "react"
import { Table } from "reactstrap"
import { ECommunicationTypes, ENotificationTypes, IChange, IChangelog } from "~/common/interfaces"
import { formatFromNow, formatUnixDateTime, uuid } from "~/helpers"
import { createNotification, getUser } from "~/samx/actions"
import { getProfileChangelog } from "~/services/api"

const styles = {
  noPadding: {
    padding: 0,
  },

  fullWidth: {
    width: "100%",
  },

  innerTable: {
    firstCol: {
      width: "17%",
    },
    secondCol: {
      width: "19%",
    },
    thirdCol: {
      width: "17%",
    },
    fourthCol: {
      width: "19%",
    },
  },

  change: {
    from: {
      color: "#d73a49",
    },
    to: {
      color: "#2cbe4e",
    },
  },
}

enum ChangeType {
  Delete = "delete",
  Update = "update",
  Create = "create",
}

interface IProps {
  id: string,
}

interface IState {
  changes: Array<IChangelog>,
}

const DATE_FIELDS = ["CreatedAt", "UpdatedAt"]
const isDateField = (fieldName) => (DATE_FIELDS.includes(fieldName))

const ECommunication = Object.entries(ECommunicationTypes).map(
  (item) => item[0],
) as any

const memoizedGetUser = memoize(getUser)

class Changelog extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props)
    this.state = {
      changes: [],
    }
  }

  public async componentDidMount() {
    const response = await (getProfileChangelog(this.props.id) as any)
    let changes = get("data.data", response) || []
    changes = await this.addUserFullNamesToChangelogEntities(changes)

    this.setState({
      changes,
    })
  }

  public parseEntityValue(change: IChange) {
    let { from, to } = change
    const entityName = [...change.path].pop()
    const rootEntity = change.path[0]

    if (rootEntity === "Communications" && entityName === "Type") {
      from = capitalize(ECommunication[from])
      to = capitalize(ECommunication[to])
    }
    if (isDateField(entityName)) {
      from = from && formatUnixDateTime(change.from as number)
      to = to && formatUnixDateTime(change.to as number)
    }

    return [from, to]
  }

  public onSubmitFailure(e) {
    createNotification({
      notificationType: ENotificationTypes.ERROR,
      message: e.message,
    })
  }

  public parseChangeset(changelog: IChangelog) {
    let changeSet: Array<IChange> = []
    try {
      changeSet = JSON.parse(changelog.changeEntity) as Array<IChange>
    } catch (e) {
      this.onSubmitFailure(e)
    }

    return (
      <td colSpan={5} style={styles.noPadding}>
        <table style={styles.fullWidth}>
          <tbody>
            {changeSet.filter(this.containsCreatedAtValuesFilter).map((change: IChange, index: number) => {
              const [from, to] = this.parseEntityValue(change)

              return (
                <tr key={uuid()}>
                  <td style={styles.innerTable.firstCol}>
                    {
                      index === 0 && changelog.userFullName
                    }
                  </td>
                  <td style={styles.innerTable.secondCol}>
                    {formatFromNow(parseInt(changelog.createdAt, 10))}
                  </td>
                  <td style={styles.innerTable.thirdCol}>
                    {capitalize(change.type)}
                  </td>
                  <td style={styles.innerTable.fourthCol}>
                    {this.getEntityType(change.path)}
                  </td>
                  <td colSpan={2}>
                    {from &&
                      <React.Fragment>
                        <span style={styles.change.from}>{this.getEntityChange(from)}</span>
                        &nbsp;
                        {change.type !== ChangeType.Delete && "⟹"}
                        &nbsp;
                      </React.Fragment>
                    }
                    <span style={styles.change.to}>{this.getEntityChange(to)}</span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    )
  }

  public render() {
    return (
      <Table>
        <thead>
          <tr>
            <th>Who</th>
            <th>When</th>
            <th>Type</th>
            <th>Entity</th>
            <th>Changes</th>
          </tr>
        </thead>
        <tbody>
          {this.state.changes.filter(this.nullChangeEntityFilter).map((change: IChangelog) => (
            <tr key={change.id}>
              {this.parseChangeset(change)}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  private nullChangeEntityFilter(change: IChangelog): boolean {
    return change.changeEntity !== "null"
  }

  private getEntityType(paths: Array<string>): string {
    const charsToOmit = ["0"]

    paths = paths.filter((char: string) => !charsToOmit.includes(char))

    return paths.join(".")
  }

  private getEntityChange(entity: any): any {
    if (!(entity instanceof Object)) {
      return entity
    }

    return entity.name || ""
  }

  private containsCreatedAtValuesFilter(entity: any): boolean {
    return !entity.path.includes("CreatedAt")
  }

  private async addUserFullNamesToChangelogEntities(entities: Array<IChangelog>): Promise<Array<IChangelog>> {
    let newEntities: Array<IChangelog> = []

    for (const entity of entities) {
      const user = await memoizedGetUser(entity.userId)

      newEntities = [
        ...newEntities,
        {
          ...entity,
          userFullName: `${user.firstName} ${user.lastName}`,
        },
      ]
    }

    return newEntities
  }
}

export { Changelog }
