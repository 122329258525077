import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "25px auto",
  height: "90%",
  width: "95%",
  color: "grey",
}

const innerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  margin: "0 auto",
  width: "90%",
  filter: "grayscale(50%)",
  padding: "50px",
}

const iconStyles = {
  padding: "25px",
  fontSize: "12em",
}

const LastPage = (props) => {
  return (
    <div style={containerStyles}>
      <div style={innerStyles}>
        <h1>No More Results To Show</h1>
        <i style={iconStyles} className="fa fa-list fa-5x"></i>
        <h4>Select new filters to begin another search</h4>
        <br/>
        <Button 
          onClick={props.previousPage}
          color="warning"
        >
          Previous Page
        </Button>
      </div>
    </div>
  )
}

LastPage.propTypes = {
  previousPage: PropTypes.func,
}

export default LastPage
