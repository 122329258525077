import * as api from "~/services/api"

import {
  get, map,
} from "lodash/fp"
import { Propose } from "samx"
import {
  INotification,
  IPlace,
  ISkill,
} from "~/common/interfaces"
import {
  PlaceIncoming,
  ProfileIncoming,
  SkillIncoming,
} from "~/services/api/adapters"
import {
  AppendToCollection,
  NewCollection,
  RemoveCollectionItem,
  UpdateCollectionItem,
} from "../proposals/collections"
import { saveQueryMeta } from "./queries"

const proposer = Propose("collections")

const getProfiles = async () => {
  const result = api.listProfiles()

  result
    .then(get("data.data"))
    .then(map(ProfileIncoming))
    .then(NewCollection("profiles"))
    .then(proposer)

  result
    .then(get("data.pagination"))
    .then(saveQueryMeta)
}

const listFilteredProfiles = (query: string) => {
  const result = api.listFilteredProfiles(query)

  result
    .then(get("data.data"))
    .then(map(ProfileIncoming))
    .then(NewCollection("profiles"))
    .then(proposer)

  result
    .then(get("data.pagination"))
    .then(saveQueryMeta)
}

const getProfile = async (id: string) => {
  await api
    .fetchProfile(id)
    .then(get("data"))
    .then(ProfileIncoming)
    .then(AppendToCollection("profiles"))
    .then(proposer)
}

const getPlace = async (id: string) => {
  await api
    .fetchPlace(id)
    .then(get("data"))
    .then(PlaceIncoming)
    .then(AppendToCollection("places"))
    .then(proposer)
}

const getPlaces = async () => {
  const { data } = await api.listPlaces()
  const places = (data.data || []).map(PlaceIncoming)
  proposer(NewCollection("places")(places))

  return data.pagination
}

const listFilteredPlaces = async (query: string) => {
  const { data } = await api.listFilteredPlaces(query)
  const places = (data.data || []).map(PlaceIncoming)
  proposer(NewCollection("places")(places))

  return data.pagination
}

const createPlace = async (place: IPlace) => {
  const placeRes = await api.addPlace(place)
  proposer(AppendToCollection("places")(placeRes))
}

const updatePlace = async (place: IPlace) => {
  await api.updatePlace(place)
  proposer(UpdateCollectionItem("places")(place))
}

const getSkills = () => {
  return api
    .listSkills()
    .then(get("data.data"))
    .then(NewCollection("skills"))
    .then(proposer)
}

const getSkill = async (id: string) => {
  const skill = await api.fetchSkill(id).then(get("data"))
  proposer(AppendToCollection("skills")(skill))

  return SkillIncoming(skill)
}

const createSkill = (
  skill: ISkill,
): Promise<any> => api.addSkill(skill)

const updateSkill = async (
  skill: ISkill,
): Promise<any> => api.updateSkill(skill)

const getSpokenLanguages = () => {
  const spokenLanguages = api.listLanguages()
  proposer(
    NewCollection("spokenLanguages")(
      spokenLanguages,
    ),
  )
}

const initializeNotificationManager = () => proposer(
  NewCollection("notifications")([]),
)

const createNotification = (notification: INotification) => proposer(
  AppendToCollection("notifications")({
    ...notification,
    id: `notification_${Math.random()}`,
  }),
)

const deleteNotification = (notification: INotification) => proposer(
  RemoveCollectionItem("notifications")(notification),
)

const createProfile = (profileData: any) => api.addProfile(profileData)
const updateProfile = async (profileData: any) => {
  const response = await api.updateProfile(profileData)
  return ProfileIncoming(response.data)
}

const getUser = async (id: string) => {
  const response = await api.fetchUser(id)
  return response.data
}

export {
  createSkill,
  createPlace,
  createProfile,
  createNotification,
  deleteNotification,
  getProfile,
  getProfiles,
  getPlace,
  getPlaces,
  getSkill,
  getSkills,
  getSpokenLanguages,
  getUser,
  listFilteredProfiles,
  listFilteredPlaces,
  initializeNotificationManager,
  updatePlace,
  updateSkill,
  updateProfile,
}
