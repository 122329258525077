import * as React from "react"

import { View } from "samx"
import { IRemoteFile } from "~/common/interfaces"
import {
  FileUpload as FileUploadPresenter,
  IFileUploadProps,
} from "~/components/presenters"
import { constructUploadFileUrl, filterOutInvalidFiles } from "~/helpers/file-upload"
import { deleteFile, fileUpload } from "~/services/api"

export type IFileUploadContainerProps = { bucket: string, files: Array<IRemoteFile> } & IFileUploadProps

class FileUploadClass extends React.Component<IFileUploadContainerProps, {}> {
  constructor(props: IFileUploadContainerProps) {
    super(props)

    this.onDrop = this.onDrop.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.onExistingFiles = this.onExistingFiles.bind(this)

    if (this.props.bucket === undefined || this.props.bucket === "") {
      console.error("InvalidProp: You have to specify a bucket name for the FileUpload component")
    }
  }

  public shouldComponentUpdate(nextProps: IFileUploadProps) {
    return nextProps !== this.props
  }

  public async onDrop(files: Array<File>): Promise<void> {
    const { bucket } = this.props
    const uploadedFiles: Array<IRemoteFile> = []

    for (const file of files) {
      const uploadedFile: IRemoteFile =  await fileUpload({ bucket, file })
      uploadedFiles.push(uploadedFile)
    }

    this.props.onDrop(uploadedFiles)
  }

  public async onRemove(file) {
    if (this.props.handleRemove === false) {
      return
    }

    const { bucket } = this.props

    await deleteFile({
      bucket,
      file,
      url: constructUploadFileUrl({ bucket, name: file.name }),
    })

    this.props.onRemove(file)
  }

  public onExistingFiles(files: Array<IRemoteFile>) {
    const { bucket } = this.props
    const nameField = "name"

    return filterOutInvalidFiles(files).map(
      (file) => ({
        name: file[nameField],
        url: constructUploadFileUrl({ name, bucket }),
      }),
    )
  }

  public render() {
    return (
      <FileUploadPresenter {...this.prepareChildProps(this.props)} />
    )
  }

  private prepareChildProps = ({
    label,
    multiple,
    bucket,
    files,
  }): IFileUploadContainerProps => ({
    onDrop: this.onDrop,
    onRemove: this.onRemove,
    label,
    files,
    multiple,
    bucket,
    onExistingFiles: this.onExistingFiles,
  })
}

export const FileUpload = View(FileUploadClass)
