import * as React from "react"

import { Component } from "react"
import { Label } from "reactstrap"
import { ICity } from "~/common/interfaces"
import { AutoCompleteMulti, ISelectItem } from "~/components/presenters"
import { getFullCityName } from "~/helpers/location"
import { eitherOrUndefined, has, isObjectEmpty } from "~/helpers/operations"
import { queryFields, queryResource } from "~/services/api"

const hasId = has("id")
const hasName = has("name")
const hasCountryName = (o) => has("country")(o) && has("name")(o.country)
const isValidCity = (city: ICity) => hasId(city) && city.id !== "" && hasName(city) && city.name !== ""
const isValidCityWithCountry = (city: ICity) => isValidCity(city) && hasCountryName(city) && city.country!.name !== ""
const emptyObjects = (o: object) => !isObjectEmpty(o)
const nonEmptyOption = (option) => has("label")(option) && has("value")(option) && isValidCity(option.value)

type SingleOrMultiLocation = Array<ICity> | [ICity] | null

export interface IProps {
  value?: SingleOrMultiLocation
  onChange?: (input: string, locations: SingleOrMultiLocation) => void,
  isMulti?: boolean,
  label?: string,
  labelClassName?: string,
}

export interface IState {
  locations: SingleOrMultiLocation,
  input: string,
}

export interface IStateMutation {
  locations?: SingleOrMultiLocation,
  input?: string,
}

class CitiesDropdown extends Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()

    this.onInput = this.onInput.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.updateState = this.updateState.bind(this)
    this.toOption = this.toOption.bind(this)
    this.toCity = this.toCity.bind(this)
    this.arePropsValid = this.arePropsValid.bind(this)
    this.citiesToOptions = this.citiesToOptions.bind(this)
    this.retrieveStateFromProps = this.retrieveStateFromProps.bind(this)
    this.hasPropsChanged = this.hasPropsChanged.bind(this)
    this.hasStateChanged = this.hasStateChanged.bind(this)
  }

  public citiesToOptions({ data: { data } }) {
    let items = [ ]

    try {
      items = data
        .map(this.toOption)
        .filter(emptyObjects)

    } catch (e) {
      console.error(e)
    }

    return items
  }

  // if the component did update due to props change, update the state accordingly
  public componentDidUpdate(prevProps) {
    const didPropsUpdate = this.hasPropsChanged(prevProps, this.props)

    if (didPropsUpdate) {
      const derivedState = this.retrieveStateFromProps()
      this.setState(derivedState)
    }
  }

  // Should the component re-render
  public shouldComponentUpdate(nextProps, nextState) {
    const currentProps = this.props
    const currentState = this.state

    return this.hasPropsChanged(currentProps, nextProps)
    || this.hasStateChanged(currentState, nextState)
  }

  public onSelect(_, data, meta) {
    const locations = data
      .map(this.toCity)
      .filter(emptyObjects)
      .filter(isValidCity)

    try {
      this.updateState({ locations })
    } catch (e) {
      console.error(e)
    }
  }

  public onInput(input: string) {
    try {
      this.updateState({ input })
    } catch (e) {
      console.error(e)
    }
  }

  public render() {
    const HAS_LABEL = "label" in this.props && this.props.label !== undefined && this.props.label.length > 3
    const LABEL = this.props.label
    const LABEL_CLASSNAME = this.props.labelClassName || ""

    const options = this.arePropsValid()
      ? this.props.value!
          .map(this.toOption)
          .filter(emptyObjects)
          .filter(nonEmptyOption)
      : []

    const defaultValue = eitherOrUndefined(() => options.length > 0, options) as any
    const defaultOptions = eitherOrUndefined(() => options.length > 0, options) as any

    const props: any = {
      placeholder: "Select city...",
      isMulti: this.props.isMulti || false,
      stateName: "",
      resourceName: "city",
      resourceField: "name",
      resourceQueryMethod: queryResource,
      queryFields,
      resultsMapper: this.citiesToOptions,
      onSelected: this.onSelect,
      onInputChange: this.onInput,
      defaultInputValue: this.state.input,
    }

    if (defaultOptions) {
      props.defaultOptions = defaultOptions
    }

    if (defaultValue) {
      props.defaultValue = defaultValue
    }

    return (
      <div>
        {
          HAS_LABEL
            ? <Label className={LABEL_CLASSNAME}>{LABEL}</Label>
            : ""
        }
        <AutoCompleteMulti<ICity> {...props} />
      </div>
    )
  }

  private arePropsValid(): boolean {
    return typeof this.props.value !== "undefined"
      && Array.isArray(this.props.value)
      && this.props.value.reduce(
        (isValid, city) => isValid && isValidCityWithCountry(city),
        true,
      )
  }

  private retrieveStateFromProps(): IState {
    const arePropsValid = this.arePropsValid()

    if (arePropsValid) {
      const city = this.props.value![0] as ICity

      return {
        input: this.props.isMulti ? "" : getFullCityName(city),
        locations: this.props.value,
      } as IState
    }

    return {
      input: "",
      locations: [
        {
          id: "",
          name: "",
          accent: "",
          country: {
            name: "",
            iso2code: "",
          },
        },
      ],
    }
  }

  private updateState(mutation: IStateMutation): void {
    this.setState((prevState) => ({
      ...prevState, ...mutation,
    }), () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.input, this.state.locations)
      }
    })
  }

  private toOption(city: ICity): ISelectItem<ICity> | {} {
    try {
      const { id, name, country } = city

      return ({
        label: name && country!.name ? getFullCityName(city) : "",
        value: { id, name, country: { name: country!.name } },
      })
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  private toCity(option: ISelectItem<ICity> | undefined): ICity | {} {
    try {
      const {
        id,
        name,
        accent,
        country,
      } = option!.value

      return {
        id,
        name,
        accent,
        country,
      }
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  private hasPropsChanged(currentProps, nextProps) {
    const currentValue = currentProps.value
    const nextValue = nextProps.value

    if (currentValue && nextValue) {
      const areValuesDifferent = "id" in currentValue
        && "id" in nextValue
        && currentValue.id !== nextValue.id

      if (areValuesDifferent) {
          return true
      }

      if (currentValue.length !== nextValue.length) {
        return true
      }
    }

    return false
  }

  private hasStateChanged(currentState, nextState) {
    if (currentState.input && nextState.input) {
      if (currentState.input !== nextState.input) {
        return true
      }
    }

    if (currentState.locations && nextState.locations) {
      if (currentState.locations !== nextState.locations) {
        return true
      }
    }

    return false
  }
}

export {
  CitiesDropdown,
}
