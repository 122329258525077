import * as moment from "moment"
import { DATE_FORMAT, DATETIME_FORMAT } from "~/config/constants"

const formatDate = ({
  date,
  format = DATE_FORMAT,
}: {
  date: string,
  format?: string,
}): string => moment(date).format(format)

const formatUnixDateTime = (date: number, format: string = DATETIME_FORMAT) => moment.unix(date).format(format)
const formatFromNow = (date: number) => moment.unix(date).fromNow()

export { formatDate, formatUnixDateTime, formatFromNow }
