import * as Joi from "joi"
import * as React from "react"
import { View } from "samx"

import { get } from "lodash/fp"
import { Redirect } from "react-router-dom"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
} from "reactstrap"
import { EStateField, ISkillFormProps } from "~/components/containers/SkillForm/common"
import { AutoCompleteMulti, ISelectItem, TextInput } from "~/components/presenters"
import { queryFields, queryResource } from "~/services/api"

const SkillFormComponent: React.FC<ISkillFormProps> = (props: ISkillFormProps):
  React.ReactElement<ISkillFormProps> => {
  if (props.saved) {
    return <Redirect to="/connect/skills" />
  }

  const parentName = get("parent.name")(props)
  return (
    <Form>
      <FormGroup>
        <TextInput
          type="text"
          name="name"
          value={props.name as string}
          placeHolderText="Name*"
          labelText="Name"
          required={false}
          handleChange={props.onInputChange(EStateField.name)}
        />
      </FormGroup>

      <FormGroup>
        <TextInput
          type="text"
          name="description"
          value={props.description as string}
          placeHolderText="Description"
          labelText="Description"
          required={false}
          handleChange={props.onInputChange(EStateField.description)}
        />
      </FormGroup>

      <FormGroup>
        <Col>
          <Label className="section-title">Parent</Label>
          <AutoCompleteMulti
            resourceName="category"
            resourceField="name"
            stateName={EStateField.parent}
            resourceQueryMethod={queryResource}
            queryFields={queryFields}
            onSelected={props.onParentSkillSelected}
            onInputChange={props.onParentChange}
            resultsMapper={props.resultsMapper}
            isMulti={false}
            defaultInputValue={parentName}
            defaultValue={
              props.resultsMapper({
                data: {
                  data: [
                    this.parent,
                  ],
                },
              })
            }
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <Button
          color="success"
          onClick={props.onSubmit}
          type="submit"
          name="submit"
          disabled={!props.isSubmitEnabled}
        >
          Submit
        </Button>
      </FormGroup>
    </Form>
  )
}

const SkillForm = View(SkillFormComponent)

export {
  SkillForm,
}
