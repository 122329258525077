import { request } from "~/services/api/resources/request"

const persistNewUser = async (user: object) => request({
  method: "POST",
  url: "users",
  data: user,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
})

const retrieveUser = async (user: object) => request({
  method: "POST",
  url: "auth",
  data: user,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
})

const authenticateUser = (user: object) => new Promise(
  (resolve, reject) => {
    // try to authenticate
    retrieveUser(user)
      .then(resolve)
      .catch(() => {
        // add new user to backend
        persistNewUser(user)
          .then(() => {
            // authenticate again
            retrieveUser(user)
              .then(resolve)
              .catch(reject)
          })
          .catch(reject)
      })
  },
)

export { authenticateUser }
