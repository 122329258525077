import React from 'react'
import logo from "~/assets/big-logo.svg"

const containerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "25px auto",
  height: "90%",
  width: "95%",
  color: "grey",
}

const innerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  width: "90%",
  filter: "grayscale(50%)",
  padding: "50px",
}

const imgStyles = {
  padding: "20px",
  maxHeight: "250px",
  filter: "opacity(50%)",
}

const InitialLoad = () => {
  return (
    <div style={containerStyles}>
      <div style={innerStyles}>
        <h1>Welcome to Supply Booster</h1>
        <h3>Who are you looking for today?</h3>
        <img style={imgStyles} src={logo} />
        <h4>Select filters to begin your search</h4>
      </div>
    </div>
  )
}

export default InitialLoad
