import * as React from "react"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { EditProfileForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const EditProfileFormScreen: React.SFC<{}> = (
  props: any,
): React.ReactElement<any> => {
  const renderEditProfileForm = () => (
    <Card>
      <CardBody>
        <Row>
          <Col> <h2>Edit Profile</h2> </Col>
          <Col xs="auto">
                <Button
                  color="primary"
                  href={`/connect/profile/status/${props.match.params.profileId}`}
                >
                  Status
                </Button>
          </Col>
        </Row>
        <EditProfileForm profileId={props.match.params.profileId} />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen
      {...props}
      renderListComponent={renderEditProfileForm}
    />
  )
}
