import * as Joi from "joi"

import { arrifyEnumeration } from "~/helpers/common"
import { ECommunicationTypes } from "../interfaces/communication-types"

const ecommunicationTypes = arrifyEnumeration(ECommunicationTypes)

export const communicationformValidation = Joi
  .object()
  .allow({})
  .keys({
    id: Joi.string().allow("").optional(),
    type: Joi.string().required().valid(...ecommunicationTypes),
    value: Joi.string().required(),
})
