import { get } from "lodash"
import * as React from "react"
import Select from "react-select"
import { Col, Label } from "reactstrap"
import { IFocusRole } from "~/common/interfaces"
import { getFocusCategories } from "~/services/api"

interface IProps {
  focusRole: IFocusRole,
  handleChange: (focusRole: IFocusRole) => any,
}

interface IState {
  focusCategories: Array<any>,
}

export class FocusRole extends React.Component<IProps, IState> {
  public constructor(props) {
    super(props)
    this.state = {
      focusCategories: [],
    }
  }

  public async componentDidMount() {
    let focusCategories = await getFocusCategories()
    focusCategories = this.transformOptions(get(focusCategories, "data.data"))

    this.setState({
      focusCategories,
    })
  }

  public render() {
    return (
      <Col>
        <Label className="section-title">Focus Role*</Label>
        <Select
          name="focus-role"
          options={this.state.focusCategories}
          defaultValue={this.transformFocusRoleToSelectValue(this.props.focusRole)}
          onChange={this.handleInternalChange}
        />
      </Col>
    )
  }

  public handleInternalChange = ({ value }: { label: string, value: IFocusRole }) => {
    this.props.handleChange(value)
  }

  private transformOptions(options) {
    return Object.entries(options).map(([key, value]) => {
      return {
        label: key,
        options: (value as any).list.map((item: any) => {
          return { label: item.name, value: item }
        }),
      }
    })
  }

  private transformFocusRoleToSelectValue(focusRole: IFocusRole) {
    return {
      label: focusRole.name,
      value: focusRole,
    }
  }
}
