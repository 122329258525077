import * as React from "react"
import { withRouter } from "react-router-dom"
import * as logo from "~/assets/expertlead-logo.png"
import { Switcher } from "~/components/hocs/FeatureSwitcher"
import { SERVICE_DESK_URL } from "~/config/constants"
import { isConnectPage, isSupplyBoosterPage, userBelongsToPrimaryOrganization } from "~/helpers"

import SideBar from "./SideBar"

import { AppHeaderDropdown } from "@coreui/react"
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import { Reactive } from "~/common/components"
import { UserRoles } from "~/common/interfaces/UserRoles"
import { LogoutContainer } from "~/components/containers"
import { authState } from "~/samx/states/auth"
import { userHasRole } from "~/services/api"

export interface INavBarLocalState {
  sidebar: boolean
  isAdmin: boolean
  shouldRenderServiceDeskLink: boolean
}

export interface  INavBarProps {
  match: any
}

class NavBarPresenter extends React.Component<INavBarProps, INavBarLocalState> {
  constructor(props) {
    super(props)

    this.state = {
      sidebar: true,
      isAdmin: false,
      shouldRenderServiceDeskLink: false,
    }

    this.toggleSideBar = this.toggleSideBar.bind(this)
  }

  public async componentDidMount() {
    const isAdmin = await userHasRole([UserRoles.SuperAdmin])

    const { match: { path } } = this.props
    const preparedPath = path.replace("/", "")

    let shouldRenderServiceDeskLink

    switch (true) {
      case isConnectPage(preparedPath) && userBelongsToPrimaryOrganization():
        shouldRenderServiceDeskLink = false
        break

      case isSupplyBoosterPage(preparedPath):
        shouldRenderServiceDeskLink = true
        break

      default:
        shouldRenderServiceDeskLink = true
        break
    }

    this.setState({
      isAdmin,
      shouldRenderServiceDeskLink,
    })
  }

  public shouldComponentUpdate(_: {}, nextState: INavBarLocalState) {
    const { sidebar, shouldRenderServiceDeskLink } = this.state

    switch (true) {
      case sidebar !== nextState.sidebar:
      case shouldRenderServiceDeskLink !== nextState.shouldRenderServiceDeskLink:
        return true

      default:
        return false
    }
  }

  public toggleSideBar() {
    this.setState({ sidebar: !this.state.sidebar })
  }

  public render() {
    const { sidebar, shouldRenderServiceDeskLink } = this.state
    const sideBarClass = sidebar === true ? "sideMenu" : "sideMenu open"

    return (
      <Nav className="d-md-down-none fixed" navbar={true}>
        <div className="navbar navbar-expand-lg navbar-light align-items-center justify-content-between">
          <Nav className="d-flex align-items-center">
            <NavItem className="px-2">
              <i className="fa fa-bars fa-2x" onClick={this.toggleSideBar} />
            </NavItem>
            <NavItem className="px-2">
              <img src={logo} height="24" alt="expertlead" />
            </NavItem>
          </Nav>
          <Nav className="d-flex align-items-center">
            <NavItem className="px-2">
              <NavLink href="#" className="text-white"><i className="icon-bell" /></NavLink>
            </NavItem>
            <NavItem className="px-2">
              <NavLink href="#" className="text-white"><i className="icon-list" /></NavLink>
            </NavItem>
            <AppHeaderDropdown direction="down">
              <DropdownToggle nav={true}>
                <Reactive>
                  <img src={authState.imageUrl} className="avatar" alt={authState.imageUrl} />
                </Reactive>
              </DropdownToggle>
              <DropdownMenu right={true} style={{ right: "auto" }}>
                <DropdownItem header={true} tag="div" className="text-center">
                  <Reactive> {authState.fullName} </Reactive>
                </DropdownItem>

                <DropdownItem tag="div" className="text-center">
                  <LogoutContainer />
                </DropdownItem>

                <Switcher
                  shouldRenderComponent={shouldRenderServiceDeskLink}
                  component={
                    <DropdownItem tag="div" className="text-center">
                      <a href={SERVICE_DESK_URL} target="_blank">
                        Raise an issue
                      </a>
                    </DropdownItem>}
                />
              </DropdownMenu>
            </AppHeaderDropdown>
          </Nav>
        </div>
        <SideBar display={sideBarClass} shouldDisplayAdminBtn={this.state.isAdmin}/>
      </Nav >
    )
  }
}

const NavBar = withRouter(NavBarPresenter)

export {
  NavBar,
}
