import axios from "axios"

import { CONNECT_API_ROOT_URL, ENVIRONMENT } from "~/config/env"
import { AuthMiddleware, CommonMiddleware, registerMiddlewares } from "~/services/api/middlewares"

const connectRequest = axios.create()
const thirdParty = axios.create()

const enableMiddlewares = () => {
  if (ENVIRONMENT !== "test") {
    registerMiddlewares(connectRequest, [AuthMiddleware, CommonMiddleware])
  }
}

const requestFactory = (axiosRequest) => async <T = any>(options) => {
  const baseURL = `${options.baseURL || CONNECT_API_ROOT_URL}/`

  return axiosRequest.request({
    ...options,
    baseURL,
  })
}

const request = requestFactory(connectRequest)
const externalRequest = requestFactory(thirdParty)

export {
  enableMiddlewares,
  request,
  externalRequest,
}
