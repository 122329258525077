import { request } from "~/services/api/resources/request"

import { IPlace } from "~/common/interfaces/place"
import { PlaceOutgoing } from "~/services/api/adapters/PlaceOutgoing"

const listPlaces = async () =>
  request({
    method: "GET",
    url: "place",
  })

const listFilteredPlaces = async (query: string) =>
  request({ method: "GET", url: `place?${query}` })

const fetchPlace = async (id: string) =>
  request({
    method: "GET",
    url: `place/${id}`,
  })

const addPlace = async (placeData: IPlace) =>
  request({
    method: "POST",
    url: "place",
    data: PlaceOutgoing(placeData),
  })

const updatePlace = async (placeData: IPlace) =>
  request({
    method: "PATCH",
    url: `place/${placeData.id}`,
    data: PlaceOutgoing(placeData),
  })

export { listPlaces, listFilteredPlaces, addPlace, fetchPlace, updatePlace }
