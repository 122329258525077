import * as PropTypes from "prop-types"
import * as React from "react"

import PlacesTableRow from "./PlacesTableRow"
import TableHeader from "./TableHeader"

import { flow } from "lodash/fp"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Table } from "reactstrap"
import { View } from "samx"
import { typeProps } from "~/helpers/common"

const placesConnectTable: React.SFC<{ list: Array<any> }> = (props: { list: Array<any> }) => {
  const makePlacesRow = (data, index) => (
    <PlacesTableRow
      rowData={data}
      key={index}
    />
  )

  const hasList = props.list && props.list.length > 0

  if (hasList) {
    return (
      <Card className="col-lg mx-auto" id="formContainer">
        <CardBody>
          <Link to="./add">
            <Button size="md" color="success" className="form__button">
              New Place <i className="fa fa-plus-square" />
            </Button>
          </Link>

          <Table hover={true} size="sm">
            <TableHeader
              // tslint:disable-next-line:jsx-no-multiline-js
              names={[
                "Name",
                "Location",
                "Logo",
                "Edit",
              ]}
            />
            {props.list.map(makePlacesRow)}
          </Table>
        </CardBody>
      </Card>
    )
  } else {
    return (
      <Col>
        <p>No places yet.</p>
      </Col>
    )
  }
}

const propTypes = {
  list: PropTypes.array,
}

export const PlacesConnectTable: any = flow(
  typeProps(propTypes),
  View,
)(placesConnectTable)
