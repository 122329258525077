import * as React from "react"

import AsyncSelect from "react-select/lib/Async"
import { DEBOUNCE_DELAY } from "~/config/constants"

export interface ISelectItem<T = any> {
  label: string
  value: T
}

interface IState<T = any> {
  inputValue: string | undefined
  value: Array<ISelectItem<T>>
}

interface IProps<T = any> {
  isMulti: boolean,
  placeholder?: string,
  stateName: string,
  resourceName: string,
  resourceField: string,
  resourceQueryMethod: ({ }) => any,
  queryFields: any,
  resultsMapper: (res: any) => Array<ISelectItem<T>>,
  onSelected: (field: string, value: Array<ISelectItem<T>>, meta: object) => void
  defaultOptions?: Array<ISelectItem<T>> | boolean,
  defaultValue?: Array<ISelectItem<T>> | Array<any> | undefined | null,
  onInputChange?: (inputValue: string, meta: object) => void,
  defaultInputValue?: string,
  extraQueryParams?: object,
  values?: Array<ISelectItem<T>> | null,
}

class AutoCompleteMulti<T = any> extends React.Component<IProps<T>, IState<T>> {
  private timerId: any
  private rejFunc: () => void // Used to cancel the unresloved promises

  constructor(props) {
    super(props)

    this.fetchOptions = this.fetchOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.loadOptionswrapper = this.loadOptionswrapper.bind(this)
    this.rejFunc = () => undefined
  }

  // Wrapping fetchOptions method in loadOptionswrapper to provide debounce effect
  // so that we can avoid multiple calls to API
  public loadOptionswrapper(inputVal: string) {
    clearTimeout(this.timerId)
    this.rejFunc() // Canceling the unresolved promises which get never kicked off from setTimeout
    return new Promise( (resolve, reject) => {
      this.rejFunc = reject
      this.timerId = setTimeout(() => {
        resolve(this.fetchOptions(inputVal))
      }, DEBOUNCE_DELAY)
    })
  }

  public fetchOptions(inputValue: string) {
    const {
      resourceName,
      resourceField,
      resourceQueryMethod,
      queryFields,
      resultsMapper,
      extraQueryParams,
    } = this.props

    const query = queryFields({ resourceName, resourceField }, inputValue)

    return resourceQueryMethod({ ...query, extraQueryParams }).then(resultsMapper)
  }

  public handleChange(option, meta) {
    let value = [option]

    if (Array.isArray(option)) {
      value = option
    }

    this.setState(
      { value },
      () => {
        if (this.props.onSelected) {
          this.props.onSelected(this.props.stateName, this.state.value, meta)
        }
      },
    )
  }

  public onInputChange(inputValue: string, meta: object) {
    this.setState({ inputValue }, () => {
      if (this.props.onInputChange) {
        this.props.onInputChange(inputValue, meta)
      }
    })
  }

  public render() {
    return (
      <AsyncSelect
        placeholder={this.props.placeholder || "Select..."}
        isMulti={this.props.isMulti}

        cacheOptions={true}
        loadOptions={this.loadOptionswrapper}

        defaultOptions={this.props.defaultOptions}
        defaultInputValue={this.props.defaultInputValue}
        defaultValue={this.props.defaultValue}

        onChange={this.handleChange}
        onInputChange={this.onInputChange}
        value={this.props.values} // Its fallback for the defaultValue prop
        // onSelectResetsInput={false}
      />
    )
  }
}

export { AutoCompleteMulti }
