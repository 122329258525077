// tslint:disable: max-line-length
const HumanFriendlyError = (message: string, domSelector: string) => ({
  message,
  domSelector,
})

const profileValidationMessages = {
  firstName: HumanFriendlyError("You must specify the first name", "input[name='firstName']"),
  lastName: HumanFriendlyError("You must specify the last name", "input[name='lastName']"),
  gender: HumanFriendlyError("You must select a gender", "select[name='gender']"),
  pool: HumanFriendlyError("You must specify the pool", "select[name='pool']"),
  bio: HumanFriendlyError("You must specify a bio", "textarea[name='bio']"),
  headline: HumanFriendlyError("You must specify a headline", "input[name='headline']"),
  hourlyRate: HumanFriendlyError("You must specify an hourly rate", "input[name='hourlyRate']"),
  location: {
    id: HumanFriendlyError("You must select a city", "input[name='city']"),
  },
  vat: HumanFriendlyError("You must specify a Tax ID Number", "input[name='vat']"),
  locationVat: {
    id: HumanFriendlyError("You must specify a Tax Location", "input[name='locationVat']"),
    name: HumanFriendlyError("You must specify a Tax Location", "input[name='locationVat']"),
  },
  availability: HumanFriendlyError("You must select an availability option", "select[name='availability']"),
  experiences: {
    companyId: HumanFriendlyError("Experiences: You have to enter a company name for experience #n", "div[data-index='*'] > input[name='place']"),
    city: HumanFriendlyError("Experiences: You have to enter a city for experience #n", "div[data-index='*'] > input[name='city']"),
    jobTitle: HumanFriendlyError("Experiences: You have to enter a job title for experience #n", "div[data-index='*'] > input[name='jobTitle']"),
    startDate: HumanFriendlyError("Experiences: You have to enter a start date for experience #n", "div[data-index='*'] > input[name='startDate']"),
    description: HumanFriendlyError("Experiences: You have to enter a description for experience #n", "div[data-index='*'] > input[name='description']"),
  },
  educations: {
    educationId: HumanFriendlyError("Educations: You have to enter a school name for education #n", "div[data-index='*'] > input[name='place']"),
    locationId: HumanFriendlyError("Educations: You have to enter a city for education #n", "div[data-index='*'] > input[name='city']"),
    startDate: HumanFriendlyError("Educations: You have to enter a start date for education #n", "div[data-index='*'] > input[name='startDate']"),
    description: HumanFriendlyError("Educations: You have to enter a description for education #n", "div[data-index='*'] > input[name='description']"),
  },
  spokenLanguages: {
    languageId: HumanFriendlyError("Languages: You have to enter a name for the language #n and click it on the result", "div[data-index='*'] > input[name='languageId']"),
    proficiency: HumanFriendlyError("Languages: You have to select proficiency for language #n", "div[data-index='*'] > input[name='proficiency']"),
  },
  skills: {
    startedAt: HumanFriendlyError("Start Date required for Top Skills", "div[class='multiField row']"),
  },
  communications: {
    type: HumanFriendlyError("Communications: You need to select a valid type for communication #n", "div[data-index='*'] > input[name='type']"),
  },
  focusRole: HumanFriendlyError("You must specify the focus role", "select[name='focus-role']"),
}

export {
  profileValidationMessages,
}
