import React, { Component } from "react"
import PropTypes from 'prop-types'
import * as API from "~/services/api"
import { authState } from '~/samx/states/auth'
import { formatFromNow } from '~/helpers/date-formatter'
import { SupplyBoosterProfileIncoming } from "~/services/api/adapters"

import { Card } from 'reactstrap'

import { NavBar } from '~/components/presenters'
import { DetailedViewTop } from './DetailedViewTop'
import { DetailedViewBottom } from './DetailedViewBottom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import get from "lodash/get"

class ProfileContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notes: [],
      id: false,
      stars: false,
      linesAdded: false,
      linesRemoved: false,
      language: false,
      watchers: false,
      forks: false,
    }

    this.deleteNote = this.deleteNote.bind(this)
    this.addNote = this.addNote.bind(this)
    this.updateTypeStatus = this.updateTypeStatus.bind(this)
    this.handleSortType = this.handleSortType.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.handleAssignToMe = this.handleAssignToMe.bind(this)
    this.handleUnassign = this.handleUnassign.bind(this)
    this.createHubspotDealHandler = this.createHubspotDealHandler.bind(this)
  }

  componentDidMount() {
    const path = this.props.location.pathname.split('/profile/')[1]
    this.setState({ contributorId: path }, async () => {
      await this.getProfile()
      const resContributions = await API.getProfileContributions({ id: path })
      await this.getNotes(path)
      this.setState({ contributions: resContributions.data }, async () => {
        const newContributions = await this.fillEmptyValues()
        this.setState({ contributions: { ...this.state.contributions, repositories: { contributions: newContributions } } })
      })
    })
  }

  async getProfile() {
    const resProfile = await API.getProfile({ id: this.state.contributorId,
      scoreSkills: window.location.search.replace('?', '').split('&').map(x => x.split('=')[1]).filter(x => !!x)})
    const profile = SupplyBoosterProfileIncoming(resProfile.data)
    this.setState({ profile })
  }

  fillEmptyValues() {
    const arr = get(this.state, "contributions.repositories.contributions", [])
    return arr.map(c => {
      c = {
        ...c,
        repository: {
          ...c.repository,
          githubStars: c.repository.githubStars || 0,
          githubForks: c.repository.githubForks || 0,
          githubWatchers: c.repository.githubWatchers || 0,
          mainLanguage: c.repository.mainLanguage || "",
        },
        totalLinesAdded: c.totalLinesAdded || 0,
        totalLinesRemoved: c.totalLinesRemoved || 0,
      }
      return c
    })
  }

  async getNotes(id) {
    try {
      const resNotes = await API.getNotes({ id })
      this.setState({ notes: resNotes.data.data || [] })
      return resNotes
    } catch (e) {
      console.error(e)
    }
  }

  async addNote(body) {
    try {
      const res = await API.addNote({ contributorId: this.state.contributorId, body })
      this.getNotes(this.state.contributorId)
      return res
    } catch (e) {
      console.error(e)
    }
  }

  async deleteNote(noteId) {
    try {
      await API.deleteNote({ contributorId: this.state.contributorId, id: noteId })
      this.getNotes(this.state.contributorId)
    } catch (e) {
      console.error(e)
    }
  }

  async updateTypeStatus(status) {
    try {
      const res = await API.patchProfile({ contributorId: this.state.contributorId, type: status })
      return res
    } catch (e) {
      console.error(e)
    }
  }

  async handleAssignToMe(contributorId) {
    try {
      await API.assignToMe({ contributorId, userId: authState.id })
      this.getProfile()
    } catch (e) {
      setTimeout(() => this.getProfile(), 2500)
      return toast.error("Something went wrong. Refreshing page")
    }
  }

  async handleUnassign(contributorId) {
    await API.unassign({ contributorId, userId: authState.id })
    this.getProfile()
  }

  async createHubspotDealHandler() {
    try {
      await API.createHubspotDeal({ contributorId: this.state.contributorId })
      toast.success("HubSpot Deal Created!")
      this.getProfile()
    } catch (e) {
      console.error(e)
      toast.error("Something went wrong...")
    }
  }

  handleSortType(event) {
    let arr = this.state.contributions.repositories.contributions
    let type = event.currentTarget.dataset.value
    let repos = arr.sort((a, b) => {
      if (type === "totalLinesRemoved" || type === "totalLinesAdded") {
        return this.state[type] ?
          a[type] - b[type]
          :
          b[type] - a[type]
      } else if (type === "id" || type === "mainLanguage") {
        return this.state[type] ?
          a.repository[type].localeCompare(b.repository[type])
          :
          b.repository[type].localeCompare(a.repository[type])
      }
      return this.state[type] ?
        a.repository[type] - b.repository[type]
        :
        b.repository[type] - a.repository[type]
    })
    this.setState({ contributions: { ...this.state.contributions, repositories: { contributions: repos } }, [type]: !this.state[type] })
  }

  render() {
    const stack = get(this.state, "contributions.languages.contributions", []).map(l => l.language)
    const notes = this.state.notes.sort((a, b) => b.createdAt - a.createdAt)
    const lastNoteTime = notes.length != 0 ? formatFromNow(this.state.notes[0].createdAt) : "-"

    return (
      <div>
        <NavBar />
        <Card className="col-lg-12">
          <ToastContainer />
          {
            this.state.profile &&
            <DetailedViewTop
              dev={this.state.profile}
              stack={stack}
              addNote={this.addNote}
              updateTypeStatus={this.updateTypeStatus}
              lastNoteTime={lastNoteTime}
              handleAssignToMe={this.handleAssignToMe}
              handleUnassign={this.handleUnassign}
              createHubspotDealHandler={this.createHubspotDealHandler}
            />
          }
          <DetailedViewBottom
            contributions={this.state.contributions}
            notes={notes}
            deleteNote={this.deleteNote}
            handleSortType={this.handleSortType}
          />
          <ToastContainer />
        </Card>
      </div>
    )
  }
}

ProfileContainer.propTypes = {
  location: PropTypes.object,
}

export { ProfileContainer }
