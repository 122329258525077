import { IStatusFormProfile } from "~/common/interfaces"

const InvitationOutgoing = (invitation: IStatusFormProfile) => ({
  referenceId: invitation.profileId,
  email: invitation.selectedEmail,
  firstName: invitation.firstName,
  lastName: invitation.lastName,
  ownerName: invitation.ownerName,
  referenceType: "profile",
})

export {
  InvitationOutgoing,
}
