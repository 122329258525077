import * as React from "react"

import { GoogleLogin, GoogleLoginResponse } from "react-google-login"
import {
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_SCOPE,
} from "~/config/env"
export interface ILoginScreenProps {
  onSuccess: (response: GoogleLoginResponse) => any,
  onFailure: () => any
}

const Login: React.FC<ILoginScreenProps> = (props): React.ReactElement<ILoginScreenProps> => (
  <div>
    <GoogleLogin
      className="btn-google-plus btn-brand mr-1 mb-1 btn-lg"
      clientId={GOOGLE_AUTH_CLIENT_ID as any}
      buttonText="Login"
      onSuccess={props.onSuccess}
      onFailure={props.onFailure}
      scope={GOOGLE_AUTH_SCOPE}
    >
      <i className="fa fa-google-plus" /><span>Sign-In</span>
    </GoogleLogin>
  </div>
)

export {
  Login,
}
