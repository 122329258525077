import { flatten, flow } from "lodash/fp"
import { objectifyArray } from "~/helpers/operations"

const countNestedSet = (field: string, childField: string, list: Array<any>) => {
  let count = 0
  function getCount(data: Array<any>) {
    for (const obj of data) {
      if (obj.hasOwnProperty(field)) {
        count = count + 1
      }
      // tslint:disable-next-line
      typeof obj["children"] === 'object' && getCount(obj[childField]);
    }
  }
  getCount(list)
  return count
}

const findNestedNode = (id: string, list: Array<any>) => {
  function findNode(insideList: Array<any>) {
    for (const obj of insideList) {
      if (obj.category.id === id) {
        return { ...obj }
      }
      if (obj.children && obj.children.length > 0) {
        const found = findNode(obj.children)
        if (found) {
          return { parent: obj.category, ...found }
        }
      }
    }
  }
  return findNode(list)
}

const objectifyNestedSet = (id: string) => (childrenField: string) => (collection: Array<any>) => {
  const flattenChildren = (data, parent?) =>
    data.map((x) => childrenField in x ?
    flattenChildren(x[childrenField], x) : { ...x, parent })

  return flow(
    flattenChildren,
    flatten,
    objectifyArray(id),
  )(collection)
}

export {
  countNestedSet,
  findNestedNode,
  objectifyNestedSet,
}
