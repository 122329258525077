import * as Joi from "joi"

const NullID = "00000000-0000-0000-0000-000000000000"

const customStringValidator = Joi.extend((joi) => ({
  base: joi.string(),
  name: "string",
  language: {
    startsWith: "needs to start with {{q}}",
  },
  rules: [
    {
      name: "startsWith",
      params: {
        q: joi.alternatives(joi.string()),
      },
      validate(params, value, state, options) {
        if (!value.startsWith(params.q)) {
          // Generate an error, state and options need to be passed
          return this.createError("string.startsWith", { v: value, q: params.q }, state, options)
        }

        return value // Everything is OK
      },
    },
  ],
}))

export {
  NullID,
  customStringValidator,
}
