import * as API from "~/services/api"
import { authState } from '~/samx/states/auth'

import React, { Component } from 'react'
import { isEmpty, getOr } from "lodash/fp"

import { DataTable } from '~/components/presenters'
import { FilterSideBarContainer } from '~/components/containers'
import InitialLoad from './InitialLoad'
import NoContent from './NoContent'
import Error from './Error'
import LastPage from './LastPage'
import PropTypes from 'prop-types'
import querystring from 'querystring'
import { parse } from "query-string"
import { Spinner } from '~/components/presenters/Spinner/Spinner'


import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Col, Row } from 'reactstrap'
import { NavBar } from '~/components/presenters'
import { EAssigned, EHireable, IField as IStateField } from "~/components/presenters/FilterBar/FilterSideBar"
import { setParams } from '~/helpers/querystring'
import { SupplyBoosterProfileIncoming } from "~/services/api/adapters"
import { bindAll } from "~/helpers/common"
import { ASC, DESC, DEFAULT_SORT_TYPE } from "~/config/constants"

const LAST_PAGE = "lastPage"

const initState = {
  [IStateField.bioOr]: "",
  [IStateField.bioAnd]: "",
  [IStateField.name]: "",
  [IStateField.location]: "",
  [IStateField.sortBy]: DEFAULT_SORT_TYPE,
  [IStateField.skills]: [
  ],
  [IStateField.assigneeFilter]: EAssigned.filterAll,
  [IStateField.isHireable]: EHireable.filterAll,
  [IStateField.page]: 1,
  [IStateField.isAscending]: false,
}

class HomeSupply extends Component {
  constructor(props) {
    super(props)

    this.state = {
      developerList: 'initial',
      totalCount: 0,
      perPage: 0,
      query: initState,
      sortOrder: DESC,
    }

    bindAll(this, [
      "fetchList",
      "pageChange",
      "lastPage",
      "handleReset",
      "onSkillSelect",
      "handleChange",
      "handleSorting",
      "skillsResultsMapper",
      "handleAssignToMe",
      "handleUnassign",
    ])
  }

  componentDidMount() {
    try {
      let params = parse(window.location.search)
      params.page ? (params.page = Number(params.page)) : undefined
      if (params.skills && typeof (params.skills) === "string") { params.skills = [params.skills] }

      if (isEmpty(params)) {
        return
      } else if (!params.skills) {
        this.setState({ query: { ...params, skills: [] } }, () => this.fetchList())
      } else {
        this.setState({ query: { ...params } }, () => this.fetchList())
      }
    } catch (error) {
      console.error(error)
    }
  }

  buildQueryString() {
    const query = {
      ...this.state.query,
      locations: (this.state.query.location || '').split(',').map(item => item.trim()),
    } 
    return querystring.stringify(query)
  }

  async fetchList(event) {
    if (event) { event.preventDefault() }
    let query = this.buildQueryString()
    const stringQuery = `?${setParams(this.state.query)}`

    if (stringQuery !== this.props.history.location.search) {
      this.props.history.push(stringQuery)
    }

    this.setState({ developerList: 'loading' })

    try {
      const res = await API.getList({ queryString: query })
      const developerList = getOr([], "data.data", res).map(SupplyBoosterProfileIncoming)
      const { perPage, total: totalCount} = getOr([], "data.pagination", res)
      this.setState({ developerList, totalCount, perPage })
    } catch (e) {
      console.error(e)
      this.setState({ developerList: "Error", totalCount: 0 })
    }
  }

  pageChange(page) {
    this.setState({ query: { ...this.state.query, page } }, () => {
      let query = this.buildQueryString()
      const stringQuery = setParams(this.state.query)
      this.props.history.push(`?${stringQuery}`)

      this.setState({ developerList: 'loading' }, async () => {
        const res = await API.getList({ queryString: query })

        if (res.data.data) {
          const developerList = getOr([], "data.data", res).map(SupplyBoosterProfileIncoming)
          this.setState({ developerList })
        } else {
          this.setState({ developerList: LAST_PAGE })
        }
      })
    })
  }

  lastPage() {
    this.pageChange(this.state.query.page - 1)
  }

  handleChange(e) {
    const value = e.currentTarget.value
    this.setState({ query: { ...this.state.query, [e.currentTarget.name]: value, page: 1 }})
  }

  handleReset() {
    this.setState({ query: initState })
    this.props.history.push("/supplybooster")
  }

  onSkillSelect(_, value) {
    const skills = (value || []).map( skill => skill.value)
    this.setState({ query: { ...this.state.query, skills , page: 1 } })
  }

  skillsResultsMapper(res){
    return (res.data.data || []).map(
      skill => ({ label: skill.name, value: skill.name}),
    )
  }

  selectedSkillsMapper(){
    return ( this.state.query.skills || [] ).map(
      skill => ({ label: skill, value: skill}),
    )
  }

  handleSorting(e){
    const { query } = this.state
    const { isAscending = false } = query
    const selectedSortBy = e.currentTarget.innerText && `SORT_BY_${e.currentTarget.innerText.toUpperCase()}`
    const selectedSortOrder = selectedSortBy === query.sortBy ? !isAscending : isAscending // by comparing current sort type and previous tort type we toggling status of the direction
    const updatedSortOrder = selectedSortOrder ? ASC : DESC
    this.setState({ query: { ...query, sortBy: selectedSortBy, isAscending: selectedSortOrder, page: 1}, sortOrder: updatedSortOrder }, this.fetchList)
  }

  async handleAssignToMe(contributorId) {
    try {
      await API.assignToMe({ contributorId, userId: authState.id })
      this.fetchList()
    } catch (e) {
      setTimeout(() => this.fetchList(), 2500)
      return toast.error("Something went wrong. Refreshing page")
    }
  }

  async handleUnassign(contributorId) {
    await API.unassign({ contributorId, userId: authState.id })
    this.fetchList()
  }

  render() {
    const devListContent =
      this.state.developerList.length > 0 ? (
        <DataTable
          list={this.state.developerList}
          pageChange={this.pageChange}
          pageNumber={this.state.query.page}
          totalCount={this.state.totalCount}
          perPage={this.state.perPage}
          isLoading={'loading' === this.state.developerList}
          handleSorting={this.handleSorting}
          currentSortValue={this.state.query.sortBy || null}
          sortOrder={this.state.sortOrder}
          handleAssignToMe={this.handleAssignToMe}
          handleUnassign={this.handleUnassign}
          scoreSkills={`?${setParams({scoreSkills: this.state.query.skills})}`}
        />
      ) : (
        <NoContent />
      )

    const loadingCheck = (
      this.state.developerList === 'loading' ? (
        <Spinner />
      ) : (
        devListContent
      )
    )

    const lastPageCheck = (
      LAST_PAGE === this.state.developerList ? (
        <LastPage previousPage={this.lastPage} />
      ) : (
        loadingCheck
      )
    )

    const intialLoadCheck = (
      this.state.developerList === 'initial' ? (
        <InitialLoad />
      ) : (
        lastPageCheck
      )
    )

    const errorCheck = (
      this.state.developerList === "Error" ? (
        <Error />
      ) : (
        intialLoadCheck
      )
    )

    return (
      <div>
        <NavBar />
        <Row className="no-gutters">
          <ToastContainer />
          <Col className="col-lg-10">
            {errorCheck}
          </Col>
          <Col className="col-lg-2">
            <FilterSideBarContainer
              isLoading={'loading' === this.state.developerList}
              query={this.state.query}
              fetchList={this.fetchList}
              handleChange={this.handleChange}
              onSkillSelect={this.onSkillSelect}
              handleReset={this.handleReset}
              skillsResultsMapper={this.skillsResultsMapper}
              selectedSkills={this.state.query.skills && this.selectedSkillsMapper()}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

HomeSupply.propTypes = {
  user: PropTypes.object,
  userId: PropTypes.string,
  tokenId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
}

export default HomeSupply
