export class NonAllowedRenderKey extends Error {
  constructor(componentName, propName, nonAllowedKeys) {
    super(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed because:
          \`${propName}\` must contain only keys available in the \`columns\` prop.
          The invalid provided keys are: \`${nonAllowedKeys}\`.`,
    )
  }
}

export class InvalidRenderKeyType extends Error {
  constructor(componentName, propName, key) {
    super(
      `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed because:
          \`${propName}\`'s keys must index values of type 'Function'. Non-function value supplied for: ${key}`,
    )
  }
}
