import * as Joi from "joi"

export const educationformValidation = Joi
  .object()
  .allow({})
  .keys({
    id: Joi.string(),
    schoolId: Joi.string().required(),
    degree: Joi.string().required(),
    startDate: Joi.string().required(),
    endDate: Joi.string(),
    description: Joi.string(),
    cityId: Joi.string(),
})
