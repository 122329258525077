import * as React from "react"
import { Col, Label } from "reactstrap"

interface ITextAreaProps {
  name: string,
  labelText: string,
  placeHolderText: string,
  handleChange: (e) => {},
  value: string,
  maxLength: number,
  enableWordCount?: boolean,
}

const TextArea = ({
  name,
  labelText,
  placeHolderText,
  handleChange,
  value,
  maxLength,
  enableWordCount = false,
}: ITextAreaProps) => {
  return (
    <Col>
      <Label className="section-title">{labelText}</Label>
      <textarea
        className="form-control"
        name={name}
        placeholder={placeHolderText}
        rows={3}
        onChange={handleChange}
        value={value}
        maxLength={maxLength}
      />
      {
        enableWordCount && <small
          id="passwordHelpBlock"
          className="form-text text-muted"
        >
          {(value || "").length}/{maxLength}
        </small>
      }
    </Col>
  )
}

export { TextArea }
