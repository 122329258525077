import * as React from "react"

import { PlacesTable } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens/ConnectListingScreen/ConnectListingScreen"

const placesListingScreen: React.SFC<any> = (props): React.ReactElement<any> => {

  const renderPlacesTable = () => (<PlacesTable />)
  return (
    <ConnectListingScreen
      {...props}
      renderListComponent={renderPlacesTable}
    />
  )
}

export const PlacesListingScreen: React.SFC<any> = placesListingScreen
