import {
  filter, flow, get, has as lodashHas, isArray, isEmpty, map, noop,
} from "lodash/fp"
import { uuid } from "./index"

const mapPropIfExists = (obj: object) =>
  (arrayPropName: string, defaultValue: any = []) =>
    (mapper: (i: any) => any) =>
      lodashHas(arrayPropName)(obj) && flow(get(arrayPropName), isArray)(obj)
        ? get(arrayPropName)(obj).map(mapper)
        : defaultValue

const safeMap = (mapper: (arg, index) => any) => (obj: any, index?: any) => !isEmpty(obj) ? mapper(obj, index) : noop()

const mapCollection = (collection: any) => (mapFunction: any) => {
  return flow(
    map(mapFunction),
    filterUndefineds,
  )(collection)
}

const filterUndefineds = filter((v: any) => v !== undefined)

const objectifyArray = (indexByProperty: string) => (collection: Array<any>) => {
  return collection && collection.reduce(
    (accumulatedObject: object, collectionItem: any) => {
      const propertyValue = get(indexByProperty)(collectionItem)
      return {
        ...accumulatedObject, [propertyValue]: collectionItem,
      }
    },
    {},
  )
}

const has = (property: string) => (obj: object) => obj
  ? obj.hasOwnProperty(property) && obj[property] !== undefined
  : false

const isObjectEmpty = (obj: object) => obj ? Object.keys(obj).length === 0 : false
const isString = (str: string) => typeof str === "string"
const eitherOrUndefined = (condition: () => boolean, value: any) => condition() ? value : undefined

const chunk = (arr: Array<any>, size: number) => arr.reduce(
  (chunks, el, i) => (i % size
    ? chunks[chunks.length - 1].push(el)
    : chunks.push([el])) && chunks,
  [],
  )

const convertToISODate = (date: string): string => (new Date(date)).toISOString()

const addKeys = (arr: Array<any>, field: string = "key") => arr.map((item) => ({
  ...item,
  [field]: item[field] || uuid(),
}))

export {
  mapCollection,
  mapPropIfExists,
  safeMap,
  filterUndefineds,
  objectifyArray,
  has,
  isObjectEmpty,
  isString,
  chunk,
  convertToISODate,
  eitherOrUndefined,
  addKeys,
}
