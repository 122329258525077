import React from "react"
import PropTypes from "prop-types"
import { Col, FormGroup, Label } from "reactstrap"
import { EProfileTravelPreferences } from "~/common/interfaces"
import { arrifyEnumeration } from "~/helpers"

export const TravelPreferences = (props) => {

  const travelOptions = arrifyEnumeration(EProfileTravelPreferences)
  const readableOptions = {
    [EProfileTravelPreferences.unlimited]: "Unlimited",
    [EProfileTravelPreferences.limited]: "Limited",
    [EProfileTravelPreferences.currentCity]: "Current City",
    [EProfileTravelPreferences.remoteOnly]: "Remote Only",
  }

  return (
    <FormGroup>
      <Col>
        <Label className="section-title">Travel Preferences</Label>
        <select
          className="form-control"
          id={"dropDown-travelPreferences"}
          name="travelPreferences"
          onChange={props.handleChange}
          value={props.value}
        >
          {travelOptions.map((item) => (
            <option key={item} value={item}>{readableOptions[item]}</option>
          ))}
        </select>
      </Col>
    </FormGroup>
  )
}

TravelPreferences.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
}
