import * as Joi from "joi"
import { omit } from "lodash/fp"

import { PureComponent } from "react"
import { ENotificationTypes, ISkill } from "~/common/interfaces"
import { ISelectItem } from "~/components/presenters/"
import { createNotification } from "~/samx/actions"

export interface ISkillFormProps {
  id?: string
  name?: string
  description?: string
  parent?: ISkill | undefined
  saved?: boolean
  isSubmitEnabled: boolean
  validationSchema: Joi.JoiObject
  saveData: ({
    name,
    description,
    parent,
  }: any) => void
  onInputChange: (field: string) => (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => any,
  onSubmitFailure: () => any,
  onParentSkillSelected: () => any,
  onParentChange: () => any,
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
  resultsMapper: ({ data: { data } }) => any,
}

export enum EStateField {
  id = "id",
  name = "name",
  description = "description",
  parent = "parent",
  saved = "saved",
  validationError = "validationError",
}

const skillFormValidation = Joi.object({
  id: Joi.string().allow(""),
  name: Joi.string().required(),
  description: Joi.string(),
  parent: Joi.object({
    name: Joi.string().required(),
    description: Joi.string(),
    id: Joi.string(),
    created: Joi.string(),
    updated: Joi.string(),
  }).allow(null),
})

export interface ICoreSkillFormData {
  readonly [EStateField.id]: string
  readonly [EStateField.name]: string
  readonly [EStateField.description]: string
  readonly [EStateField.parent]: ISkill | undefined
  readonly [EStateField.saved]: boolean
}

export type ICoreSkillFormDataState = ICoreSkillFormData

export type ISkillFormState = ICoreSkillFormDataState & IValidationState

interface IValidationState {
  readonly [EStateField.validationError]: Joi.ValidationError | {}
}

export const getInitialState = () => ({
  isSubmitEnabled: true,
  isLoaded: false,
  skill: {
    id: "",
    name: "",
    description: "",
    parent: undefined,
  },
  saved: false,
  validationError: {},
})

export interface IEditFormSkillLocalState {
  isSubmitEnabled: boolean
  skill: ISkill | {}
}

export class SkillContainer extends PureComponent {
  public saveData(data: any) {
    // TODO: override this in your component
  }

  public extraProps() {
    // Override this in your component
    return {}
  }

  public prepareProps() {
    const saveData = this.saveData.bind(this)
    const onInputChange = this.onInputChange.bind(this)
    const onParentSkillSelected = this.onParentSkillSelected.bind(this)
    const onParentChange = this.onParentChange.bind(this)
    const onSubmitFailure = this.onSubmitFailure.bind(this)
    const onSubmit = this.onSubmit.bind(this)
    const resultsMapper = this.resultsMapper.bind(this)
    const extraProps = this.extraProps()

    return {
      onInputChange,
      onSubmitFailure,
      onSubmit,
      onParentSkillSelected,
      onParentChange,
      resultsMapper,
      saveData,
      ...extraProps,
    } as ISkillFormProps
  }

  public onInputChange = (field: EStateField) => (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const value = e.currentTarget.value
    this.setState((prevstate) => ({
      ...prevstate,
      skill: {
        ...(prevstate as any).skill,
        [field]: value,
      },
    }))
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!this.validate()) {
      return
    }

    try {
      await this.saveData(this.prepDataToSubmit())
      this.setState((prevstate) => ({
        ...prevstate,
        saved: true,
      }))
    } catch (e) {
      this.onSubmitFailure(e)
    } finally {
      this.setState((prevstate) => ({
        ...prevstate,
        isSubmitEnabled: true,
      }))
    }
  }

  public onSubmitFailure(e) {
    createNotification({
      notificationType: ENotificationTypes.ERROR,
      message: e.message,
    })
  }

  public toSkillOption = ({ name, id, description }) => ({
    label: name,
    value: { id, name, description },
  })

  public resultsMapper = ({ data: { data } }) => {
    let items = []
    if (!data[0]) {
      return []
    }

    try {
      items = data.map(this.toSkillOption)
    } catch (e) {
      console.error(e)
    }

    return items
  }

  public onParentSkillSelected(field: EStateField, data: Array<ISelectItem>) {
    try {
      const { value } = data[0]
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
      }))
    } catch (e) {
      console.error(e)
    }
  }

  public onParentChange = (skill: string): void => {
    if (skill === "") {
      this.setState((prevState) => ({
        ...prevState,
        skill: {
          ...(prevState as any).skill,
          parent: undefined,
        },
      }))
    }
  }

  public validate() {
    const { error } = Joi.validate(
      this.prepDataToSubmit(),
      skillFormValidation,
    )

    if (error) {
      this.onSubmitFailure(error)
      return false
    }
    return true
  }

  private prepDataToSubmit(): ICoreSkillFormDataState {
    const { skill, parent } = (this.state as any)
    return {
      ...omit(["validationError", "saved"])(skill),
      parent,
    } as ICoreSkillFormDataState
  }
}
