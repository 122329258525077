import * as React from "react"
import { Button } from "reactstrap"

export interface ILogoutScreenProps {
  onLogoutHandler: () => any
}

const Logout: React.FC<any> = (props: ILogoutScreenProps): React.ReactElement<any> => {
  return (
    <Button
      color="danger"
      size="lg"
      onClick={props.onLogoutHandler}
      className="btn-logout"
    >
      <i className="fa fa-google-plus" />
      &nbsp;
      <span>Sign Out</span>
    </Button>
  )
}

export {
  Logout,
}
