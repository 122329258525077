import "draft-js/dist/Draft.css"
import * as React from "react"
import * as ReactMarkdown from "react-markdown"

import { Col, Row } from "reactstrap"
import { IProposition } from "~/common/interfaces/proposition"

const styles = {
  markdown: {
    minHeight: "100%",
  },
  container: {
    minHeight: "120px",
  },
  guideButton: {
    cursor: "pointer",
    borderBottom: "1px dashed grey",
  },
}

interface IRichTextAreaProps {
  value: string
  updateParent: (input: string) => void
}

interface IState {
  input: any
  guideVisable: boolean,
}

class RichTextArea extends React.Component<
  IRichTextAreaProps,
  IState,
  IProposition
  > {
  constructor(props: IRichTextAreaProps) {
    super(props)

    this.state = {
      input: "",
      guideVisable: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.toggleGuide = this.toggleGuide.bind(this)
  }

  public componentDidMount() {
    if (this.props.value) {
      this.setState({ input: this.props.value })
    }
  }

  public handleChange(e: any) {
    const input = e.currentTarget.value
    this.setState({ input }, () => {
      this.props.updateParent(this.state.input)
    })
  }

  public toggleGuide() {
    this.setState({ guideVisable: !this.state.guideVisable })
  }

  public render() {
    const guideToggle = { display: this.state.guideVisable ? "block" : "none" }

    return (
      <div>
        <Row style={styles.container}>
          <Col>
            <textarea
              style={styles.markdown}
              className="form-control"
              placeholder="Add Description Here..."
              onChange={this.handleChange}
              value={this.state.input || ""}
            >
              {this.state.input}
            </textarea>
          </Col>
          <Col>
            <div style={styles.markdown}>
              <div>
                <ReactMarkdown
                  source={this.state.input}
                />
              </div>
            </div>
          </Col>
        </Row>
        <small
          onClick={this.toggleGuide}
          style={styles.guideButton}
        >
          Markdown Guide +
        </small>
        <div style={guideToggle}>
          <small
            id="passwordHelpBlock"
            className="form-text text-muted"
          >
            Please enter the work description in bullets only, using *. See example below:<br/><br/>

            * Responsibility 1<br/>
            * Responsibility 2<br/>
            * Responsibility 3
          </small>
        </div>
      </div>
    )
  }
}

export { RichTextArea }
