import { Model } from "samx"
import { IAuthUser, IProposition } from "~/common/interfaces"

export const emptyUser = {
  id: "",
  accessToken: "",
  createdAt: "",
  email: "",
  firstName: "",
  lastName: "",
  organizationId: "",
  imageUrl: "",
}

const getCachedAuthUser = () => {
  const cachedJsonAuthUser = window.localStorage.getItem("authUser")

  if (cachedJsonAuthUser) {
    return JSON.parse(cachedJsonAuthUser)
  }

  return emptyUser
}

const {
  id,
  firstName,
  lastName,
  accessToken,
  email,
  imageUrl,
  organizationId,
  createdAt,
} = getCachedAuthUser()

const schema: IAuthUser = {
  id,
  firstName,
  lastName,
  accessToken,
  email,
  imageUrl,
  organizationId,
  createdAt,
}

const acceptor = (
  accept: (mutation: IProposition) => any,
  proposition: IProposition,
): any => {
  const { value, name: propositionName } = proposition

  switch (propositionName) {
    case "user.login":
      window.localStorage
        .setItem("authUser", JSON.stringify(value))
      break

    case "user.logout":
    case "user.flush":
      window.localStorage.removeItem("authUser")
      accept(proposition)
      break

    default:
      accept(proposition) // accept other propositions regardless
      break
  }
}

Model("user", { schema, acceptor })
