/* tslint:disable:jsx-no-lambda */
import "./App.scss"

import * as React from "react"

import Page404 from "~/components/containers/Common/Page404"
import Page500 from "~/components/containers/Common/Page500"
import { ProfileContainer } from "~/components/containers/SupplyBooster/DetailedView/Profile"
import HomeSupply from "~/components/containers/SupplyBooster/HomeSupply"
import Portal from "~/components/presenters/Menu/Portal"

import { Component } from "react"
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom"
import { View } from "samx"
import withAnalytics from "~/common/components/GoogleAnalytics"
import {
  AuthenticatedRoute,
  AuthenticationManager,
  NotificationManager,
} from "~/components/containers"
import { Admin } from "~/components/containers/Admin/Admin"
import { HomeConnect } from "~/components/containers/SupplyConnect/HomeConnect"

import {
  EditPlaceFormScreen,
  EditSkillFormScreen,
  Licenses,
  NewPlaceFormScreen,
  NewProfileFormScreen,
  NewSkillFormScreen,
  PlacesListingScreen,
  SkillsListingScreen,
  SpokenLanguagesListingScreen,
  StatusFormScreen,
} from "./components/screens"
import { EditProfileFormScreen } from "./components/screens"

interface IUser {
  id: string,
  accessToken: string,
  loggedIn: boolean,
  firstName: string,
  lastName: string,
  email: string,
}

interface IAppState {
  user: IUser,
}

class AppComponent extends Component<{}, IAppState> {
  constructor(props: any) {
    super(props)

    this.state = {
      user: {
        loggedIn: true,
        id: "",
        accessToken: "",
        firstName: "",
        lastName: "",
        email: "",
      },
    }
  }

  public render() {
    return (
        <>
          <Switch>
              <Route
                exact={true}
                path="/auth/:action"
                component={AuthenticationManager}
                fallbackRoute="/"
              />
              <AuthenticatedRoute exact={true} path="/" component={Portal}/>
              <AuthenticatedRoute
                exact={true}
                path="/supplybooster"
                component={(props) => (
                  <HomeSupply
                    {...props}
                    user={this.state.user}
                  />
                )}
              />
              <AuthenticatedRoute
                exact={true}
                path="/supplybooster/profile/:id"
                component={ProfileContainer}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/profile/new"
                component={NewProfileFormScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/profile/edit/:profileId"
                component={EditProfileFormScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/profile/status/:profileId"
                component={StatusFormScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/home"
                component={(props) => (
                  <HomeConnect
                    {...props}
                    user={this.state.user}
                    display="profile"
                  />
                )}
              />

              <AuthenticatedRoute
                exact={true}
                path="/connect/skills"
                component={SkillsListingScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/skill/new"
                component={NewSkillFormScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/skills/edit/:id"
                component={EditSkillFormScreen}
              />

              <AuthenticatedRoute
                exact={true}
                path="/connect/places"
                component={PlacesListingScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/place/new"
                component={NewPlaceFormScreen}
              />
              <AuthenticatedRoute
                exact={true}
                path="/connect/places/edit/:placeId"
                component={EditPlaceFormScreen}
              />

              <AuthenticatedRoute
                exact={true}
                path="/connect/spoken-languages"
                component={SpokenLanguagesListingScreen}
              />

              <AuthenticatedRoute
                exact={true}
                path="/admin"
                component={(props) => (
                  <Admin
                    {...props}
                    display="admin"
                  />
                )}
              />

              <Route
                exact={true}
                path="/500"
                component={Page500}
              />

            <Route
              exact={true}
              path="/third-party"
              component={Licenses}
            />

              <AuthenticatedRoute component={Page404} />
          </Switch>
          <NotificationManager />
          <div className="footer">
            <a href={"/third-party"}>Third party licenses</a>
          </div>
        </>
    )
  }
}

const AppWithRouter = withRouter(View(withAnalytics(AppComponent)))

const App = () => (
  <BrowserRouter>
    <AppWithRouter />
  </BrowserRouter>
)

export { App }
