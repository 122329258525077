import * as React from "react"
import * as ReactDOM from "react-dom"

import { App } from "~/App"
import { enableMiddlewares } from "~/services/api"

const initialise = () => enableMiddlewares()

const render = () => {
  ReactDOM.render(<App />, document.getElementById("root"))
}

export {
  initialise,
  render,
}
