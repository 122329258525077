const SkillOutgoing = (skill) => ({
  category: {
    id: skill.id || null,
    name: skill.name,
    description: skill.description,
  },
  parent: skill.parent,
})

export {
  SkillOutgoing,
}
