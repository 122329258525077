import * as React from "react"

import { Redirect, Route } from "react-router-dom"
import { View } from "samx"
import { authState } from "~/samx/states/auth"

export interface IAuthenticatedRouteProps {
  component: React.FC<any> | React.Component<any, any>| any,
  location: { pathname: string },
}

const AuthenticatedRouteComponent = (props: IAuthenticatedRouteProps) => {
  const { component, ...routeProps } = props

  if (authState.isLoggedIn) {
    return (
      <Route
        {...routeProps as any}
        isLoggedIn={authState.isLoggedIn}
        component={component}
      />
    )
  } else {
    return (
      <Redirect
        to={{
          pathname: "/auth/login",
        }}
      />
    )
  }
}

const AuthenticatedRoute = View(AuthenticatedRouteComponent)

export {
  AuthenticatedRoute,
}
