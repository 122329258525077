export enum EPlaceStatus {
  underReview = "STATUS_UNDER_REVIEW",
  published = "STATUS_PUBLISHED",
  rejected = "STATUS_REJECTED",
}

export const statusLabelMap = {
  [EPlaceStatus.underReview]: "Under Review",
  [EPlaceStatus.published]: "Published",
  [EPlaceStatus.rejected]: "Rejected",
}
