import React from 'react'
import PropTypes from 'prop-types'
import { Table, Button } from 'reactstrap'
import Pagination from "react-js-pagination"

const UsersTable = ({ users, handleDeleteUser, pagination, handlePagination }) => {
  
  return (
    <>
      <Table responsive hover>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Email</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) =>
            <tr key={user.id}>
              <td>{user.firstName} {user.lastName}</td>
              <td>{user.email}</td>
              <td className="pt-2"><Button color="danger" data-userid={user.id} onClick={handleDeleteUser}>Delete User</Button></td>
            </tr>
          )}
        </tbody>
      </Table>
      <Pagination {...pagination}
        itemClass="page-item"
        linkClass="page-link"
        onChange={handlePagination} />
    </>
  )
}

UsersTable.propTypes = {
  users: PropTypes.array,
  handleDeleteUser: PropTypes.func,
  pagination: PropTypes.object,
  handlePagination: PropTypes.func,
}

export { UsersTable }
