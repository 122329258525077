import React, { Component } from "react"
import PropTypes from "prop-types"

import { AutoCompleteMulti } from "~/components/presenters"
import { Col, Row, Label, Button } from "reactstrap"
import { isEmpty, isEqual } from "lodash/fp"
import { queryFields, queryResource } from "~/services/api"
import { ELanguageProficiencyTypes } from "~/common/interfaces/language-types"
import { addKeys } from "~/helpers"

class Languages extends Component {
  constructor(props) {
    super(props)

    const spokenLanguagesInitialValue = !isEmpty(props.value) ? addKeys(props.value) : [{}]

    this.state = {
      languagesList: [ ],
      spokenLanguages: spokenLanguagesInitialValue,
    }

    this.handleAddLanguage = this.handleAddLanguage.bind(this)
    this.onLanguageChanged = this.onLanguageChanged.bind(this)
    this.handlePropertyChange = this.handlePropertyChange.bind(this)
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.value, this.props.value) 
  }

  deleteEmpty(skill) {
    const keys = [
      "languageId",
      "languageName",
      "proficiency",
    ]

    const isEmpty = keys.reduce(
      (areKeysEmpty, key) => key in skill
        ? areKeysEmpty && skill[key] === "" || skill[key] === undefined || skill[key] === 0
        : areKeysEmpty,
      true
    )

    return !isEmpty ? skill : {}
  }

  setSpokenLanguages(spokenLanguages) {
    const langWithKeys = addKeys(spokenLanguages)

    this.setState({ 
      spokenLanguages: langWithKeys,
    }, () => {
      if (this.props.setSpokenLanguages) {
        const processedSpokenLanguages = langWithKeys
          .map(this.deleteEmpty)

        this.props.setSpokenLanguages(processedSpokenLanguages)
      }
    })
  }

  handlePropertyChange(propertyName, index, value) {
    const newLanguages = this.state.spokenLanguages.map((lang, idx) => {
      if (index !== idx) return lang
      return { ...lang, [propertyName]: value }
    })

    this.setSpokenLanguages(newLanguages)
  }

  onLanguageChanged(index) {
    return (input, [{ value: { id: languageId, name: languageName } }]) => {
      try {
        const newLanguages = this
          .state
          .spokenLanguages
          .map(
            (language, sidx) => 
              index !== sidx
                ? language
                : {
                  ...language,
                  languageId,
                  languageName,
                }
          )
        
        this.setSpokenLanguages(newLanguages)
      } catch (e) {
        console.error(e)
      }
    }
  }

  handleProficiencyChange(index, event) {
    this.handlePropertyChange('proficiency', index, event.target.value)
  }

  handleAddLanguage() {
    const spokenLanguages = [
      ...this.state.spokenLanguages,
      {},
    ]

    this.setSpokenLanguages(spokenLanguages)
  }

  handleRemoveLanguage(idx) {
    const spokenLanguages = this.state.spokenLanguages.filter(
      (s, sidx) => idx !== sidx
    )
    
    this.setSpokenLanguages(spokenLanguages)
  }

  filterByLanguageName(languageName, languages) {
    return languages.filter(
      ({ name }) => name.toLowerCase().startsWith(languageName.toLowerCase()) > -1
    )
  }

  clearForm(index) {
    const spokenLanguages = this.state.spokenLanguages.map(
      (langObj, langIndex) => langIndex === index
        ? { proficiency: 0, languageName: "" }
        : langObj
    )

    this.setSpokenLanguages(spokenLanguages)
  }

  resultsMapper({ data }) {
    // eslint-disable-next-line array-bracket-newline
    let items = []

    try {
      items = data.data.map((item) => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
        },
      }))
    } catch (e) {
      console.error(e)
    }
    return items
  }

  render() {
    return (
      <Col>
        <Label className="section-title">Languages</Label>
        {this.state.spokenLanguages.map((language, idx) => (
          <Row className="multiField" key={language.key}>
            <Col className="col-sm-8">
              <AutoCompleteMulti
                resourceName="language"
                resourceField="name"
                stateName=""
                resourceQueryMethod={queryResource}
                queryFields={queryFields}
                onSelected={this.onLanguageChanged(idx)}
                resultsMapper={this.resultsMapper}
                isMulti={false}
                defaultInputValue={language.languageName}
                defaultValue={this.resultsMapper({
                  data: {
                    data: language? [
                      {
                        id: language.languageId,
                        name: language.languageName,
                      },
                    ] : [],
                  },
                })}
              />
              <small
                id="passwordHelpBlock"
                className="form-text text-muted"
              >
                Language Name
              </small>
            </Col>
            <Col className="col-sm-2">
              <select
                name={"proficiency"}
                className="form-control"
                id="exampleFormControlSelect1"
                onChange={this.handleProficiencyChange.bind(this, idx)}
                value={language.proficiency}
              >
                <option>-</option>
                <option value={ELanguageProficiencyTypes.NATIVE}>Native</option>
                <option value={ELanguageProficiencyTypes.ADVANCED}>Advanced</option>
                <option value={ELanguageProficiencyTypes.INTERMEDIATE}>Intermediate</option>
                <option value={ELanguageProficiencyTypes.BEGINNER}>Beginner</option>
              </select>
              <small
                id="passwordHelpBlock"
                className="form-text text-muted"
              >
                Proficiency
              </small>
            </Col>
            <Col>
              <i
                className="fa fa-trash fa-2x"
                onClick={
                  this.state.spokenLanguages.length > 1
                    ? this.handleRemoveLanguage.bind(this, idx)
                    : this.clearForm.bind(this, idx)
                }
                style={{ "color": "grey", "cursor": "pointer" }}
              />
            </Col>
          </Row>
        ))}
        <Button
          className="alert-success"
          onClick={this.handleAddLanguage}
        >
          Add Language
        </Button>
      </Col>
    )
  }
}

Languages.propTypes = {
  setSpokenLanguages: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  edit: PropTypes.bool,
}

export default Languages
