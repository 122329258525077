import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'reactstrap'
import { RepoTableRow } from './RepoTableRow'
import TableHeader from "./TableHeader"

const RepoTable = (props) => {

  const style = {
    hover: {
      cursor: "pointer",
    },
    grey: {
      color: "grey",
    },
    red: {
      color: "red",
    },
    green: {
      color: "#3AC47D",
    },
  }

  const rows = props.contributions.map(repo => (
    <RepoTableRow
      data={repo}
      key={repo.repository.id}
    />
  ))

  return (
    <div>
      <Table striped={true} responsive={true}>
        <TableHeader
          handleSortType={props.handleSortType}
          names={[
            {
              name: <span style={style.hover}>Contributions to repos  <i className="fa fa-sort" /></span>,
              style: style.grey.color,
              columnName: "id",
            },
            {
              name: <span style={style.hover}>Stars  <i className="fa fa-sort" /></span>,
              style: style.grey.color,
              columnName: "githubStars",
            },
            {
              name: <span style={style.hover}>Lines Added <i className="fa fa-sort" /></span>,
              style: style.green.color,
              columnName: "totalLinesAdded",
            },
            {
              name: <span style={style.hover}>Lines removed  <i className="fa fa-sort" /></span>,
              style: style.red.color,
              columnName: "totalLinesRemoved",
            },
            {
              name: <span style={style.hover}>Main language  <i className="fa fa-sort" /></span>,
              style: style.grey.color,
              columnName: "mainLanguage",
            },
            {
              name: <span style={style.hover}>Forks  <i className="fa fa-sort" /></span>,
              style: style.grey.color,
              columnName: "githubForks",
            },
            {
              name: <span style={style.hover}>Watchers  <i className="fa fa-sort" /></span>,
              style: style.grey.color,
              columnName: "githubWatchers",
            },

          ]} />
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>
  )
}

RepoTable.propTypes = {
  contributions: PropTypes.object,
  handleSortType: PropTypes.func,
}

export { RepoTable }
