import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Row } from "reactstrap"

class HubSpotControl extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      buttonDisabled: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  async handleClick() {
    this.setState({ buttonDisabled: true })

    try {
      await this.props.createHubspotDealHandler()
    } catch (e) {
      console.error(e)
    } finally {
      this.setState({ buttonDisabled: false })
    }
  }

  render() {
    return (
      <Row>
        <Col className="col-lg-5">
          <strong>HubSpot Deal:</strong>
          &nbsp;&nbsp;
          {
            this.props.hubspotDealUrl ?
              (<a target="_blank" rel="noopener noreferrer" href={this.props.hubspotDealUrl}>
                Link <i className="fa fa-external-link" />
              </a>)
              :
              (
                <Button color="primary" disabled={this.state.buttonDisabled} onClick={this.handleClick} size="sm">
                  Create Deal
                </Button>
              )
          }
        </Col>
      </Row>
    )
  }
}

HubSpotControl.propTypes = {
  hubspotDealUrl: PropTypes.string,
  createHubspotDealHandler: PropTypes.func,
}

export { HubSpotControl }
