import { IStatusFormProfile } from "~/common/interfaces"
import {
  InvitationOutgoing,
} from "~/services/api/adapters"
import { request } from "~/services/api/resources/request"

const sendInvitation = async (invitationData: IStatusFormProfile) => request({
  method: "POST",
  url: "invitation",
  data: InvitationOutgoing(invitationData),
})

const getLatestInvitation = async (profileId: string) => request({
  method: "GET",
  url: `/invitation/latest?referenceId=${profileId}`,
})

export {
  sendInvitation,
  getLatestInvitation,
}
