import * as Joi from "joi"
import * as React from "react"

import { flow } from "lodash/fp"
import { Redirect, withRouter } from "react-router-dom"
import { View } from "samx"
import {
  SkillForm,
} from "~/components/presenters"

import { createSkill, getSkills } from "~/samx/actions/collections"
import {
  getInitialState,
  ICoreSkillFormDataState,
  SkillContainer,
} from "./common"

export class NewSkillFormClass extends SkillContainer {
  public state = getInitialState()

  public async saveData(skillData: ICoreSkillFormDataState) {
    this.setState({
      isSubmitEnabled: false,
    })

    await createSkill(skillData).then(getSkills)

    this.makeSubmitEnabled()
  }

  public extraProps() {
    return {
      isSubmitEnabled: this.state.isSubmitEnabled,
    }
  }

  public makeSubmitEnabled() {
    this.setState({
      isSubmitEnabled: true,
    })
  }

  public render() {
    if (this.state.saved) {
      return <Redirect to={"/connect/skills"} />
    }
    return <SkillForm {...this.prepareProps()} />
  }
}

export const NewSkillForm: any = flow(
  withRouter,
  View,
)(NewSkillFormClass as any)
