import { IAuthUser } from "~/common/interfaces"
import { IProposition } from "~/common/interfaces/proposition"
import { emptyUser } from "../models/user"

const NewUser = (user: IAuthUser): IProposition => ({
  name: "user.login",
  value: user,
})

const NoUser = (): IProposition => ({
  name: "user.logout",
  value: emptyUser,
})

const ForgetUser = (): IProposition => ({
  name: "user.flush",
  value: emptyUser,
})

export {
  NewUser,
  NoUser,
  ForgetUser,
}
