import * as Joi from "joi"

import { ELanguageProficiencyTypes } from "~/common/interfaces/language-types"
import { arrifyEnumeration } from "~/helpers/common"

const types = arrifyEnumeration(ELanguageProficiencyTypes)

export const spokenLanguageformValidation = Joi
  .object()
  .allow({})
  .keys({
    id: Joi.string(),
    languageId: Joi.string().required(),
    languageName: Joi.string(),
    proficiency: Joi.string().required().valid(...types),
})
