import {
  CLIENT_ORG_ID,
  CLIENT_PUBLIC_PROFILE_URL,
  PRIMARY_ORG_ID,
  PRIMARY_PUBLIC_PROFILE_URL,
} from "~/config/env"

const orgIdToProfileUrlMap = {
  [CLIENT_ORG_ID]: CLIENT_PUBLIC_PROFILE_URL,
  [PRIMARY_ORG_ID]: PRIMARY_PUBLIC_PROFILE_URL,
}

const getPublicProfileUrl = (orgId: string) =>
  orgIdToProfileUrlMap[orgId]

export { getPublicProfileUrl }
