import { IPlace } from "~/common/interfaces"

const PlaceOutgoing = (place): IPlace => ({
  ...place,
  id: place.id || null,
  logo: place.logo || undefined,
  city: place.city || undefined,
})

export {
  PlaceOutgoing,
}
