import { IFileUploadPayload } from "~/common/interfaces"

const FileOutgoing = (fileuploadData: IFileUploadPayload) => {
  const formData = new FormData()
  const keys = Object.keys(fileuploadData)

  keys.forEach((key: string) => {
    formData.append(key, fileuploadData[key])
  })

  return formData
}

export { FileOutgoing }
