import * as React from "react"

import { Card, CardBody } from "reactstrap"
import { EditPlaceForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const EditPlaceFormScreen: React.SFC<{}> = (
  props: any,
): React.ReactElement<any> => {
  const renderEditPlaceForm = () => (
    <Card>
      <CardBody>
        <h2>Edit Place</h2>

        <EditPlaceForm placeId={props.match.params.placeId} />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen
      {...props}
      renderListComponent={renderEditPlaceForm}
    />
  )
}
