import * as React from "react"

interface IProps {
  shouldRenderComponent: boolean,
  component: React.ReactNode,
  fallback?: React.ReactNode,
}

const Switcher: React.FC<IProps> = ({
  shouldRenderComponent,
  component,
  fallback,
}): React.ReactElement<any> => (
  <React.Fragment>
    {(shouldRenderComponent) ? component : (fallback || null)}
  </React.Fragment>
)

export { Switcher }
