import * as Joi from "joi"
import { TOP_SKILLS_LIMIT } from "~/config/constants"

export const skillformValidation = Joi
  .object({
    id: Joi.string().allow("").optional(),
    skillId: Joi.string().required(),
    startedAt: Joi.string().when("order", {
      is: Joi.number().less(TOP_SKILLS_LIMIT + 1),
      then: Joi.required(),
      otherwise: Joi.allow("").optional(),
    }),
    order: Joi.number().required(),
  })
