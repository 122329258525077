import * as Joi from "joi"
import * as React from "react"

import { omit } from "lodash/fp"
import { Redirect } from "react-router"
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap"
import { State } from "samx"
import { View } from "samx"
import {
  EPlaceStatus,
  EPlaceType,
  IPlace,
  IRemoteFile,
  statusLabelMap,
} from "~/common/interfaces"
import { FileUpload } from "~/components/containers"
import { CitiesDropdown, TextInput } from "~/components/presenters"
import { CONNECT_PLACES_BUCKET_NAME } from "~/config/env"
import { arrifyEnumeration } from "~/helpers"

enum EStateField {
  name = "name",
  bio = "bio",
  type = "type",
  status = "status",
  logo = "logo",
  city = "city",
  validationError = "validationError",
  isSubmitEnabled = "isSubmitEnabled",
  isSubmitSuccessful = "isSubmitSuccessful",
}

export type ICorePlaceFormDataState = IPlace

interface IUtilState {
  readonly [EStateField.validationError]:
    | Joi.ValidationError
    | {}
    | string
  readonly [EStateField.isSubmitEnabled]: boolean
  readonly [EStateField.isSubmitSuccessful]: boolean
}

export type IPlaceFormState = ICorePlaceFormDataState & IUtilState

export interface IPlaceFormProps extends IPlace {
  validationSchema: Joi.JoiObject
  saveData: ({
    name,
    bio,
    type,
    logo,
    city,
    status,
  }: IPlace) => Promise<void>
}

const statuses = arrifyEnumeration(EPlaceStatus)

const getInitialState = (props: IPlaceFormProps) => ({
  name: props.name || "",
  bio: props.bio || "",
  type: props.type || EPlaceType.undefined,
  city: props.city || null,
  status: props.status || EPlaceStatus.underReview,
  logo: props.logo || null,
  validationError: {},
  isSubmitSuccessful: false,
  isSubmitEnabled: true,
})

class PlaceFormClass extends React.Component<
  IPlaceFormProps,
  IPlaceFormState
> {
  public state = State(() => () => getInitialState(this.props))

  constructor(props) {
    super(props)

    this.setCity = this.setCity.bind(this)
  }

  public onInputChange = (field: EStateField) => (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const value = e.currentTarget.value
    this.setState((prevstate) => ({
      ...prevstate,
      [field]: value,
    }))
  }

  public onInputLogoChange = (files: Array<IRemoteFile>): void => {
    this.setState({
      [EStateField.logo]: files.length > 0 ? files[0] : null,
    })
  }

  public onInputLogoRemove = (file: File): void => {
    this.setState({
      [EStateField.logo]: null,
    })
  }

  public onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!this.validate()) {
      return
    }

    try {
      this.setState({ [EStateField.isSubmitEnabled]: false })
      await this.props.saveData(this.prepDataToSubmit())
      this.setState({ [EStateField.isSubmitEnabled]: true }, () => {
        this.setState({ [EStateField.isSubmitSuccessful]: true })
      })
    } catch (e) {
      this.setState({
        [EStateField.validationError]: e.message,
        [EStateField.isSubmitEnabled]: true,
      })
    }
  }

  public setCity(input, [ { id, name } ]) {
    try {
      if (id) {
        this.setState(
          (prevState) => ({
            ...prevState,
            [EStateField.city]: { id, name },
          }),
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  public render() {
    return (
      <div>
        {this.state.isSubmitSuccessful ? (
          <Redirect to="/connect/places" />
        ) : (
          <Form onSubmit={this.onSubmit}>
            {Object.keys(this.state.validationError).length !== 0 && (
              <FormGroup>
                <Alert color="danger">
                  {this.state.validationError.toString()}
                </Alert>
              </FormGroup>
            )}

            <FormGroup>
              <TextInput
                type="text"
                name="name"
                value={this.state.name}
                placeHolderText="Name"
                labelText="Name*"
                required={true}
                handleChange={this.onInputChange(EStateField.name)}
                maxLength={255}
              />
            </FormGroup>

            <FormGroup>
              <TextInput
                type="textarea"
                name="bio"
                value={this.state.bio}
                placeHolderText="Bio"
                labelText="Bio"
                required={false}
                handleChange={this.onInputChange(EStateField.bio)}
              />
            </FormGroup>

            <FormGroup>
              <Col>
                <Label className="section-title" for="type">
                  Type*
                </Label>
                <Input
                  type="select"
                  name="type"
                  onChange={this.onInputChange(EStateField.type)}
                  value={this.state.type}
                  id="type"
                >
                  <option value={EPlaceType.school}>School</option>
                  <option value={EPlaceType.organisation}>
                    Organisation
                  </option>
                  <option value={EPlaceType.undefined}>
                    Undefined
                  </option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col>
                <Label className="section-title" for="status">
                  Status*
                </Label>
                <Input
                  type="select"
                  name="status"
                  onChange={this.onInputChange(EStateField.status)}
                  value={this.state.status}
                  id="status"
                >
                  {statuses.map((status) => (
                    <option key={status} value={status}>
                      {statusLabelMap[status]}
                    </option>
                  ))}
                </Input>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col>
                <CitiesDropdown
                  label={"City"}
                  isMulti={false}
                  onChange={this.setCity}
                  value={[this.props.city] as any}
                />
              </Col>
            </FormGroup>

            <FormGroup>
              <FileUpload
                label="Logo"
                multiple={false}
                bucket={CONNECT_PLACES_BUCKET_NAME}
                files={[this.props.logo]}
                onDrop={this.onInputLogoChange}
                onRemove={this.onInputLogoRemove}
              />
            </FormGroup>

            <FormGroup>
              <Col>
                <Button
                  color="success"
                  type="submit"
                  name="submit"
                  disabled={!this.state.isSubmitEnabled}
                >
                  Submit
                </Button>
              </Col>
            </FormGroup>
          </Form>
        )}
      </div>
    )
  }

  private validate() {
    const { error } = Joi.validate(
      this.prepDataToSubmit(),
      this.props.validationSchema,
    )

    if (error) {
      this.setState({
        [EStateField.validationError]: error,
      })

      return false
    }

    return true
  }

  private prepDataToSubmit(): IPlace {
    return {
      ...omit([
        EStateField.validationError,
        EStateField.isSubmitSuccessful,
        EStateField.isSubmitEnabled,
        "city.coordinates",
        "city.country",
        "city.accent",
        "logo.path",
      ])(this.state),
    } as IPlace
  }
}

export const PlaceForm = View(PlaceFormClass)
