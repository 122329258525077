import * as PropTypes from "prop-types"
import * as React from "react"

import { NavLink } from "react-router-dom"
import { Button, Card, Nav, NavItem } from "reactstrap"
import { typeProps } from "~/helpers/common"

const floatingTopMenu = (props: {
  items: Array<{
    route: string,
    render: RenderFunction,
  }>,
}) => {

  const renderItem = (item: { route: string, render: RenderFunction }, index: number) => (
    <NavItem key={index}>
      <NavLink
        to={item.route}
      >
        <Button
          color="link"
          size={"lg"}
        >
          {item.render()}
        </Button>
      </NavLink>
    </NavItem>
  )

  const hasItems = props.items && props.items.length > 0

  if (!hasItems) {
    console.warn("FloatingTopMenu: the provided items array is empty!")
  }

  return (
    <Card className="col-lg mx-auto" id="formContainer">
      <Nav>
        {
          hasItems
            ? props.items.map(renderItem)
            : null
        }
      </Nav>
    </Card>
  )
}

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export const FloatingTopMenu = typeProps(propTypes)(floatingTopMenu)
