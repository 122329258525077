import * as functional from "react-functional"

import { flow, keys, reduce } from "lodash/fp"

const uncappedReduce = (reduce as any).convert({ cap: false })

const typeProps = (propTypes: { [i: string ]: any }) => (component) => {
  component.propTypes = propTypes
  return component
}

const addLifecyleMethods = (lifecycleMethods: object) => (component) => functional(component, lifecycleMethods)

const loadScript: (url: string) => Promise<{
  script: HTMLScriptElement,
  url: string,
}> = (url: string) => new Promise((resolve, reject) => {
  const script = document.createElement("script") as HTMLScriptElement

  script.src = url
  script.async = true

  script.onload = (event) => {
    resolve({ url, script })
  }

  script.onerror = () => {
    reject({ url, script })
  }

  document.body.appendChild(script)
})

const arrifyEnumeration: (x: any) => Array<string> = (enumeration: any) => flow(
  keys, uncappedReduce(
    (arr, key) => [...arr, enumeration[key]],
    [],
  ),
)(enumeration as any) as any

const bindAll = (ctx, mtdNames) => {
  if (!Array.isArray(mtdNames)) {
    mtdNames = [mtdNames]
  }
  mtdNames.map((mtd) => {
    try {
      ctx[mtd] = ctx[mtd].bind(ctx)
    } catch (e) {
      const ctxName = ctx.name ? (", " + ctx.name) : ""
      const methodName = typeof mtd === "function" ? mtd.name : mtd
      throw new Error("Cannot bind mtd " + methodName + " to the supplied ctx" + ctxName)
    }
    return null
  })
}

export {
  typeProps,
  addLifecyleMethods,
  loadScript,
  arrifyEnumeration,
  bindAll,
}
