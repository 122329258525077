import * as React from "react"
import { Col, Label } from "reactstrap"
import { userFullName } from "~/helpers"
import { queryResource } from "~/services/api"
import { AutoCompleteMulti } from "../../presenters/InputFields/AutoCompleteMulti"

export interface IManagerProps {
  managerId: string,
  managerName: string,
  onChange: (data: any) => any,
}

export interface IManagerState {
  managerId: string,
  managerName: string,
}

export class Manager extends React.Component<IManagerProps, IManagerState> {
  public constructor(props) {
    super(props)

    this.state = {
      managerName: props.managerName,
      managerId: props.managerId,
    }

    this.onSelected = this.onSelected.bind(this)
  }

  public onSelected(_, value, meta) {
    const item = value[0]
    const manager = {
      id: item.value.id,
      name: item.label,
    }

    this.setState({
      managerId: manager.id,
      managerName: item.label,
    })
    this.props.onChange(manager)
  }

  public render() {
    return (
        <Col xs="auto">
          <Label className="section-title">Freelancer Manager</Label>
          <AutoCompleteMulti
            key={this.state.managerName}
            resourceName="users"
            resourceField="name"
            stateName=""
            resourceQueryMethod={queryResource}
            queryFields={this.queryManagers}
            defaultOptions={true}
            onSelected={this.onSelected}
            resultsMapper={this.resultsMapper}
            defaultInputValue={this.state.managerName}
            isMulti={false}
            extraQueryParams={{ role: "internal" }}
          />
        </Col>
    )
  }

  private queryManagers(resources, inputValue) {
    return {
      ...resources,
      resourceQuery: inputValue,
    }
  }

  private resultsMapper = ({ data }) => {
    try {
      return data.data.map((item) => ({
        label: userFullName(item),
        value: {
          id: item.id,
        },
      }))
    } catch (e) {
      console.error(e)
    }
  }
}
