import * as React from "react"
import { Card, CardBody } from "reactstrap"
import { EditSkillForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens/ConnectListingScreen/ConnectListingScreen"

export const EditSkillFormScreen: React.SFC<{}> = (
  props: any,
): React.ReactElement<any> => {
  const skillId = props.match.params.id
  const renderEditSkillForm = () => (
    <Card>
      <CardBody>
        <h2>Edit Skill</h2>

        <EditSkillForm skillId={skillId} />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen {...props} renderListComponent={renderEditSkillForm} />
  )
}
