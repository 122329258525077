const SkillIncoming = ({ category, parent }, order = 0) => ({
  id: category.id || null,
  name: category.name,
  description: category.description,
  parent: {
    name: parent.name,
    id: parent.id,
  },
  order: category.order || order,
})

export {
  SkillIncoming,
}
