import * as Joi from "joi"

import { EPlaceStatus, EPlaceType } from "~/common/interfaces"

export const placeformValidation = Joi.object().keys({
  name: Joi.string().required(),
  bio: Joi.string().allow(""),
  type: Joi.string()
    .valid(
      EPlaceType.organisation,
      EPlaceType.school,
      EPlaceType.undefined,
    )
    .required(),
  logo: Joi.object({
    id: Joi.string().optional(),
    name: Joi.string(),
    bucket: Joi.string(),
    mimeType: Joi.string(),
  }).allow(null).optional(),
  status: Joi.string()
    .valid(
      EPlaceStatus.underReview,
      EPlaceStatus.published,
      EPlaceStatus.rejected,
    )
    .required(),
  city: Joi.object().keys({
    id: Joi.string().required(),
    name: Joi.string().required(),
  }),
})
