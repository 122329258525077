import React from "react"
import PropTypes from 'prop-types'
import { CardBody, Col, Button, Row } from 'reactstrap'
import { RepoTable } from "./RepoTable"
import { NotesTable } from "./NotesTable"
import { Chart } from "./Chart"
import get from "lodash/get"

class DetailedViewBottom extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTable: 1,
    }
  }

  renderNoContributions() {
    return (
      <div>No contributions found</div>
    )
  }

  render() {
    const repositories = get(this.props, "contributions.repositories.contributions", [])
    const languages = get(this.props, "contributions.languages.contributions", [])

    return (
      <div>
        <Row>
          <Col className="col-lg-12">
            <Button
              size="lg"
              color="link"
              onClick={() => this.setState({ activeTable: 1 })}
            >
              LANGUAGE
            </Button>
            <Button
              size="lg"
              color="link"
              onClick={() => this.setState({ activeTable: 2 })}
            >
              REPOSITORIES
            </Button>
            <Button
              size="lg"
              color="link"
              onClick={() => this.setState({ activeTable: 3 })}
            >
              NOTES
            </Button>
          </Col>
        </Row>
        <CardBody>
          {this.state.activeTable === 1 &&
            (!!languages.length && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Chart
                  series={languages.map(n => n.totalLinesAdded || 0)}
                  labels={languages.map(n => n.language)}
                  name="Languages"
                  smallText="(Lines Added)"
                />
              </div>
            ) || this.renderNoContributions())
          }
          {this.state.activeTable === 2 &&
            (!!repositories.length &&
              <RepoTable
                contributions={repositories}
                handleSortType={this.props.handleSortType}
              /> || this.renderNoContributions()
            )
          }
          {this.state.activeTable === 3 &&
            <NotesTable notes={this.props.notes} deleteNote={this.props.deleteNote}/>
          }
        </CardBody>
      </div>
    )
  }
}

DetailedViewBottom.propTypes = {
  contributions: PropTypes.object,
  notes: PropTypes.array,
  deleteNote: PropTypes.func,
  handleSortType: PropTypes.func,
}

export { DetailedViewBottom }
