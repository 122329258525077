import * as React from "react"

import { withRouter } from "react-router-dom"
import { Logout } from "~/components/presenters"
import { logout } from "~/samx/actions/auth"

export const logoutContainer: React.FC<any> = ({ history }: any): React.ReactElement<any> => {
  const logoutAndRedirect = () => {
    logout().then(
      () => history.push("/auth/login"),
    )
  }

  return (
    <Logout onLogoutHandler={logoutAndRedirect} />
  )
}

const LogoutContainer = withRouter(logoutContainer)

export {
  LogoutContainer,
}
