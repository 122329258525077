import React from "react"

const Error = () => {
  return (
    <div className="fa-5x">
      <br />
      <div className="no-content-container">
        <i className="icon-ban" />
        <p>
          <i>Well this isn't meant to happen...</i>
        </p>
        <h3>Something went wrong</h3>
        <p id="bot">Please report to Tech after checking your internet connection</p>
      </div>
    </div>
  )
}

export default Error
