import * as Joi from "joi"
import * as _ from "lodash"
import * as React from "react"

import { flow, omit } from "lodash/fp"
import { Redirect, withRouter } from "react-router-dom"

import { View } from "samx"
import {
  SkillForm,
  Spinner,
} from "~/components/presenters"
import { getSkill, updateSkill } from "~/samx/actions/collections"
import {
  getInitialState,
  ICoreSkillFormDataState,
  SkillContainer,
} from "./common"

export class EditSkillFormClass extends SkillContainer {
  public state = getInitialState()

  public async componentDidMount() {
    const skill = await getSkill((this.props as any).skillId)

    this.setState({
      skill,
      parent: skill.parent,
      isLoaded: true,
    })
  }

  public async saveData(skillData: ICoreSkillFormDataState) {
    this.setState({
      isSubmitEnabled: false,
    })

    await updateSkill(skillData)

    this.setState({
      isSubmitEnabled: true,
    })
  }

  public extraProps() {
    const saveData = this.saveData.bind(this)
    const skill = this.state.skill

    return {
      ...skill,
      saveData,
      isSubmitEnabled: this.state.isSubmitEnabled,
    }
  }

  public render() {
    const props = this.prepareProps()

    if (!this.state.isLoaded) {
      return <Spinner />
    }

    if (this.state.saved) {
      return <Redirect to={"/connect/skills"} />
    }

    return <SkillForm {...props} />
  }
}

export const EditSkillForm: any = flow(
  withRouter,
  View,
)(EditSkillFormClass as any)
