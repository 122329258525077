import React, { Component } from "react"

import PropTypes from "prop-types"
import { FileUpload } from "~/components/containers"
import { chunk } from "~/helpers/operations"
import { Col, Row, Label, Button, Input } from "reactstrap"
import { CONNECT_PORTFOLIOS_BUCKET_NAME } from "~/config/env"
import { buildImageUrl, isObjectEmpty, addKeys } from "~/helpers"
import { isEmpty } from "lodash/fp"
 
import "./Portfolios.scss"

class Portfolios extends Component {
  retrieveStateFromProps() {
    const arePropsValid = Array.isArray(this.props.value) && this.props.value.reduce(
      (allElementsAreObjects, element) => allElementsAreObjects
        && typeof element === 'object' 
        && !Array.isArray(element)
        && !isEmpty(element),
      true
    )

    return arePropsValid ? {
      portfolios: addKeys(this.props.value),
    } : {
      portfolios: [

      ],
    }
  }

  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()

    this.rowData = {
      defaultColumns: 3,
      maxColumnsPerRow: 12,
    }
    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.handleEditPortfolio = this.handleEditPortfolio.bind(this)
  }

  onFilesAdded(files) {
    const portfolios = files.map((file) => ({
      file,
      title: '',
      description: '',
      href: '',
    }))

    this.handleAddPortfolios(portfolios)
  }

  deleteEmpty(portfolios) {
    return portfolios.filter((portfolio) =>
      !isObjectEmpty(portfolio))
  }

  filterPortfolios(portfolios) {
    return this.deleteEmpty(portfolios)
  }

  setPortfolios(portfolios) {
    const portfoliosWithKeys = addKeys(portfolios)

    this.setState({ 
      portfolios: portfoliosWithKeys,
    }, () => {
      const processedPortfolios = this.filterPortfolios(portfoliosWithKeys)

      if (this.props.setPortfolios) {
        this.props.setPortfolios(processedPortfolios)
      }
    })
  }

  getChunkAttrs() {
    const {
      defaultColumns,
      maxColumnsPerRow,
    } = this.rowData

    return {
      columns: defaultColumns,
      columnSize: maxColumnsPerRow / defaultColumns,
    }
  }

  handleAddPortfolios(newPortfolios) {
    const portfolios = [
      ...this.state.portfolios,
      ...newPortfolios,
    ]

    this.setPortfolios(portfolios)
  }

  handleRemovePortfolio(idx) {
    const portfolios = this.filterPortfolios(this.state.portfolios).filter(
      (s, sidx) => idx !== sidx
    )

    this.setPortfolios(portfolios)
  }

  handleEditPortfolio(idx) {
    return evt => {
      const newPortfolios = this.filterPortfolios(this.state.portfolios).map(
        (portfolio, sidx) => {
          if (idx !== sidx) return portfolio
          return { ...portfolio, [evt.target.name]: evt.target.value }
        }
      )

      this.setPortfolios(newPortfolios)
    }
  }

  render() {
    const {
      columns,
      columnSize,
    } = this.getChunkAttrs()

    const portfolioChunks = chunk(
      this.deleteEmpty(this.state.portfolios),
      columns
    )

    return (
      <Col>
        <Label className="section-title">Portfolios</Label>

        {
          portfolioChunks.map((portfolios, idxChunk) => (
            <Row className="multiField" key={idxChunk}>
              {
                portfolios.map((portfolio, idx) => {
                  const {
                    file,
                    title,
                    description,
                    href,
                  } = portfolio

                  const imgUrl = buildImageUrl({
                    bucket: file.bucket,
                    id: file.name,
                  })

                  const linkStyle = {
                    backgroundImage: `url('${imgUrl}')`,
                  }

                  return (
                    <Col className={`col-md-${columnSize}`} key={portfolio.key}>
                      <div className="border p-3">
                        <div className="thumbnail-container">
                          <a href={imgUrl} target="_blank" rel="noopener noreferrer" style={linkStyle}>
                          </a>
                        </div>

                        <div className="mt-2">
                          <Input
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Title (required)"
                            onChange={this.handleEditPortfolio(idx)}
                            defaultValue={title}
                          />
                        </div>

                        <div className="mt-2">
                          <Input
                            name="description"
                            type="textarea"
                            className="form-control"
                            placeholder="Description"
                            onChange={this.handleEditPortfolio(idx)}
                            defaultValue={description}
                          />
                        </div>

                        <div className="mt-2">
                          <Input
                            name="href"
                            type="text"
                            className="form-control"
                            placeholder="URL"
                            onChange={this.handleEditPortfolio(idx)}
                            defaultValue={href}
                          />
                        </div>

                        <div className="mt-2 text-center">
                          <Button color="danger" size="sm" onClick={this.handleRemovePortfolio.bind(this, idx)}>
                            <i
                              className="fa fa-trash fa-2x"
                            />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          ))
        }

        <br />

        <FileUpload
          label="Upload portfolio items here"
          multiple={true}
          bucket={CONNECT_PORTFOLIOS_BUCKET_NAME}
          // eslint-disable-next-line array-bracket-newline
          files={[]}
          onDrop={this.onFilesAdded.bind(this)}
          onRemove={() => {}}
          handleRemove={false}
        />
      </Col>
    )
  }
}

Portfolios.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setPortfolios: PropTypes.func,
  edit: PropTypes.bool,
}

export default Portfolios
