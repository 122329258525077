import * as React from "react"
import { PageButton } from "~/components/presenters/Buttons/PageButton"

interface IProps {
  pageNumber: number,
  totalCount: number,
  pageChange: (pageNumber: number) => void,
  isLoading: boolean,
  perPage: number,
}

export const PageButtonsContainer = (props: IProps) => {
  const isNextBtnDisabled = props.perPage * props.pageNumber > props.totalCount
  return (
    <div className="page-btn-container">
      <p className="pageNum">Page {props.pageNumber}</p>
      <h5>{props.totalCount} results found</h5>
      <div>
        {props.pageNumber > 1 &&
          <PageButton
            changeNum={-1}
            pageChange={props.pageChange}
            btnText="Prev"
            pageNumber={props.pageNumber}
            isLoading={props.isLoading}
          />}
        <PageButton
          changeNum={0}
          pageChange={props.pageChange}
          btnText="Refresh"
          pageNumber={props.pageNumber}
          isLoading={props.isLoading}
        />
        <PageButton
          changeNum={1}
          pageChange={props.pageChange}
          btnText="Next"
          pageNumber={props.pageNumber}
          isLoading={props.isLoading}
          disabled={isNextBtnDisabled}
        />
      </div>
    </div>
  )
}
