import * as PropTypes from "prop-types"
import * as React from "react"

import { ListingScreen } from "~/components/screens/ListingScreen/ListingScreen"
import { typeProps } from "~/helpers/common"

export interface IConnectListingProps {
  renderListComponent: () => any,
}

const connectListingScreen: React.SFC<IConnectListingProps> = (props: IConnectListingProps):
  React.ReactElement<IConnectListingProps> => {

    // TODO: This list is duplicated
    const navItems = [
      {
        render: () => (
          <span>
            <i className="fa fa-user" /> Profiles
          </span>
        ),
        route: "/connect/home",
      },
      {
        render: () => (
          <span>
            <i className="fa fa-building" /> Places
          </span>
        ),
        route: "/connect/places",
      },
      {
        render: () => (
          <span>
            <i className="fa fa-gear" /> Skills
          </span>
        ),
        route: "/connect/skills",
      },
      {
        render: () => (
          <span>
            <i className="fa fa-building" /> Spoken Languages
          </span>
        ),
        route: "/connect/spoken-languages",
      },
    ]

    return (
      <ListingScreen
        navItems={navItems}
        renderListComponent={props.renderListComponent}
      />
    )
}

const propTypes = {
  renderListComponent: PropTypes.func,
}

export const ConnectListingScreen: React.SFC<IConnectListingProps> = typeProps(propTypes)(connectListingScreen)
