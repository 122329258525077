import { IProposition } from "~/common/interfaces/proposition"

const NewCollection = (
  collectionName: string,
) => (collection: Array<any>): IProposition => ({
  name: "collections.new-collection",
  value: {
    [collectionName]: collection,
  },
})

const AppendToCollection = (
  collectionName: string,
) => (newItem: any) => (
  collectionsModel: any,
): IProposition => ({
  name: "collections.append-to-collection",
  key: collectionName,
  value: [
    ...collectionsModel[collectionName].filter((item) => item.id !== newItem.id),
    newItem,
  ],
})

const UpdateCollectionItem = (
  collectionName: string,
) => (item: any) => (
  collectionsModel: any,
): IProposition => ({
  name: "collections.update-collection-item",
  key: collectionName,
  value: [
    ...collectionsModel[collectionName].filter(
      (o: any) => o.id !== item.id,
    ),
    item,
  ],
})

const RemoveCollectionItem = (
  collectionName: string,
) => (item: any) => (
  collectionsModel: any,
): IProposition => ({
  name: "collections.remove-from-collection",
  value: {[collectionName]: [...collectionsModel[collectionName].filter(
      (o: any) => o.id !== item.id,
    ),
  ]},
})

export {
  AppendToCollection,
  NewCollection,
  RemoveCollectionItem,
  UpdateCollectionItem,
}
