import { envStore } from "~/helpers/env-store"

envStore.configure({
  provide() {

    if (process.env.NODE_ENV === "production") {
      return {
        ...process.env,
        // @ts-ignore
        ...window.___env,
      }
    }

    return process.env
  },
})

const {
  CONNECT_API_ROOT_URL,
  CONNECT_STORAGE_SERVICE_URL,
  CONNECT_IMAGE_API_URL,

  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_SCOPE,
  GOOGLE_API_CLIENT_URL,
  AUTH_TOKEN_REFRESH_INTERVAL,

  CONNECT_PROFILES_BUCKET_NAME,
  CONNECT_PLACES_BUCKET_NAME,
  CONNECT_PORTFOLIOS_BUCKET_NAME,

  NODE_ENV: ENVIRONMENT,

  HUBSPOT_DEAL_URL,
  TRACKING_ID,

  PRIMARY_ORG_ID,
  CLIENT_ORG_ID,

  PRIMARY_PUBLIC_PROFILE_URL,
  CLIENT_PUBLIC_PROFILE_URL,
} = envStore.get("*")

// TODO: Look over keys
console.assert(CONNECT_API_ROOT_URL !== "", "CONNECT_API_ROOT_URL is required but was not specified")
console.assert(CONNECT_IMAGE_API_URL !== "", "CONNECT_IMAGE_API_URL is required but was not specified")
console.assert(CONNECT_STORAGE_SERVICE_URL !== "", "CONNECT_STORAGE_SERVICE_URL is required but was not specified")

console.assert(GOOGLE_AUTH_CLIENT_ID !== "", "GOOGLE_AUTH_CLIENT_ID is required but was not specified")
console.assert(GOOGLE_AUTH_SCOPE !== "", "GOOGLE_AUTH_SCOPE is required but was not specified")
console.assert(GOOGLE_API_CLIENT_URL !== "", "GOOGLE_API_CLIENT_URL is required but was not specified")

console.assert(ENVIRONMENT !== "", "ENVIRONMENT is required but was not specified")

console.assert(CONNECT_PROFILES_BUCKET_NAME !== "", "CONNECT_PROFILES_BUCKET_NAME is required but was not specified")
console.assert(CONNECT_PLACES_BUCKET_NAME !== "", "CONNECT_PLACES_BUCKET_NAME is required but was not specified")
// tslint:disable-next-line: max-line-length
console.assert(CONNECT_PORTFOLIOS_BUCKET_NAME !== "", "CONNECT_PORTFOLIOS_BUCKET_NAME is required but was not specified")
console.assert(AUTH_TOKEN_REFRESH_INTERVAL !== "", "AUTH_TOKEN_REFRESH_INTERVAL is required but was not specified")

console.assert(HUBSPOT_DEAL_URL !== "", "HUBSPOT_DEAL_URL is required but was not specified")

console.assert(TRACKING_ID !== "", "TRACKING_ID is required but was not specified")

console.assert(PRIMARY_ORG_ID !== "", "PRIMARY_ORG_ID is required but was not specified")
console.assert(CLIENT_ORG_ID !== "", "CLIENT_ORG_ID is required but was not specified")

console.assert(PRIMARY_PUBLIC_PROFILE_URL !== "", "PRIMARY_PUBLIC_PROFILE_URL is required but was not specified")
console.assert(CLIENT_PUBLIC_PROFILE_URL !== "", "CLIENT_PUBLIC_PROFILE_URL is required but was not specified")

export {
  CONNECT_API_ROOT_URL,
  CONNECT_STORAGE_SERVICE_URL,
  CONNECT_IMAGE_API_URL,

  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_SCOPE,
  GOOGLE_API_CLIENT_URL,

  ENVIRONMENT,

  CONNECT_PROFILES_BUCKET_NAME,
  CONNECT_PLACES_BUCKET_NAME,
  CONNECT_PORTFOLIOS_BUCKET_NAME,
  AUTH_TOKEN_REFRESH_INTERVAL,

  HUBSPOT_DEAL_URL,
  TRACKING_ID,

  PRIMARY_ORG_ID,
  CLIENT_ORG_ID,

  PRIMARY_PUBLIC_PROFILE_URL,
  CLIENT_PUBLIC_PROFILE_URL,
}
