import PropTypes from "prop-types"

import React, { Component } from "react"

import { RichTextArea, AutoCompleteMulti, CitiesDropdown } from "~/components/presenters"
import { queryFields, queryResource } from "~/services/api"
import { omit, isEqual } from "lodash/fp"
import { Col, Row, Label, Button } from "reactstrap"
import { addKeys } from "~/helpers"
import {EPlaceStatus} from "~/common/interfaces"

class Experiences extends Component {
  retrieveStateFromProps() {
    const arePropsValid = Array.isArray(this.props.value) && this.props.value.reduce(
      (allElementsAreObjects, element) => allElementsAreObjects
        && typeof element === 'object' && !Array.isArray(element),
      true
    )

    return arePropsValid ? {
      experiences: addKeys(this.props.value),
    } : {
      experiences: [
        {},
      ],
    }
  }

  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()
    this.handleAddExperience = this.handleAddExperience.bind(this)
    this.onCompanyChanged = this.onCompanyChanged.bind(this)
    this.onCityChanged = this.onCityChanged.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState.experiences, this.state.experiences)
  }

  deleteEmptyExperiences(experience) {
    const keys = [
      "jobTitle",
      "startDate",
      "endDate",
      "description",
      "companyId",
      "cityId",
    ]

    const isEmpty = keys.reduce(
      (areKeysEmpty, key) => key in experience
        ? areKeysEmpty && experience[key] === "" || experience[key] === undefined
        : areKeysEmpty,
      true
    )

    return !isEmpty ? experience : {}
  }

  omitAutoCompleteProperties(experience) {
    return omit([
      "companyName",
      "locationName",
    ])(experience)
  }

  setExperiences(experiences) {
    const expWithKeys = addKeys(experiences)

    this.setState({
      experiences: expWithKeys,
    }, () => {
      if (this.props.setExperiences) {
        const processedExperiences = expWithKeys
          .map(this.omitAutoCompleteProperties)
          .map(this.deleteEmptyExperiences)

        this.props.setExperiences(processedExperiences)
      }
    })
  }

  handleChange(idx) {
    return evt => {
      const newExperiences = this.state.experiences.map(
        (experience, sidx) => {
          if (idx !== sidx) return experience
          return {
            ...experience,
            [evt.target.name]: evt.target.value,
          }
        }
      )

      this.setExperiences(newExperiences)
    }
  }

  handleAddExperience() {
    const experiences = [
      ...this.state.experiences,
      {},
    ]

    this.setExperiences(experiences)
  }

  handleRemoveExperience(idx) {
    const experiences = this
      .state
      .experiences
      .filter(
        (s, sidx) => idx !== sidx
      )

    this.setExperiences(experiences)
  }

  setExperienceDescription(idx, value) {
    const experiences = this.state.experiences.map(
      (experience, sidx) => {
        if (idx !== sidx) return experience
        return { ...experience, description: value }
      }
    )

    this.setExperiences(experiences)
  }

  filterByCompanyName(companyName, companies) {
    return companies.filter(
      ({ name }) => name.toLowerCase().startsWith(companyName.toLowerCase())
    )
  }

  clearForm(index) {
    const experiences = this.state.experiences.map(
      (experienceObj, experienceIndex) => experienceIndex === index
        ? {
          companyName: "",
          companyId: "",
          cityName: "",
          cityCountryName: "",
          cityId: "",
          jobTitle: "",
          startDate: "",
          endDate: "",
          description: "",
        }
        : experienceObj
    )

    this.setExperiences(experiences)
  }

  onCompanyChanged(index) {
    return (input, [ { value: { id: companyId, name: companyName } } ]) => {
      if (companyId) { // id being the only required
        const newExperiences = this
          .state
          .experiences
          .map(
            (experience, sidx) =>
              index !== sidx
                ? experience
                : {
                  ...experience,
                  companyId,
                  companyName,
                },
          )

        this.setExperiences(newExperiences)
      }
    }
  }

  onCityChanged(index) {
    return (input, [ { id: cityId, name: cityName, country: { name: cityCountryName } } ]) => {
      if (cityId) { // id being the only required
        const newExperiences = this
          .state
          .experiences
          .map(
            (experience, sidx) =>
              index !== sidx
                ? experience
                : {
                  ...experience,
                  cityId,
                  cityName,
                  cityCountryName,
                },
          )

        this.setExperiences(newExperiences)
      }
    }
  }

  resultsMapper({ data }) {
    // eslint-disable-next-line array-bracket-newline
    let items = []

    try {
      items = data.data.map((item) => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
        },
      }))
    } catch (e) {
      console.error(e)
    }

    return items
  }

  render() {
    return (
      <Col>
        <Label className="section-title">Experiences</Label>

        {this.state.experiences.map((exp, idx) => (
          <div key={exp.key} data-index={idx}>
            { idx > 0
              ? <hr/>
              : ""
            }
            <Row className="multiField">
              <Col className="col-sm-6">
                <AutoCompleteMulti
                  resourceName="place"
                  resourceField="name"
                  stateName=""
                  resourceQueryMethod={queryResource}
                  queryFields={queryFields}
                  onSelected={this.onCompanyChanged(idx)}
                  resultsMapper={this.resultsMapper}
                  isMulti={false}
                  defaultInputValue={exp.companyName}
                  defaultValue={this.resultsMapper({
                    data: {
                      data: this.props.value[idx]
                        ? [{
                          id: exp.companyId,
                          name: exp.companyName,
                        }]
                        : [],
                    },
                  })}
                  extraQueryParams={{ status: EPlaceStatus.published }}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Company Name
                </small>
              </Col>
              <Col className="col-sm-5">
                <input
                  name={"jobTitle"}
                  type="text"
                  className="form-control"
                  placeholder="Job Title"
                  onChange={this.handleChange(idx)}
                  defaultValue={exp.jobTitle}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Job Title
                </small>
              </Col>
              <Col>
                <i
                  className="fa fa-trash fa-2x"
                  onClick={
                    this.state.experiences.length > 1
                      ? this.handleRemoveExperience.bind(this, idx)
                      : this.clearForm.bind(this, idx)
                  }
                  style={{ "color": "grey", "cursor": "pointer" }}
                />
              </Col>
            </Row>
            <Row className="multiField">
              <Col className="col-sm-3">
                <input
                  name={"startDate"}
                  type="date"
                  className="form-control"
                  onChange={this.handleChange(idx)}
                  value={exp.startDate}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Start Date
                </small>
              </Col>
              <Col className="col-sm-3">
                <input
                  name={"endDate"}
                  type="date"
                  className="form-control"
                  onChange={this.handleChange(idx)}
                  value={exp.endDate}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  End Date
                </small>
              </Col>
              <Col className="col-sm-5">
                <CitiesDropdown
                  isMulti={false}
                  onChange={this.onCityChanged(idx)}
                  value={exp? [
                    {
                      id: exp.cityId,
                      name: exp.cityName,
                      country: {
                        name: exp.cityCountryName,
                      },
                    },
                  ] : undefined
                  }
                />

                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  City
                </small>
              </Col>
            </Row>
            <Row className="multiField">
              <Col className="col-sm-12">
                <RichTextArea
                  name={"description"}
                  className="form-control"
                  updateParent={this.setExperienceDescription.bind(this, idx)}
                  value={exp.description}
                />
              </Col>
            </Row>
            <br />
          </div>
        ))}

        <br />
        <Button
          className="alert-success"
          onClick={this.handleAddExperience}
        >
          Add Experience
        </Button>
      </Col>
    )
  }
}

Experiences.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setExperiences: PropTypes.func,
  edit: PropTypes.bool,
}

export default Experiences
