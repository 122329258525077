import React, { Component } from "react"
import PropTypes from "prop-types"
import { EProfileAvailable } from "~/common/interfaces"
import { arrifyEnumeration } from "~/helpers"
import { Col, Label, Row, Input } from "reactstrap"

const profileAvailableOptions = arrifyEnumeration(EProfileAvailable)

class Availability extends Component {
  constructor(props) {
    super(props)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnChangeAvailability = this.handleOnChangeAvailability.bind(this)
  }

  handleOnChange(e) {
    const { props: { value } } = this
    const t = e.currentTarget
    const updatedValues = value.available === EProfileAvailable.unavailable && value.date === ""
      ?
      { ...value, [t.name]: t.value, hours: 40 }
      :
      { ...value, [t.name]: t.value }
    this.props.onAvailabilityChange({ ...updatedValues })
  }

  handleOnChangeAvailability(e) {
    const t = e.currentTarget
    const updatedValues = {
      "date": "",
      "hours": t.value === EProfileAvailable.unavailable ? 0 : 40,
      [t.name]: t.value,
    }

    this.props.onAvailabilityChange({ ...this.props.value, ...updatedValues })
  }

  render() {
    const { props: { value } } = this
    const readableOptions = {
      [EProfileAvailable.unspecified]: "Unspecified",
      [EProfileAvailable.available]: "Available",
      [EProfileAvailable.unavailable]: "Unavailable",
    }
    const hoursOptions = [
      5,
      10,
      15,
      20,
      25,
      30,
      35,
      40,
    ]
    const hoursVisability = value.available === EProfileAvailable.unavailable && value.date === ""
      ?
      "hidden"
      :
      "visible"
    return (
      <Col>
        <Label className="section-title">Availability*</Label>
        <Row>
          <Col>
            <select
              name="available"
              className="form-control"
              value={value.available}
              onChange={this.handleOnChangeAvailability}
            >
              {profileAvailableOptions.map((item) => (
                <option key={item} value={item}>{readableOptions[item]}</option>
              ))}
            </select>
            <small
              id="passwordHelpBlock"
              className="form-text text-muted"
            >
              Available?
            </small>
          </Col>
          <Col>
            <Input
              name="date"
              type="date"
              value={value.date}
              onChange={this.handleOnChange}
              disabled={value.available === EProfileAvailable.unspecified}
            />
            <small
              id="passwordHelpBlock"
              className="form-text text-muted"
            >
              Available {value.available === EProfileAvailable.available ? "Until" : "From"}
            </small>
          </Col>
          <Col
            style={{ visibility: hoursVisability }}
          >
            <select
              name="hours"
              className="form-control"
              value={value.hours}
              onChange={this.handleOnChange}
              disabled={value.available === EProfileAvailable.unspecified || (value.available === EProfileAvailable.unavailable && value.date === "")}

            >
              {
                hoursOptions.map(op =>
                  <option key={op} value={op}>{op}</option>
                )
              }
            </select>
            <small
              id="passwordHelpBlock"
              className="form-text text-muted"
            >
              Hours
            </small>
          </Col>
        </Row>
      </Col>
    )
  }
}

Availability.propTypes = {
  onAvailabilityChange: PropTypes.func,
  value: PropTypes.object,
}

export { Availability }
