import React from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

const Page500 = () => {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <span className="clearfix">
              <h1 className="float-left display-3 mr-4">500</h1>
              <h4 className="pt-3">Well this wasn't meant to happen...</h4>
              <p className="text-muted float-left">
                The page you are looking for is temporarily unavailable.
              </p>
            </span>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="button404">
              <Link to="/">
                <Button>
                  Return to Home Page
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Page500
