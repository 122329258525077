import * as React from "react"

import * as functional from "react-functional"

import { NavBar } from "~/components/presenters"

import { flow, replace } from "lodash/fp"
import { Redirect } from "react-router-dom"
import { Container, Row } from "reactstrap"
import { View } from "samx"
import { FloatingTopMenu } from "~/components/presenters/Menu/FloatingTopMenu"
import { PlacesConnectTable } from "~/components/presenters/Table/PlacesConnectTable"
import { ProfilesConnectTable } from "~/components/presenters/Table/ProfilesConnectTable"
import { SkillsConnectTable } from "~/components/presenters/Table/SkillsConnectTable"
import { getPlaces, getProfiles, getSkills } from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"

export interface IHomeSwitchProps {
  currentRoute: string
}

const homeSwitchDidUpdate = (props: IHomeSwitchProps) => {
  switch (props.currentRoute) {
    case "home":
      return getProfiles()

    case "places":
      return getPlaces()

    case "skills":
      return getSkills()

    default:
      return
  }
}

const homeSwitch = (props: IHomeSwitchProps): React.ReactElement<any> | null => {
  switch (props.currentRoute) {
    case "home":
      return (
        <ProfilesConnectTable
          list={connectState.profiles}
        />
      )
    case "places":
      return (
        <PlacesConnectTable
          list={connectState.places}
        />
      )
    // TODO: Removing this case for HomeConnect (we're not going to call skills from here)
    case "skills":
      return (
        <SkillsConnectTable
          list={connectState.skills}
        />
      )
    case "spoken-languages":
      return (
        <Redirect to="/connect/spoken-languages" />
      )
    default:
      return (
        <ProfilesConnectTable
          list={connectState.profiles}
        />
      )
  }
}

const HomeSwitch: React.SFC<IHomeSwitchProps> = flow(
  (c: any) => functional(c, {
    componentDidMount: homeSwitchDidUpdate,
  }),
  View,
)(homeSwitch) as any

const getConnectRoute = replace("/connect/", "")

export interface IHomeConnectProps {
  user: object,
  display?: string,
  location: { pathname: string }
}

export const HomeConnect: React.SFC<IHomeConnectProps> = (props: IHomeConnectProps) => {
  const currentRoute = getConnectRoute(props.location.pathname) || "profiles"
  const menuItems = [
    {
      render: () => (
        <span>
          <i className="fa fa-user" /> Profiles
        </span>
      ),
      route: "./home",
    },
    {
      render: () => (
        <span>
          <i className="fa fa-building" /> Places
        </span>
      ),
      route: "./places",
    },
    {
      render: () => (
        <span>
          <i className="fa fa-gear" /> Skills
        </span>
      ),
      route: "/connect/skills",
    },
    {
      render: () => (
        <span>
          <i className="fa fa-building" /> Spoken Languages
          </span>
      ),
      route: "./spoken-languages",
    },
  ]

  return (
    <div>
      <NavBar />
      <Row id="formBuilderContainer">
        <Container className="col-lg-10 mx-auto">
          <FloatingTopMenu items={menuItems} />
          <HomeSwitch currentRoute={currentRoute} />
        </Container>
      </Row>
    </div>
  )
}
