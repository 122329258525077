import React from "react"
import ReactApexChart from 'react-apexcharts'
import PropTypes from 'prop-types'

const Chart = (props) => {

  const options = {
    labels: props.labels,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  }

  const series = props.series.map(num => Number(num))
  
  return (
    <div id="chart">
      <h3 style={{ marginBottom: "-5px" }}>{props.name}</h3>
      <small>{props.smallText}</small>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="450"
      />
    </div>
  )
}

Chart.propTypes = {
  series: PropTypes.array,
  labels: PropTypes.array,
  name: PropTypes.string,
  smallText: PropTypes.string,
}

export { Chart }
