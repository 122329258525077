const getMargin = (rate: number, margin: number): number =>
    (rate * 100) / (100 - (margin * 100))

const roundup = (rate: number, margin: number): number => {
    if (getMargin(rate, margin) % 5 === 0) {
        return getMargin(rate, margin)
    } else {
        return Math.ceil((getMargin(rate, margin)) / 5 ) * 5 }
}

const actualMargin = (rate: number, roundupValue: number): number => {
    return ((1 - (rate / roundupValue)) * 100)
}

export {
    roundup,
    actualMargin,
}
