import { request } from "~/services/api/resources/request"

import { ISkill } from "~/common/interfaces/skill"
import { SkillOutgoing } from "~/services/api/adapters/SkillOutgoing"

const listSkills = async () => request({
  method: "GET",
  url: "category-list",
})

const addSkill = async (skillData: ISkill) => request({
  method: "POST",
  url: "category",
  data: SkillOutgoing(skillData),
})

const updateSkill = async (skillData: ISkill) => request({
  method: "PATCH",
  url: `category/${skillData.id}`,
  data: SkillOutgoing(skillData),
})

const fetchSkill = async (id: string) => request({
  method: "GET",
  url: `category/${id}`,
})

export {
  addSkill,
  fetchSkill,
  listSkills,
  updateSkill,
}
