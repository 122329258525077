import {
  flow, isEmpty, isEqual, isNumber, omitBy,
} from "lodash/fp"
import * as queryString from "query-string"

const isInvalidParam = (value: any) => {
  if (typeof value === "object") {
    return isEmpty(value)
  }

  return !isNumber(value) && !value
}

const removeInvalidParamKeys = (obj: any) => omitBy(isInvalidParam, obj)

const removeMetaKeysIfIsEmptyParams = (query: any) => {
  const keys = Object.keys(removeInvalidParamKeys(query))
  const targetKeys = ["page", "perPage"]

  // check if only 'page' and 'perPage' keys are present in the query object
  if (isEqual(keys.sort(), targetKeys.sort())) {
    return {}
  }

  return query
}

const buildQueryString = (query: any) => queryString.stringify(query)
const buildProfileQueryString = (query: object) => flow(removeInvalidParamKeys, buildQueryString)(query)

const setParams = (state) => {
  const searchParams = new URLSearchParams()

  for (const key in state) {
    if (!isInvalidParam(state[key])) {
      if (typeof state[key] === "object") {
        state[key].forEach((i) => {
          searchParams.append(key, i)
        })
      } else {
        searchParams.set(key, state[key])
      }
    }
  }
  return searchParams.toString()
}

export {
  buildQueryString,
  buildProfileQueryString,
  removeMetaKeysIfIsEmptyParams,
  setParams,
}
