import { logout } from "~/samx/actions"
import { selectAuthToken } from "~/samx/selectors/auth"
import { IMiddleware } from "./middleware"

const handleUnauthorizedRequests = (err: any) => {
  logout()
}

const onNonAuthorizedResponse = (handler) => (err) => {
  if (err && err.response && err.response.status === 401) {
    handler(err)
  } else {
    throw err
  }
}

const injectAuthToken = (config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: selectAuthToken(),
    },
  }
}

export const AuthMiddleware: IMiddleware = {
  onRequest: injectAuthToken,
  onResponseError: onNonAuthorizedResponse(handleUnauthorizedRequests),
}
