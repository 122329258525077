import * as PropTypes from "prop-types"
import * as React from "react"

import ProfileTableRow from "./ProfilesTableRow"
import TableHeader from "./TableHeader"

import { flow } from "lodash/fp"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap"
import { View } from "samx"
import { Pagination, SearchBar } from "~/components/presenters"
import { buildProfileQueryString } from "~/helpers"
import { typeProps } from "~/helpers/common"
import { saveQueryParams } from "~/samx/actions"
import { listFilteredProfiles } from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"

const profilesConnectTable: React.SFC<{ list: Array<{}> }> = (props: {
  list: Array<{}>,
}): React.ReactElement<{
  list: Array<{}>,
}> => {
  let children
  const makeTableRow = (data: object, index: number) => (
    <ProfileTableRow
      rowData={data}
      key={index}
    />
  )

  const onPageChange = (page: number) => {
    const obj = {
      ...connectState.queryProfile,
      page,
    }

    const query = buildProfileQueryString(obj)
    listFilteredProfiles(query)
    saveQueryParams(obj)
}

  if (props.list && props.list.length > 0) {
    children = (
      <Table hover={true} size="sm">
        <TableHeader
          // tslint:disable-next-line:jsx-no-multiline-js
          names={[
            "Name",
            "Focus",
            "Location",
            "Skills",
            "Pool",
            "Actions",
          ]}
        />
        <tbody>
          {props.list.map(makeTableRow)}
        </tbody>
      </Table>
    )
  } else {
    children = <h3 id="no-item-yet-text">No items yet...</h3>
  }

  return (
    <Card className="col-lg mx-auto" id="formContainer">
    <CardBody>
        <Row>
          <Col>
            <Link to="/connect/profile/new">
              <Button size="md" color="success" className="form__button">
                New Profile <i className="fa fa-plus-square" />
              </Button>
            </Link>
          </Col>
        </Row>
        <SearchBar />
      </CardBody>
      <CardBody>
        {children}
      </CardBody>
      <Pagination
        onPageChange={onPageChange}
        paginationGlobalStateKey="profiles"
      />
    </Card>
  )
}

const propTypes = {
  list: PropTypes.array,
}

export const ProfilesConnectTable: React.SFC<{ list: Array<{}> }> = flow(
  typeProps(propTypes),
  View,
)(profilesConnectTable) as any
