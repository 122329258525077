import * as React from "react"

import {
  flow,
  get,
  isEmpty,
  isUndefined,
  negate,
} from "lodash/fp"
import {
  computed,
  decorate,
  View,
  when,
} from "samx"
import {
  EPlaceStatus,
  IPlace,
} from "~/common/interfaces"

import { placeformValidation } from "~/common/validation-schema"
import {
  ICorePlaceFormDataState,
  IPlaceFormProps,
  PlaceForm,
  Spinner,
} from "~/components/presenters"
import { getPlace, updatePlace } from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"

export interface IEditFormPlaceLocalState {
  isSubmitEnabled: boolean
  isLoaded: boolean
  place: any
}

export interface IEditFormPlaceProps {
  placeId: string
}

class EditPlaceFormClass extends React.Component<
  IEditFormPlaceProps,
  IEditFormPlaceLocalState
> {
  public state: IEditFormPlaceLocalState = {
    isSubmitEnabled: true,
    isLoaded: false,
    place: {
      name: "",
    },
  }

  public async componentDidMount() {
    await getPlace(this.props.placeId)
    this.copyReactivePlaceWhenReady()
    this.setState({
      isLoaded: true,
    })
  }

  public async saveData(placeData: ICorePlaceFormDataState) {
    await updatePlace({
      ...placeData,
      id: this.props.placeId,
    } as IPlace)
  }

  get isPlaceReady() {
    return flow(
      get(this.props.placeId),
      negate(isUndefined),
    )(connectState.placesObj)
  }

  get place() {
    return get(this.props.placeId)(connectState.placesObj)
  }

  public copyReactivePlaceWhenReady() {
    when(
      () => this.isPlaceReady,
      () => {
        this.setState({
          ...this.state,
          place: this.place,
        })
      },
    )
  }

  public prepareChildProps() {
    const saveData = this.saveData.bind(this)
    const placeObj: IPlace = get(this.props.placeId)(
      connectState.placesObj,
    )

    const place = isEmpty(placeObj) ? {
      type: undefined,
      status: EPlaceStatus.underReview,
      name: "",
      bio: "",
      logo: null,
      city: {},
    } : placeObj

    return {
      ...place,
      validationSchema: placeformValidation,
      saveData,
    } as IPlaceFormProps
  }

  public render() {
    if (!this.isPlaceReady || !this.state.isLoaded) {
      return <Spinner />
    } else {
      return <PlaceForm {...this.prepareChildProps()} />
    }
  }
}

export const EditPlaceForm = flow(
  (component: any) => decorate(component, {
    place: computed,
    isPlaceReady: computed,
  }),
  View,
)(EditPlaceFormClass) as any
