import * as React from "react"

import { Card, CardBody } from "reactstrap"
import { NewSkillForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const NewSkillFormScreen: React.SFC<{}> = (
  props,
): React.ReactElement<any> => {
  const renderNewSkillForm = () => (
    <Card>
      <CardBody>
        <h2>New Skill</h2>

        <NewSkillForm />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen {...props} renderListComponent={renderNewSkillForm} />
  )
}
