import axios from "axios"
import { authState } from "~/samx/states/auth"
import { IMiddleware } from "./middleware"

const headers = {
  "Content-Type": "application/json; charset=utf-8",
}

const transformResponse = (data, hs) => {
  if (data.error) {
    throw data
  }
  return data
}

const injectCorrectHeaders = (config: any) => {
  config.validateStatus = (status) => ![500, 404, 401].includes(status)
  config.transformResponse = (axios.defaults.transformResponse as Array<(data, headers) => any>).concat(
    transformResponse,
  )

  return {
    ...config,
    headers: {
      ...config.headers,
      ...headers,
    },
  }
}

export const CommonMiddleware: IMiddleware = {
  onRequest: injectCorrectHeaders,
}
