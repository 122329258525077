import { isEmpty, isEqual } from "lodash/fp"
import * as React from "react"
import { Button, Col, Label, Row } from "reactstrap"

import { AutoCompleteMulti } from "~/components/presenters"
import { addKeys } from "~/helpers"
import { queryFields, queryResource } from "~/services/api"

interface ISkillsProps {
  defaultSkills?: Array<any>,
  setSkills: (collection: Array<any>) => any,
}
interface ISkillsState {
  skills: Array<any>,
}

class TestedSkill extends React.Component<ISkillsProps, ISkillsState> {
  public static getDerivedStateFromProps(nextProps, prevState) {
    const nextIds = nextProps.defaultSkills.map((i) => i.id)
    const prevIds = prevState.skills.map((i) => i.id)
    if (nextIds !== prevIds) {
      return {
        skills: nextProps.defaultSkills,
      }
    }
    return null
  }

  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()

    this.handleAddSkill = this.handleAddSkill.bind(this)
    this.onSkillSelected = this.onSkillSelected.bind(this)
  }

  public onSkillSelected(index) {
    return (input, data) => {
      try {
        const { value } = data[0]
        const newSkills = this.state.skills.map((skill, sidx) => {
          if (index !== sidx) { return skill }
          return { ...value, skillId: value.id }
        })

        this.setSkills(newSkills)
      } catch (e) {
        console.error(e)
      }
    }
  }

  public resultsMapper = ({ data }) => {
    const existentSkills = this.state.skills.map((skill) => skill.name)
    let items = []

    try {
      items = data.data.map((item) => ({
        label: item.name,
        isDisabled: existentSkills.includes(item.name),
        value: {
          id: item.id,
          name: item.name,
          skillName: item.name,
        },
      }))
    } catch (e) {
      console.error(e)
    }
    return items
  }

  public handleAddSkill() {
    const skills = [
      ...this.state.skills,
      { name: "", skillName: "", id: "", skillId: "" },
    ]

    this.setSkills(skills)
  }

  public handleRemoveSkill(idx) {
    const skills = this.state.skills.filter((s, sidx) => idx !== sidx)

    this.setSkills(skills)
  }

  public deleteEmpty(skill) {
    const keys = [
      "startedAt",
      "skillId",
      "skillName",
    ]

    const isKeyEmpty = keys.reduce(
      (areKeysEmpty, key) => key in skill
        ? areKeysEmpty && skill[key] === "" || skill[key] === undefined
        : areKeysEmpty,
      true,
    )

    return !isKeyEmpty ? skill : {}
  }

  public setSkills(skills) {
    const skillsWithKeys = addKeys(skills)

    this.setState({
      skills: skillsWithKeys,
    }, () => {
      if (this.props.setSkills) {
        const processedSkills = skillsWithKeys
          .map(this.deleteEmpty)

        this.props.setSkills(processedSkills)
      }
    })
  }

  public clearForm(index) {
    const skills = this.state.skills.map(
      (skillObj, skillIndex) => skillIndex === index
        ? {
          startedAt: "",
          skillName: "",
          skillId: "",
        }
        : skillObj,
    )

    this.setSkills(skills)
  }

  public render() {
    return (
      <Col>
        <Label className="section-title">Tested Skill</Label>
        {this.state.skills.map((skill, idx) => (
          <Row className="multiField" key={skill.key}>
            <Col className="col-sm-7">
              <AutoCompleteMulti
                resourceName="category"
                resourceField="name"
                stateName=""
                resourceQueryMethod={queryResource}
                queryFields={queryFields}
                onSelected={this.onSkillSelected(idx)}
                resultsMapper={this.resultsMapper}
                isMulti={false}
                defaultInputValue={skill.skillName}
                defaultValue={this.resultsMapper({
                  data: {
                    data: [
                      {
                        id: skill.skillId,
                        name: skill.skillName || skill.name,
                        skillName: skill.skillName || skill.name,
                      },
                    ],
                  },
                })}
              />
              <small
                id="passwordHelpBlock"
                className="form-text text-muted"
              >
                Tested Skill Name
              </small>
            </Col>
          </Row>
        ))}
      </Col>
    )
  }

  private retrieveStateFromProps() {
    return !isEmpty(this.props.defaultSkills) ? {
      skills: addKeys(this.props.defaultSkills as any),
    } : {
      skills: [
        {},
      ],
    }
  }
}

export { TestedSkill }
