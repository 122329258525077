import { State } from "samx"
import { objectifyNestedSet } from "~/helpers/nestedSet"
import { objectifyArray } from "~/helpers/operations"

const objectifyById = objectifyArray("id")
const objectifyByReferenceId = objectifyArray("referenceId")
const objectifyByNestedId = objectifyNestedSet("category.id")("children")

const aggregator = (models: any) => () => ({
  places: models.collections.places,
  profiles: models.collections.profiles,
  skills: models.collections.skills,
  spokenLanguages: models.collections.spokenLanguages,
  placesObj: objectifyById(models.collections.places),
  profilesObj: objectifyById(models.collections.profiles),
  skillsObj: objectifyByNestedId(models.collections.skills),
  invitationsObj: objectifyByReferenceId(models.collections.invitations),
  queryProfile: models.queries.queryProfile.params,
  pagination: {
    profiles: {
      page: models.queries.queryProfile.params.page,
      perPage: models.queries.queryProfile.params.perPage,
      total: models.queries.queryProfile.meta.total,
    },
  },
  notifications: models.collections.notifications,
})

export const connectState = State(aggregator)
