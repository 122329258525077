import { request } from "~/services/api/resources/request"

const fetchUser = async (id: string) => request({
  method: "GET",
  url: `users/${id}`,
})

const deleteUser = async (id: string) => request({
  method: "DELETE",
  url: `users/${id}`,
})

const fetchInternalUsers = async (query, role = "internal") => request({
  method: "GET",
  url: `users?role=${role}&${query}`,
})

const userHasRole = async (checkRoles: Array<string>) => {
  const authUserInfo = await fetchUser("me")
  const roles: Array<string> = authUserInfo.data.roles
  return checkRoles.some( (role) => roles.includes(role))
}

export {
  fetchUser,
  userHasRole,
  fetchInternalUsers,
  deleteUser,
}
