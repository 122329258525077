import { IPlace } from "~/common/interfaces"

const PlaceIncoming = (place): IPlace => ({
  ...place,
  id: place.id || undefined,
  logo: (place.logo && {
    id: place.logo.id,
    name: place.logo.id,
    bucket: place.logo.bucket,
  }),
  status: place.status,
})

export {
  PlaceIncoming,
}
