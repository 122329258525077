import * as React from "react"
import ReactJSPagination from "react-js-pagination"
import { connectState } from "~/samx/states/connect"

interface IPaginationProps {
  onPageChange: (page: number) => void,
  paginationGlobalStateKey: string,
}

interface IPaginationState {
  activePage: number,
}

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  constructor(props) {
    super(props)

    this.onPageChange = this.onPageChange.bind(this)
  }

  public render() {
    const pagination = connectState.pagination[this.props.paginationGlobalStateKey]

    return (
      <ReactJSPagination
        activePage={pagination.page}
        itemsCountPerPage={pagination.perPage}
        totalItemsCount={pagination.total}
        itemClass="page-item"
        linkClass="page-link"
        onChange={this.onPageChange}
      />
    )
  }

  private onPageChange(page: number) {
    this.setState({
      activePage: page,
    })
    this.props.onPageChange(page)
  }
}

export {
  Pagination,
}
