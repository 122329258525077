import * as React from "react"
import { Col, Input, Label } from "reactstrap"
import { InputType } from "reactstrap/lib/Input"

interface ITextInputProps {
  name: string,
  value: any,
  placeHolderText: string,
  labelText: string,
  required: boolean,
  type: InputType,
  maxLength?: number,
  handleChange?: React.ReactEventHandler<HTMLInputElement>,
  disabled?: boolean,
  enableWordCount?: boolean,
}

const TextInput = ({
  name,
  value,
  placeHolderText,
  labelText,
  required,
  type,
  maxLength,
  handleChange,
  disabled = false,
  enableWordCount = false,
}: ITextInputProps) => {
  return (
    <Col>
      <Label className="section-title">{labelText}</Label>
      <Input
        className="form-control"
        type={type || "text"}
        name={name}
        placeholder={placeHolderText}
        onChange={handleChange}
        required={required}
        value={value}
        maxLength={maxLength}
        disabled={disabled || false}
      />
      {
        enableWordCount && <small
          id="passwordHelpBlock"
          className="form-text text-muted"
        >
          {value.length}/{maxLength}
        </small>
      }
    </Col>
  )
}

export { TextInput }
