export enum InvitationStatus {
  accepted = "ACCEPTED",
  invited = "INVITED",
  created = "CREATED",
  undefined = "UNINVITED",
  active = "ACTIVE",
  expired = "EXPIRED",
}

export interface IInvitation {
  id?: string,
  referenceId?: string,
  referenceType?: string
  userId?: string,
  token?: string,
  status: InvitationStatus,
  expiresAt?: string,
  createdAt?: string,
  updatedAt?: string,
  email?: string,
  firstName?: string,
  lastName?: string,
}
