import { action, toJS } from "samx"
import { authState } from "~/samx/states/auth"

export const selectAuthToken = action(() => authState.accessToken)
export const selectAuthStatus = action(() => authState.isLoggedIn)
export const selectAuthUser = action(
  () => {
    const {
      fullName,
      givenName,
      familyName,
      email,
      imageUrl,
      userId,
    } = toJS(authState)

    return {
      fullName,
      givenName,
      familyName,
      email,
      imageUrl,
      userId,
    }
  },
)
