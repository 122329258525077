import * as Joi from "joi"

import { filter, flow } from "lodash/fp"
import normalizeUrl from "normalize-url"
import {
  EProfileBadges,
  EProfileGenders,
  EProfilePools,
} from "~/common/interfaces"
import { SKILLS_LIMIT } from "~/config/constants"
import { HUBSPOT_DEAL_URL } from "~/config/env"
import { arrifyEnumeration } from "~/helpers/common"
import {
  communicationformValidation,
  educationformValidation,
  experienceformValidation,
  portfolioformValidation,
  skillformValidation,
  spokenLanguageformValidation,
} from "./"
import { customStringValidator, NullID } from "./common"

const removeValue = (value) => filter((v) => v !== value)

const ProfileGenders = flow(arrifyEnumeration, removeValue("-"))(EProfileGenders)
const ProfilePools = flow(arrifyEnumeration, removeValue("-"))(EProfilePools)
const ProfileBadges = arrifyEnumeration(EProfileBadges)

export const profileformValidation = Joi.object().keys({
  id: Joi.string().allow("").optional(),
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  gender: Joi.string().valid(ProfileGenders),
  dob: Joi.string().allow("").optional(),
  pool: Joi.string().valid(ProfilePools),
  bio: Joi.string().required(),
  focusRole: Joi.object().keys({
    id: Joi.string().required(),
    name: Joi.string().required(),
    category: Joi.string().required(),
    mapped: Joi.array().items(Joi.string(), ""),
  }),
  headline: Joi.string().required(),
  note: Joi.string().optional().allow(""),
  location: Joi.object({
    id: Joi.string().required(),
    name: Joi.string().required(),
  }),
  owner: Joi.object().keys({
    id: Joi.string().required(),
  }),
  hourlyRate: Joi.number().required().not(0),
  vat: Joi.string().required(),
  locationVat: Joi.object().keys({
    id: Joi.string().invalid(NullID).required(),
    name: Joi.string().allow("").optional(),
    iso2code: Joi.string().allow("").optional(),
  }),
  travelPreferences: Joi.string().valid("UNLIMITED", "LIMITED", "CURRENT_CITY", "REMOTE_ONLY"),
  availability: Joi.object(),
  isLongTermProject: Joi.string().valid("Yes", "No"),
  experiences: Joi.array().items(experienceformValidation, {}),
  educations: Joi.array().items(educationformValidation, {}),
  portfolios: Joi.array().items(portfolioformValidation, {}),
  spokenLanguages: Joi.array().items(spokenLanguageformValidation, {}),
  skills: Joi.array().items(skillformValidation, {}).max(SKILLS_LIMIT),
  communications: Joi.array().items(communicationformValidation, {}),
  badges: Joi.array().items(
    Joi.string().valid(...ProfileBadges),
    [],
  ),
  referralCode: Joi.string().allow("").optional(),
  photo: Joi.object({
    name: Joi.string(),
    bucket: Joi.string(),
    mimeType: Joi.string(),
  }).allow(null).optional(),
  hubspotDealUrl: HUBSPOT_DEAL_URL &&
    customStringValidator.string().startsWith(`${normalizeUrl(HUBSPOT_DEAL_URL)}/`).allow("").optional() || "",
})
