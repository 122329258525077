import React, { useState, useEffect } from "react"
import { NavBar } from "~/components/presenters"
import { Container, Row } from 'reactstrap'
import { fetchInternalUsers, deleteUser } from "~/services/api"
import { get } from "lodash/fp"
import { HttpStatusCode } from '~/common/interfaces/http-status-code'
import { ConfirmationModal } from './ConfirmationModal'
import { UsersTable } from './UsersTable'
import { createNotification } from "~/samx/actions"
import { ENotificationTypes } from "~/common/interfaces"
import { buildProfileQueryString } from "~/helpers"
import { PAGINATION_RESULTS_PER_PAGE } from "~/config/constants"

export const Admin = () => {
  const [modal,
    setModal] = useState(false)
  const [users,
    setUsers] = useState([])
  const [selectedUser,
    setSelectedUser] = useState('')
  const [queryStr,
    setQueryStr] = useState({
    page: 1,
    perPage: PAGINATION_RESULTS_PER_PAGE,
  })
  const [pagination,
    setPagination] = useState({
    activePage: 1,
    itemsCountPerPage: PAGINATION_RESULTS_PER_PAGE,
    totalItemsCount: 0,
  })

  async function fetchData(pageNumber = 1) {
    const query = buildProfileQueryString({
      ...queryStr,
      page: pageNumber,
    })
    try { 
      const response = await fetchInternalUsers(query)
      const usersData = get("data.data", response, [])
      const { page, perPage, total } = get("data.pagination", response, [])
      setQueryStr({ page, perPage })
      setUsers(usersData)
      setPagination({
        activePage: parseInt(page, 10),
        itemsCountPerPage: parseInt(perPage, 10),
        totalItemsCount: parseInt(total, 10),
      })  
    } catch(e){
      console.error(e)
    }
  }

  const toggleModal = () => setModal(modal => !modal)

  useEffect(() => {
    fetchData()
  }, [])

  const handleDeleteUser = e => {
    const userId = e.target.dataset.userid
    setSelectedUser(userId)
    toggleModal()
  }

  const handlePagination = (pageNumber) => {
    fetchData(pageNumber)
  }

  const handleDeleteUserConfirmation = async () => {
    const response = await deleteUser(selectedUser)
    if (response.status === HttpStatusCode.OK) {
      setSelectedUser('')
      toggleModal()
      createNotification({
        header: "User Deleted",
        message: "User successfully deleted!  🎉",
        notificationType: ENotificationTypes.SUCCESS,
        timeout: 4,
      })
      fetchData()
    }
  }
  return (
    <div>
      <NavBar />
      <Row>
        {pagination.totalItemsCount > 0 &&
          <Container className="col-lg-10 mx-auto mt-5">
            <UsersTable
              users={users}
              handleDeleteUser={handleDeleteUser}
              pagination={pagination}
              handlePagination={handlePagination}
            />
          </Container>
        }
        <ConfirmationModal
          toggle={toggleModal}
          modal={modal}
          handleDeleteUserConfirmation={handleDeleteUserConfirmation}
        />
      </Row>
    </div>
  )
}
