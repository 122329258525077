import { State } from "samx"

const aggregator = (models: any) => () => ({
  firstName: models.user.fistName,
  lastName: models.user.lastName,
  fullName: `${models.user.firstName} ${models.user.lastName}`,
  email: models.user.email,
  imageUrl: models.user.imageUrl,
  id: models.user.id,
  accessToken: models.user.accessToken,
  isLoggedIn: models.user.accessToken !== "",
  organizationId: models.user.organizationId,
})

export const authState = State(aggregator)
