import { isEmpty } from "lodash/fp"
import * as React from "react"
import { ASC, DESC, DISABLED_SORT_OPTIONS } from "~/config/constants"

interface IProps {
  names: Array<any>,
  handleSorting?: (object: object) => any,
  currentSortValue?: string | null,
  sortOrder?: string,
}

const TableHeader = ({ names, currentSortValue, sortOrder, handleSorting }: IProps) => {
  const tableHeaderNames = names.filter(Boolean).map((name, index) => {
    const sortType = name && (typeof name === "object" ? name.key : name).toUpperCase()
    const isSortEnabled = !DISABLED_SORT_OPTIONS.includes(sortType)
    return (
      <th
        className={`dev-row ${isSortEnabled && "dev-row__sort"}
        ${currentSortValue === `SORT_BY_${sortType}` && sortOrder}`}
        {...(isSortEnabled && { onClick: handleSorting })}
        key={index}
      >
        <span>{name}</span>
      </th>
    )

  })

  return (
    <thead>
      <tr>
        {tableHeaderNames}
      </tr>
    </thead>
  )

}

export default TableHeader
