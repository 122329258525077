import * as React from "react"

import { isEmpty } from "lodash/fp"
import { Button, Col, FormGroup, Label, Row } from "reactstrap"
import { EProfileBadges } from "~/common/interfaces"

interface IProps {
  value?: Array<string>,
  setBadges: any,
}

interface IState {
  badges: Array<string>,
}

class Badges extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    const badgesInitialValue = !isEmpty(props.value) ? props.value : [

    ]

    this.state = {
      badges: badgesInitialValue,
    }
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value) {
      this.setState({ badges: this.props.value })
    }
  }

  public addBadge(badgeName: string) {
    const updatedBadges = [
      ...this.state.badges,
      badgeName,
    ]

    this.setState({ badges: updatedBadges }, () => {
      this.props.setBadges(this.state.badges)
    })
  }

  public removeBadge(badgeName: string) {
    const updatedBadges = this.state.badges.filter((i) => i !== badgeName)
    this.setState({ badges: updatedBadges }, () => {
      this.props.setBadges(this.state.badges)
    })
  }

  public handleCheckboxClick(e) {
    const clickedBadgeName = e.target.name
    const badges = this.state.badges

    if (badges.includes(clickedBadgeName)) {
      this.removeBadge(clickedBadgeName)
    } else {
      this.addBadge(clickedBadgeName)
    }
  }

  public render() {
    const badges = this.state.badges

    const codingTest = badges.includes(EProfileBadges.codingTest) ? "success" : "secondary"
    const liveCoding = badges.includes(EProfileBadges.liveCoding) ? "success" : "secondary"
    const personalityScreen = badges.includes(EProfileBadges.personalityScreen) ? "success" : "secondary"

    const handleCheckboxClick = this.handleCheckboxClick.bind(this)

    return (
      <Col lg={8}>
        <Label className="section-title">Badges</Label>
        <FormGroup>
          <Row>
            <Col lg={{ size: "auto", offset: 0 }}>
              <Button
                color={codingTest}
                name="CODING_TEST"
                onClick={handleCheckboxClick}
              >
                Coding Test
              </Button>
            </Col>
            <Col lg={{ size: "auto", offset: 1 }}>
              <Button
                color={liveCoding}
                name="LIVE_CODING"
                onClick={handleCheckboxClick}
              >
                Live Coding
              </Button>
            </Col>
            <Col lg={{ size: "auto", offset: 1 }}>
              <Button
                color={personalityScreen}
                name="PERSONALITY_SCREENING"
                onClick={handleCheckboxClick}
              >
                Personality Screening
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Col>
    )
  }
}

export { Badges }
