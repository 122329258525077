import { IProposition } from "~/common/interfaces"

const AddQuery = (query: any, key: string): IProposition => ({
    name: "queries.add-query",
    key: `queryProfile.${key}`,
    value: {
      ...query,
    },
  })

export {
  AddQuery,
}
