import { EType } from "~/components/presenters/FilterBar/FilterSideBar"
import {isObject, map} from 'lodash'

const SupplyBoosterProfileIncoming = (profile) => {
  profile.skills = (isObject(profile.skills) && map(profile.skills, x => x.language)) || profile.skills
  const convertedProfile = {
    ...profile,
    type: profile.type || EType.filterUnknown,
  }

  return convertedProfile
}

export {
  SupplyBoosterProfileIncoming,
}
