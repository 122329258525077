import { flow, get, isUndefined, negate } from "lodash/fp"
import * as React from "react"
import { Redirect, withRouter } from "react-router-dom"
import { computed, decorate, toJS, View, when } from "samx"
import {
  ECommunicationTypes,
  ENotificationTypes,
  ICommunication,
  InvitationStatus,
  IStatusFormProfile,
  IUser,
} from "~/common/interfaces"
import { UserRoles } from "~/common/interfaces/UserRoles"
import { IStatusFormProps, Spinner, StatusForm as StatusFormPresenter } from "~/components/presenters"
import { userBelongsToPrimaryOrganization } from "~/helpers"
import { createNotification, getLatestInvitation, getProfile, getUser, redirectTo, sendInvitation } from "~/samx/actions"
import { connectState } from "~/samx/states/connect"
import { deleteProfile, userHasRole } from "~/services/api"

export interface IStatusFormContainerState {
  isSendDisabled: boolean,
  isLoaded: boolean,
  isDeleteProfileEnabled: boolean,
  profile: IStatusFormProfile,
  user: IUser,
}

export interface IStatusFormContainerProps {
  profileId: string
}

const stateProfilesKey = "profilesObj"
const stateInvitationsKey = "invitationsObj"

export class StatusFormClass extends React.Component<IStatusFormContainerProps,
  IStatusFormContainerState> {

  get doesProfileExist() {
    return this.props.profileId in connectState[stateProfilesKey]
  }

  get isProfileReady() {
    return this.isEntityReady(stateProfilesKey) && this.isEntityReady(stateInvitationsKey)
  }

  get profile() {
    return toJS(get(this.props.profileId)(connectState[stateProfilesKey]))
  }

  get invitation() {
    return toJS(get(this.props.profileId)(connectState[stateInvitationsKey]))
  }

  public state: IStatusFormContainerState = {
    isDeleteProfileEnabled: false,
    isLoaded: false,
    isSendDisabled: true,
    profile: {
      profileId: this.props.profileId,
      userId: "",
      firstName: "",
      lastName: "",
      emails: [],
      selectedEmail: "",
      ownerName: "",
      invitation: {
        status: InvitationStatus.undefined,
      },
      createdAt: "",
    },
    user: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      createdAt: "",
      source: "",
      role: "",
      lastActiveTime: "",
      updatedAt: "",
    },
  }

  constructor(props) {
    super(props)
  }

  public onInputEmailChange(value: string): void {
    this.setState({
      profile: {
        ...this.state.profile,
        selectedEmail: value,
      },
    })
  }

  public copyReactiveProfileWhenReady() {
    when(
      () => this.isEntityReady("profilesObj"),
      async () => {
        const prof = this.profile
        const emails = this.extractCommunicationEmails(prof.communications)

        await this.setState({
          ...this.state,
          profile: {
            ...this.state.profile,
            firstName: prof.firstName,
            lastName: prof.lastName,
            ownerName: `${prof.owner.firstName} ${prof.owner.lastName}`,
            emails,
            selectedEmail: emails[0] || "",
            userId: prof.userId,
            createdAt: prof.createdAt,
          },
        })

        const user = await getUser(prof.userId)
        this.setState({
          ...this.state,
          user,
        })
      },
    )

    when(
      () => userBelongsToPrimaryOrganization() && this.isEntityReady("invitationsObj"),
      () => {
        const invitation = this.invitation
        const isSendDisabled = ![InvitationStatus.expired, InvitationStatus.undefined].includes(invitation.status)

        this.setState({
          ...this.state,
          isSendDisabled,
          profile: {
            ...this.state.profile,
            invitation,
          },
        })
      },
    )
  }

  public async componentDidMount() {
    let requests = [getProfile(this.props.profileId)]

    if (userBelongsToPrimaryOrganization()) {
      requests = [
        ...requests,
        getLatestInvitation(this.props.profileId),
      ]
    }

    await Promise.all(requests)

    this.copyReactiveProfileWhenReady()
    this.setState({
      isLoaded: true,
    })

    const userIsSuperAdmin = await userHasRole([UserRoles.SuperAdmin, UserRoles.OrgAdmin])
    this.setState({
      isDeleteProfileEnabled: userIsSuperAdmin,
    })
  }

  public async onSend() {
    this.setState({
      isSendDisabled: false,
    })

    await sendInvitation(this.state.profile)

    this.setState({
      isSendDisabled: true,
    })
  }

  public async onDeleteProfile(id: string) {
    try {
      await deleteProfile(id)
      redirectTo("/connect/home")
    } catch (err) {
      createNotification({
        header: "Could not delete the profile",
        message: "",
        notificationType: ENotificationTypes.ERROR,
        timeout: 4,
      })
    }
  }

  public prepareProps() {
    const onSend = this.onSend.bind(this)
    const onInputEmailChange = this.onInputEmailChange.bind(this)
    const onDeleteProfile = this.onDeleteProfile.bind(this)

    return {
      profile: {
        ...this.state.profile,
        invitation: this.invitation,
      },
      user: this.state.user,
      onInputEmailChange,
      onSend,
      isSendDisabled: this.state.isSendDisabled,
      onDeleteProfile,
      isDeleteProfileEnabled: this.state.isDeleteProfileEnabled,
      shouldRenderInvitationFields: userBelongsToPrimaryOrganization(),
    } as IStatusFormProps
  }

  public render() {
    if (!this.state.isLoaded) {
      return <Spinner/>
    }

    if (this.state.isLoaded && !this.doesProfileExist) {
      return <Redirect to="/404"/>
    }

    return <StatusFormPresenter {...this.prepareProps()} />
  }

  private isEntityReady(stateKey) {
    return flow(
      get(this.props.profileId),
      negate(isUndefined),
    )(connectState[stateKey])
  }

  private extractCommunicationEmails(communications: Array<ICommunication>) {
    return communications
      .filter((communication: ICommunication) => communication.type === ECommunicationTypes.email)
      .map((communication: ICommunication) => communication.value)
  }

  // TODO: implement logic of enabling/disabling send button based on invitation status
  // private isSendEnabled() {
  // tslint:disable-next-line:max-line-length
  //   return [InvitationStatus.undefined, InvitationStatus.created].includes(this.state.profile.invitation.status as any)
  // }
}

export const StatusForm = flow(
  withRouter,
  (component: any) => decorate(component, {
    profile: computed,
    isEntityReady: computed,
  }),
  View,
)(StatusFormClass as any) as any
