import * as React from "react"

import { View } from "samx"

const Reactive = View(
  (props: any) => (
    <React.Fragment>
      {...props.children}
    </React.Fragment>
  ),
)

export {
  Reactive,
}
