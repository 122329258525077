import { get, omit, sortBy } from "lodash/fp"
import normalizeUrl from "normalize-url"
import { HUBSPOT_DEAL_URL } from "~/config/env"
import { mapPropIfExists } from "~/helpers"

function getDate(date) {
  if (date) {
    const splitDate = date.split("-")
    const day = splitDate[2].split("T")

    return `${splitDate[0]}-${splitDate[1]}-${day[0]}`
  }

  return ""
}

const buildFullHubspotDealUrl = (dealId: number): string => {
  return normalizeUrl(`${HUBSPOT_DEAL_URL}/${dealId}`)
}

const ProfileIncoming = (profile) => {
  const longTerm = profile.isLongTermProject === true ? "Yes" : "No"

  const mapProfilePropIfExists = mapPropIfExists(profile)

  const experiences = mapProfilePropIfExists("experiences", [{}])(
    (exp) => ({
      id: exp.id,
      companyName: exp.company.name,
      companyId: exp.company.id,
      jobTitle: exp.jobTitle,
      startDate: getDate(exp.startDate),
      endDate: getDate(exp.endDate),
      description: exp.description,
      cityId: get("location.id")(exp),
      cityName: get("location.name")(exp),
      cityCountryName: get("location.country.name")(exp),
    }),
  )

  const educations = mapProfilePropIfExists("educations", [{}])(
    (education) => ({
      id: education.id,
      schoolName: education.school.name,
      schoolId: education.school.id,
      degree: education.degree,
      startDate: getDate(education.startDate),
      endDate: getDate(education.endDate),
      description: education.description,
      cityId: get("location.id")(education),
      cityName: get("location.name")(education),
      cityCountryName: get("location.country.name")(education),
    }),
  )

  const portfolios = mapProfilePropIfExists("portfolios", [{}])(
    (portfolio) => (
      {
        id: portfolio.id,
        title: portfolio.title,
        description: portfolio.description,
        href: portfolio.href,
        file: portfolio.file,
      }
    ),
  )

  const spokenLanguages = mapProfilePropIfExists("spokenLanguages", [{}])(
    (language) => ({
      id: language.id,
      languageId: language.language.id,
      languageName: language.language.name,
      proficiency: language.proficiency,
    }
    ),
  )

  const skills = (skillsArray) => {
    const restructuredSkills = (rawSkills) => rawSkills.map((skill, index) => ({
      id: skill.id,
      skillName: skill.skill.name,
      skillId: skill.skill.id,
      startedAt: getDate(skill.startedAt),
      order: skill.order ? skill.order : (index + 1),
    }))

    const assignSkillOrder = (unorderedSkills) => {
      const skillsWithDate = unorderedSkills.filter((s) => {
        return s.startedAt
      })

      const skillsWithDateSorted = skillsWithDate.sort((a, b) => {
        return (new Date(a.startedAt) as any) - (new Date(b.startedAt) as any)
      })

      const skillsWithoutDate = unorderedSkills.filter((s) => {
        return !s.startedAt
      })

      const result = [...skillsWithDateSorted, ...skillsWithoutDate]
      return restructuredSkills(result)
    }

    return !!skillsArray.filter((s) => s.hasOwnProperty("order")).length
      ?
      restructuredSkills(skillsArray)
      :
      assignSkillOrder(skillsArray).sort((a, b) => a.order < b.order)
  }

  const communications = mapProfilePropIfExists("communications", [{}])(
    (communication) => ({
      id: communication.id,
      value: communication.value,
      type: communication.type,
    }
    ),
  )

  const availability = profile.availability ?
    {
      ...profile.availability,
      date: getDate(profile.availability.date),
    }
    :
    {
      available: "AVAILABILITY_UNSPECIFIED",
      date: "",
      hours: 40,
    }

  const note = profile.note ? profile.note : ""

  const profileLocation = omit(["coordinates"])(profile.location)

  const convertedProfile = {
    ...profile,
    note,
    availability,
    dob: getDate(profile.dob),
    location: profileLocation,
    locationVat: profile.locationVat ? {
      id: profile.locationVat.id,
      name: profile.locationVat.name,
    } : {
        id: "",
        name: "",
      },
    isLongTermProject: longTerm,
    experiences,
    educations,
    portfolios,
    spokenLanguages,
    focusRole: profile.focusRole,
    skills: profile.skills ? skills(profile.skills) : [{}],
    owner: profile.owner,
    communications,
    hubspotDealUrl: !!profile.hubspotDealId && buildFullHubspotDealUrl(profile.hubspotDealId) || "",
  }

  return omit(["hubspotDealId"])(convertedProfile)
}

export {
  ProfileIncoming,
}
