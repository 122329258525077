import * as React from "react"

import { Redirect } from "react-router-dom"
import { View } from "samx"
import { Unauthorized } from "~/components/presenters"
import { LoginScreen, LogoutScreen } from "~/components/screens"
import { authState } from "~/samx/states/auth"

export interface IAuthenticationManagerProps {
  fallbackRoute: string,
  match: { params: { action: string } },
  location: { pathname: string, from: string },
  history: any,
}

const AuthManager = (props: IAuthenticationManagerProps) => {
  const { fallbackRoute = "/", match: { params: { action } } } = props
  const { isLoggedIn } = authState

  switch (action) {
    case "login":
      return (
        <div>
          {
            isLoggedIn
              ? <Redirect push={true} to={fallbackRoute} />
              : <LoginScreen />
          }
        </div>
      )
    case "logout":
      return (
        <div>
          {
            isLoggedIn
              ? <LogoutScreen />
              : <Redirect push={true} to={"/auth/login"} />
          }
        </div>
      )

    case "unauthorized":
      return (
        <div>
          {
            isLoggedIn
              ? <Redirect push={true} to={fallbackRoute} />
              : <Unauthorized />
          }
        </div>
      )

    default:
      return <Redirect to="/404" />
  }
}

const AuthenticationManager = View(AuthManager)

export {
  AuthenticationManager,
}
