import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatUnixDateTime } from '~/helpers/date-formatter'

class NotesTableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmVisable: false,
    }
  }

  render() {
    const style = {
      red: {
        color: "white",
        backgroundColor: "red",
        borderRadius: "3px",
        maxWidth: "80px",
        padding: "2px 4px",
        cursor: "pointer",
        outline: 0,
      },
      green: {
        color: "white",
        backgroundColor: "green",
        borderRadius: "3px",
        maxWidth: "80px",
        padding: "2px 4px",
        cursor: "pointer",
        outline: 0,
      },
      normal: {
        borderRadius: "3px",
        maxWidth: "80px",
        padding: "2px 4px",
        cursor: "pointer",
        outline: 0,
      },
      rowWidth: {
        width: "200px",
      },
    }

    const createdAt = formatUnixDateTime(Number(this.props.data.createdAt), 'LLL')

    return (
      <tr>
        <td className="dev-row">{`${this.props.data.author.firstName} ${this.props.data.author.lastName}`}</td>
        <td className="dev-row">{createdAt}</td>
        <td className="dev-row">{this.props.data.content}</td>
        <td className="dev-row" style={style.rowWidth}>
          {
            !this.state.confirmVisable ? 
              (
                <React.Fragment>
                  <button style={style.red} onClick={() => this.setState({ confirmVisable: true })}>
                    DELETE
                  </button>
                </React.Fragment>
              )
              :
              (
                <React.Fragment>
                  <button style={style.green} onClick={() => this.props.deleteNote(this.props.data.id)}>
                    Confirm
                  </button>
                  <button style={style.normal} onClick={() => this.setState({ confirmVisable: false })}>
                    Cancel
                  </button>
                </React.Fragment>
              )
          }
        </td>
      </tr>
    )
  }
}

NotesTableRow.propTypes = {
  data: PropTypes.object,
  deleteNote: PropTypes.func,
}

export { NotesTableRow }
