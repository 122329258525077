import * as Chance from "chance"
import { ISpokenLanguage } from "~/common/interfaces"

const chance = new Chance()

const LanguageIncoming = (name: string): ISpokenLanguage => ({
  id: chance.guid(),
  name,
})

export { LanguageIncoming }
