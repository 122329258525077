import * as React from "react"

interface IAlertProps {
  timeout?: number,
  text?: string,
  header?: string,
  notificationType: string,
  onClose: any,
}

const TO_MILISECONDS = 1000

class Notification extends React.Component<IAlertProps> {
  private timer: any

  public componentDidMount() {
    const { timeout, onClose } = this.props
    this.timer = timeout && setTimeout(onClose, timeout * TO_MILISECONDS)
  }

  public componentWillUnmount() {
    clearTimeout(this.timer)
  }

  public render() {
    const { header, text, notificationType } = this.props
    const alertClassName = `show alert ${ notificationType } fade in`

    return (
      <div className={alertClassName}>
          <button className="close" onClick={this.props.onClose}>
          &times;
          </button>
          {header &&
            <React.Fragment>
              <strong>{header}</strong>
              <br />
            </React.Fragment>
          }
          {text}
      </div>
    )
  }
}

export { Notification }
