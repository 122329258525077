import React from "react"
import PropTypes from 'prop-types'
import { CardBody, Col, Row } from 'reactstrap'
import { TypeSelect } from "./TypeSelect"
import { HubSpotControl } from "./HubSpotControl"
import normalizeUrl from 'normalize-url'
import { EHireable } from '~/components/presenters/FilterBar/FilterSideBar'
import { AssignButton } from "~/components/presenters/Buttons/AssignButton"
import { userBelongsToPrimaryOrganization } from "~/helpers"
import { Switcher } from "~/components/hocs"
const HUBSPOT_DEALS_URL = "https://app.hubspot.com/contacts/5492847/deal"

const DetailedViewTop = (props) => {

  const style = {
    yes: {
      background: "#3AC47D",
      borderRadius: "3px",
      color: "white",
      padding: "1px 1px",
      textAlign: "center",
      width: "40px",
    },
    no: {
      background: "red",
      borderRadius: "3px",
      color: "white",
      padding: "1px 1px",
      textAlign: "center",
      width: "40px",
    },
    unknown: {
      background: "#f7be45",
      borderRadius: "3px",
      color: "white",
      padding: "1px 1px",
      textAlign: "center",
      width: "70px",
    },
  }

  const hubspotDealUrl = props.dev.hubspotDealId? `${HUBSPOT_DEALS_URL}/${props.dev.hubspotDealId}` : null

  return (
    <div>
      { props.dev && <Row>
        <CardBody className="col-lg-4 offset-2">
          <Col className="col-lg">
            <Row>
              <Col className="col-lg">
                <h3>{props.dev.name}</h3>
                <p>{props.dev.location}</p>
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="col-lg-3">
                <small>Assignee:</small>
              </Col>
              <Col>
                <AssignButton
                  contributor={props.dev}
                  handleAssignToMe={props.handleAssignToMe}
                  handleUnassign={props.handleUnassign}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col className="col-lg-3">
                <small>Hireable:</small>
              </Col>
              <Col>
                {
                  props.dev.isHireable === EHireable.filterYes &&
                  <p style={style.yes}>Yes</p>
                }
                {
                  props.dev.isHireable === EHireable.filterNo &&
                  <p style={style.no}>No</p>
                }
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-3">
                <small>Score:</small>
              </Col>
              <Col className="col-lg">
                <p>{Number.parseFloat(props.dev.score.value).toFixed(2)}</p>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-3">
                <small>Tech Stack:</small>
              </Col>
              <Col className="col-lg">
                <p>{!!props.stack.length && props.stack.join(", ") || "Unavailable..."}</p>
              </Col>
            </Row>
            <Row>
              <Col className="col-lg-3">
                <small>Bio:</small>
              </Col>
              <Col className="col-lg">
                <p>{props.dev.bio ? props.dev.bio : "No Bio Available..."}</p>
              </Col>
            </Row>
            {props.dev.websiteUrl && <Row>
              <Col className="col-lg-3">
                <small>Website:</small>
              </Col>
              <Col className="col-lg">
                <p><a href={normalizeUrl(props.dev.websiteUrl)}>{props.dev.websiteUrl}</a></p>
              </Col>
            </Row>}
            <Row>
              <Col className="col-lg-3">
                <small>GitHub:</small>
              </Col>
              <Col className="col-lg">
                <p><a href={`https://github.com/${props.dev.username}`}>{props.dev.username}</a></p>
              </Col>
            </Row>
          </Col>
        </CardBody>

        <CardBody
          className="col-lg-6"
        >
          <Col className="col-lg">
            <br />
            {/* { FUTURE FEATURES } */}
            {/* <Row>
              <Col className="col-lg">
                <label>
                  <input type="checkbox" style={{ marginRight: "10px" }} />
                  Mark as Irrelevant
                </label>
              </Col>
            </Row> */}
            <Switcher
              shouldRenderComponent={userBelongsToPrimaryOrganization()}
              component={
                <HubSpotControl
                  hubspotDealUrl={hubspotDealUrl}
                  createHubspotDealHandler={props.createHubspotDealHandler}
                />
              }
            />
            <br />
            <TypeSelect
              addNote={props.addNote}
              updateTypeStatus={props.updateTypeStatus}
              typeStatus={props.dev.type}
              lastNoteTime={props.lastNoteTime}
            />
          </Col>
        </CardBody>
      </Row> }
    </div>
  )
}

DetailedViewTop.propTypes = {
  dev: PropTypes.object,
  stack: PropTypes.array,
  addNote: PropTypes.func,
  updateTypeStatus: PropTypes.func,
  lastNoteTime: PropTypes.string,
  handleAssignToMe: PropTypes.func,
  handleUnassign: PropTypes.func,
  createHubspotDealHandler: PropTypes.func,
}

export { DetailedViewTop }
