import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import logo from '~/assets/expertlead-logo.png'


class Portal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      route: '',
    }
  }

  handleClick(route) {
    this.setState({ route })
  }

  render() {
    if (this.state.route === '') {
      return (
        <div className="loginScreen animated fadeIn">
          <div className="mainMenu">

            <div onClick={() => this.handleClick('connect')}>
              <Card className="loginscreen-out" >
                <CardBody className="loginScreen-inner">
                  <div>
                    <img src={logo} alt="expertlead-logo" />
                    <div className="text-white menu-active" id="connect-menu">Connect</div>
                  </div>
                  <i className="fa cui-chevron-right fa-4x"></i>
                </CardBody>
              </Card>
            </div>

            <div onClick={() => this.handleClick('supply')}>
              <Card className="loginscreen-out">
                <CardBody className="loginScreen-inner">
                  <div>
                    <img src={logo} alt="expertlead-logo" />
                    <div className="text-white menu-active" id="supply-menu">Supply</div>
                  </div>
                  <i className="fa cui-chevron-right fa-4x"></i>
                </CardBody>
              </Card>
            </div>

          </div >
        </div >
      )
    } else if (this.state.route === 'supply') {
      return (
        <Redirect to="/supplybooster" />
      )
    } else if (this.state.route === 'connect') {
      return (
        <Redirect to="/connect/home" />
      )
    }
  }
}

export default Portal
