import React, { Component } from 'react'
import { Button, Col, Container, Row } from 'reactstrap'
import { Redirect } from 'react-router-dom'

class Page404 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      redirect: false,
    }
  }

  render() {
    if (this.state.redirect === false) {
      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col md="6">
                <div className="page404">
                  <div className="clearfix">
                    <h1 className="float-left display-3 mr-4">404</h1>
                    <h4 className="pt-3">
                      We couldn't find what you were looking for
                    </h4>
                    <p className="text-muted float-left">
                      ...but we found cats, there are always cats on the
                      internet!
                    </p>
                  </div>
                  <img
                    src="https://i.imgur.com/IVoM3jy.gif?noredirect"
                    alt="cat"
                  />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="6">
                <div className="button404">
                  <Button
                    onClick={() => {
                      this.setState({ redirect: true })
                    }}
                  >
                    Return to Home Page
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )
    } else {
      return <Redirect to="/" />
    }
  }
}
export default Page404
