import * as Joi from "joi"
import {
  NullID,
} from "~/common/validation-schema/common"

export const experienceformValidation = Joi
  .object()
  .allow({})
  .keys({
    id: Joi.string(),
    companyId: Joi.string().required(),
    cityId: Joi.string(),
    jobTitle: Joi.string().required(),
    startDate: Joi.string().required(),
    endDate: Joi.string(),
    description: Joi.string(),
})
