import * as React from "react"

import { SpokenLanguagesTable } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

const spokenLanguagesListingScreen: React.SFC<any> = (props): React.ReactElement<any> => {

  const renderSpokenLanguagesTable = () => (<SpokenLanguagesTable />)
  return (
    <ConnectListingScreen
      {...props}
      renderListComponent={renderSpokenLanguagesTable}
    />
  )
}

export const SpokenLanguagesListingScreen: React.SFC<any> = spokenLanguagesListingScreen
