import React from 'react'
import PropTypes from 'prop-types'

const TableHeader = props => {

  const tableHeaderNames = props.names.map((item, index) => {
    return <th
      className="dev-row"
      key={index}
      style={{ color: item.style }}
      onClick={props.handleSortType}
      data-value={item.columnName}
    >
      {item.name}
    </th>
  })

  return (
    <thead>
      <tr>
        {tableHeaderNames}
      </tr>
    </thead>
  )

}

TableHeader.propTypes = {
  names: PropTypes.array,
  handleSortType: PropTypes.func,
}

export default TableHeader
