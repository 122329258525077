import * as PropTypes from "prop-types"
import * as React from "react"

import { get } from "lodash/fp"
import { Link } from "react-router-dom"
import {
  Button,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import { ISkill, ISkillNode } from "~/common/interfaces/skill"
import { typeProps } from "~/helpers/common"

export interface ISkillNodeProps {
  node: ISkillNode,
  parentId: string,
  id: string,
  level: number,
  opened: boolean,
  mapper: (nodes: Array<ISkillNode>, parentId: string, level: number) => {},
  toggle: (event: React.ChangeEvent<HTMLInputElement>) => void,
  renderItem?: (node: ISkillNode) => React.ReactNode,
}

interface IProps {
  tree: Array<ISkillNode>
  renderItem?: (node: ISkillNode) => React.ReactNode,
}

const SkillNode: React.SFC<ISkillNodeProps> = (props: ISkillNodeProps)
  : React.ReactElement<ISkillNodeProps> => {
    const { node, level, parentId, id, mapper, toggle, opened, renderItem } = props
    const clsName = `${parentId ? `rounded-0 ${props.level ? "border-bottom-0" : ""}` : ""}`
    return (
      <React.Fragment>
        <ListGroupItem style={{ zIndex: 0 }} className={clsName}>
          {<div style={{ paddingLeft: `${50 * props.level}px` }}>
            {
              <Button
                className="skill-collapse-button"
                color="link"
                id={id}
                disabled={!get("children.length")(node)}
                onClick={toggle as any}
              >
                {opened ? "-" : "+"}
              </Button>
            }
            {node.category.name} - {renderItem ? renderItem(node) : node.category.description}
            <Link to={`/connect/skills/edit/${node.category.id}`}>
              <i className="fa fa-edit fa-2x skills-edit" />
            </Link>
          </div>}
        </ListGroupItem>
        {node.children &&
          <Collapse isOpen={opened}>
            {mapper(node.children, id, (level || 0) + 1)}
          </Collapse>
        }
      </React.Fragment>
    )
}

class SkillsTableComponent extends React.Component<IProps, { [nodeId: string]: boolean }> {
  constructor(props: IProps) {
    super(props)
    this.state = {}
  }

  public render() {
    const mapper = (nodes: Array<ISkillNode>, parentId: string, level: number) => {
      return nodes.map((node: ISkillNode) => {
        const id = this.constructId(node, parentId)
        return (
          <SkillNode
            key={id}
            level={level}
            node={node}
            parentId={parentId}
            id={id}
            mapper={mapper}
            opened={this.state[id]}
            toggle={this.toggle}
            renderItem={this.props.renderItem}
          />
        )
      })
    }
    return (
      <ListGroup>
        {mapper(this.props.tree, "", 0)}
      </ListGroup>
    )
  }

  public toggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id: any = event.target.getAttribute("id")
    this.setState((state) => ({ [id]: !state[id] }))
  }

  private constructId(node: ISkillNode, parentId: string) {
    return `${node.category.id}-${parentId ? parentId : "top"}`.replace(/[^a-zA-Z0-9-_]/g, "")
  }

}

const propTypes = {
  tree: PropTypes.array,
}

export const SkillsTable = typeProps(propTypes)(SkillsTableComponent)
