import "./ProfileForm.scss"

import * as Joi from "joi"
import * as React from "react"

import { getPublicProfileUrl } from "~/helpers"

import {
  Alert,
  Button,
  Card,
  CardBody,
  Col, Form,
  FormGroup,
  Row,
} from "reactstrap"
import { View } from "samx"
import {
  EProfileGenders, EProfilePools, IOwner, IProfile, IRemoteFile,
} from "~/common/interfaces"

import { FileUpload, Manager } from "~/components/containers"
import {
  BackButton,
  CancelButton,
  CitiesDropdown,
  Communications,
  DropDown,
  Educations,
  Experiences,
  HourlyRate,
  Languages,
  Portfolios,
  Skills,
  TextArea,
  TextInput,
  TravelPreferences,
} from "~/components/presenters"
import { CountriesDropdown, FocusRole } from "~/components/presenters"
import { CONNECT_PROFILES_BUCKET_NAME, HUBSPOT_DEAL_URL } from "~/config/env"
import { arrifyEnumeration, userBelongsToPrimaryOrganization, userFullName } from "~/helpers"
import { authState } from "~/samx/states/auth"
import { Availability } from "../InputFields/Availability"

export interface IProfileFormProps {
  profile: IProfile,
  isSubmitSuccessful: boolean,
  isSubmitEnabled: boolean
  validationSchema: Joi.JoiObject
  validationError: Joi.ValidationError | {} | string,
  onInputChange: (field: string) => (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => any,
  onNumberInputChange: (field: string) => (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => any,
  onChildInput: (field: string) => any,
  onFocusChange: (e: any) => any,
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void,
  saveRef: (ref: React.RefObject<HTMLDivElement>) => void,
  onInputPictureAdded: (files: Array<IRemoteFile>) => void,
  onInputPictureRemoved: () => void,
  onAvailabilityChange: (object: any) => void,
}

const errorBoxRef: any = React.createRef()

const scrollToElement = (elementName: string, domSelector = "") => {
  const elementNode = domSelector
    ? document.querySelector(domSelector)
    : document.querySelector(`input[name='${elementName}']`)
    || document.querySelector(`textarea[name='${elementName}']`)
    || document.querySelector(`select[name='${elementName}']`) as any

  if (elementNode && typeof elementNode.focus === "function") {
    window.scrollTo(0, elementNode.getBoundingClientRect().top)
    elementNode.focus()
  }
}

const loadManager = (owner: IOwner) => (addDefaultOwnerFunc): any => {
  let managerId: string
  let managerName: string
  if (owner && owner.id) {
    managerId = owner.id
    managerName = userFullName(owner)
  } else {
    managerId = authState.id
    managerName = authState.fullName
    addDefaultOwnerFunc({ id: managerId })
  }
  return { managerId, managerName }
}

const ProfileFormComponent: React.FC<IProfileFormProps> = (props: IProfileFormProps):
  React.ReactElement<IProfileFormProps> => {

  const ProfileGenders = arrifyEnumeration(EProfileGenders)
  const ProfilePools = arrifyEnumeration(EProfilePools)

  props.saveRef(errorBoxRef)

  const onCityChanged = (input, [{ id, name, country }]) => {
    props.onChildInput("location")({ id, name, country })
  }

  const viewProfileButton = (
    <Col xs="auto">
      <Button target="_blank" rel="noopener noreferrer" href={`${getPublicProfileUrl(authState.organizationId)}/${props.profile.slug}`}>
        View Profile
      </Button>
    </Col>
  )

  const { managerId, managerName } = loadManager(props.profile.owner)(props.onChildInput("owner"))

  const form = (
    <div>
      <Form>
        <div ref={errorBoxRef}>
          {Object.keys(props.validationError).length !== 0 && (
            <FormGroup>
              <Alert color="danger">
                {Object
                  .keys(props.validationError)
                  .map(
                    (k: string) => (<li
                      className="underline-on-hover"
                      onClick={scrollToElement.bind(null, k, props.validationError[k].domSelector)}
                    >
                      {props.validationError[k].message}
                    </li>),
                  )
                }
              </Alert>
            </FormGroup>
          )}
        </div>

        <Card className="col-lg-12 justify-content-md-center">
          <CardBody>
            <FormGroup>
              <Row>
                <TextInput
                  required={true}
                  type="text"
                  name="firstName"
                  labelText="First Name*"
                  placeHolderText="First Name"
                  handleChange={props.onInputChange("firstName")}
                  value={props.profile.firstName}
                />
                <TextInput
                  required={true}
                  type="text"
                  name="lastName"
                  labelText="Surname*"
                  placeHolderText="Surname"
                  handleChange={props.onInputChange("lastName")}
                  value={props.profile.lastName}
                />

                {
                  props.profile.id &&
                  <TextInput
                    required={true}
                    disabled={true}
                    type="text"
                    name="slug"
                    labelText="Slug*"
                    placeHolderText="Slug"
                    value={props.profile.slug}
                  />
                }

                <FocusRole
                  focusRole={props.profile.focusRole}
                  handleChange={props.onFocusChange}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col>
                  <CitiesDropdown
                    label={"City*"}
                    labelClassName={"section-title"}
                    onChange={onCityChanged}
                    value={[props.profile.location]}
                  />
                </Col>
                <TextInput
                  required={true}
                  type="date"
                  name="dob"
                  placeHolderText=""
                  labelText="Date of Birth"
                  handleChange={props.onInputChange("dob")}
                  value={props.profile.dob}
                />
                <TextInput
                  required={false}
                  type="text"
                  name="hubspotDealUrl"
                  placeHolderText=""
                  labelText="Hubspot Deal URL"
                  handleChange={props.onInputChange("hubspotDealUrl")}
                  value={props.profile.hubspotDealUrl as string}
                />
                <Manager
                  managerId={managerId}
                  managerName={managerName}
                  onChange={props.onChildInput("owner")}
                />
                <DropDown
                  name="gender"
                  labelText="Gender*"
                  options={ProfileGenders}
                  handleChange={props.onInputChange("gender")}
                  value={props.profile.gender}
                />
                <DropDown
                  name="pool"
                  labelText="Pool*"
                  options={ProfilePools}
                  handleChange={props.onInputChange("pool")}
                  value={props.profile.pool}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <TextInput
                  required={true}
                  type="text"
                  name="addressLine1"
                  labelText="Address Line 1"
                  placeHolderText="Address Line 1"
                  handleChange={props.onInputChange("addressLine1")}
                  value={props.profile.addressLine1 as string}
                />
                <TextInput
                  required={true}
                  type="text"
                  name="addressLine2"
                  labelText="Address Line 2"
                  placeHolderText="Address Line 2"
                  handleChange={props.onInputChange("addressLine2")}
                  value={props.profile.addressLine2 as string}
                />
                <TextInput
                  required={true}
                  type="text"
                  name="postalCode"
                  labelText="Postal Code"
                  placeHolderText="Postal Code"
                  handleChange={props.onInputChange("postalCode")}
                  value={props.profile.postalCode as string}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <HourlyRate
                  value={props.profile.hourlyRate}
                  handleChange={props.onNumberInputChange("hourlyRate")}
                  shouldRenderMarginFields={userBelongsToPrimaryOrganization()}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <TravelPreferences
                  value={props.profile.travelPreferences}
                  handleChange={props.onInputChange("travelPreferences")}
                />
                <DropDown
                  name="isLongTermProject"
                  labelText="Long Term Only?"
                  options={[
                    "No",
                    "Yes",
                  ]}
                  handleChange={props.onInputChange("isLongTermProject")}
                  value={props.profile.isLongTermProject}
                />
                <CountriesDropdown
                  labelText={"Tax Location*"}
                  inputName="locationVat"
                  value={props.profile.locationVat}
                  setCountry={props.onChildInput("locationVat")}
                />
                <TextInput
                  required={true}
                  type="text"
                  name="vat"
                  labelText="Tax ID Number*"
                  placeHolderText=""
                  handleChange={props.onInputChange("vat")}
                  value={props.profile.vat as string}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <TextArea
                  name="bio"
                  labelText="Bio*"
                  placeHolderText="Write Freelancer Bio Here..."
                  handleChange={props.onInputChange("bio")}
                  value={props.profile.bio}
                  maxLength={800}
                  enableWordCount={true}
                />
                <TextArea
                  name="headline"
                  labelText="Headline*"
                  placeHolderText="e.g. Senior Fullstack Developer"
                  handleChange={props.onInputChange("headline")}
                  value={props.profile.headline as string}
                  maxLength={60}
                  enableWordCount={true}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Availability
                  onAvailabilityChange={props.onAvailabilityChange}
                  value={props.profile.availability}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row className="referralCodeWrapper">
                <TextInput
                  required={true}
                  type="text"
                  name="referralCode"
                  labelText="Referral Code"
                  disabled={true}
                  placeHolderText="Your expertlead referral code here..."
                  handleChange={props.onInputChange("referralCode")}
                  value={props.profile.referralCode as string}
                />
              </Row>
              <Row>
                <FileUpload
                  label="Freelancer Picture"
                  multiple={false}
                  bucket={CONNECT_PROFILES_BUCKET_NAME}
                  files={[props.profile.photo]}
                  onDrop={props.onInputPictureAdded}
                  onRemove={props.onInputPictureRemoved}
                />
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <TextArea
                  name="note"
                  labelText="Note"
                  placeHolderText="Add addtional information here..."
                  handleChange={props.onInputChange("note")}
                  value={props.profile.note as string}
                  maxLength={5000}
                  enableWordCount={true}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Communications
                  setCommunications={props.onChildInput("communications")}
                  value={props.profile.communications}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Skills
                  setSkills={props.onChildInput("skills")}
                  defaultSkills={props.profile.skills}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Languages
                  setSpokenLanguages={props.onChildInput("spokenLanguages")}
                  value={props.profile.spokenLanguages}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Experiences
                  setExperiences={props.onChildInput("experiences")}
                  value={props.profile.experiences}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Educations
                  setEducations={props.onChildInput("educations")}
                  value={props.profile.educations}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Portfolios
                  setPortfolios={props.onChildInput("portfolios")}
                  value={props.profile.portfolios}
                />
              </Row>
            </FormGroup>
          </CardBody>
        </Card>

        <Card className="col-lg-12 justify-content-md-center" id="formContainer">
          <CardBody>
            <FormGroup>
              <Row>
                <Col xs="auto">
                  <Button
                    color="success"
                    type="submit"
                    name="submit"
                    onClick={props.onSubmit}
                    disabled={!props.isSubmitEnabled}
                  >
                    Save
                  </Button>
                </Col>
                {props.profile.id && viewProfileButton}
                <Col xs="auto">
                  {
                    props.isSubmitSuccessful ?
                      <BackButton
                        linkTo="/connect/home"
                        buttonText="Back to List"
                      />
                      :
                      <CancelButton />
                  }
                </Col>
              </Row>
            </FormGroup>
          </CardBody>
        </Card>
      </Form>

    </div>
  )

  return form
}

const ProfileForm = View(ProfileFormComponent)

export {
  ProfileForm,
}
