import { CLIENT_ORG_ID, PRIMARY_ORG_ID } from "~/config/env"
import { authState } from "~/samx/states/auth"

const containsUserOrganizationId = (orgIds: Array<string>) =>
  orgIds.includes(authState.organizationId)

const userBelongsToOrganization = (orgId: string) =>
  containsUserOrganizationId([orgId])

const userBelongsToPrimaryOrganization = () =>
  userBelongsToOrganization(PRIMARY_ORG_ID)

const userBelongsToClientOrganization = () =>
  userBelongsToOrganization(CLIENT_ORG_ID)

const userFullName = (user) => `${user.firstName} ${user.lastName}`

export {
  userFullName,
  userBelongsToPrimaryOrganization,
  userBelongsToClientOrganization,
}
