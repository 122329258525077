import * as PropTypes from "prop-types"
import * as React from "react"

import { NavBar } from "~/components/presenters"

import { Container, Row } from "reactstrap"
import { FloatingTopMenu } from "~/components/presenters/Menu/FloatingTopMenu"
import { typeProps } from "~/helpers/common"

export interface IListingScreenProps {
  navItems: Array<{
    render: () => React.ReactElement<any> | any,
    route: string,
  }>,
  renderListComponent: () => any,
  location?: { pathname: string },
}

const listingScreen: React.SFC<IListingScreenProps> = (props: IListingScreenProps)
  : React.ReactElement<any> => {
    const { renderListComponent, navItems } = props

    return (
      <div>
        <NavBar />
        <Row id="formBuilderContainer">
          <Container className="col-lg-10 mx-auto">
            <FloatingTopMenu items={navItems} />
            {renderListComponent()}
          </Container>
        </Row>
      </div>
    )
}

const propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  renderListComponent: PropTypes.func,
  location: PropTypes.object,
}

export const ListingScreen: React.SFC<IListingScreenProps> = typeProps(propTypes)(listingScreen)
