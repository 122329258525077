import * as React from "react"

import { Login } from "~/components/presenters"

import { withRouter } from "react-router-dom"
import { IGoogleProfile } from "~/common/interfaces"
import { redirectTo } from "~/samx/actions"
import { login } from "~/samx/actions/auth"

const loginContainer: React.FC<any> = ({ history }: any): React.ReactElement<any> => {
  const onGoogleSuccessfulAuth: any = async (response: {
    profileObj: IGoogleProfile,
    tokenId: string,
    tokenObj: {
      first_issued_at: number,
      expires_in: number,
    },
  }) => {
    const {
      tokenId,
      profileObj: {
        email,
        givenName,
        familyName,
        imageUrl,
      },
    } = response

    await login({
      email,
      accessToken: tokenId,
      firstName: givenName,
      lastName: familyName,
      imageUrl,
    })

    redirectTo("/")
  }

  const onGoogleFailedAuth = (...args: any) => {
    console.error("google login failed", args)
    history.push("/auth/unauthorized")
  }

  return (
    <Login
      onSuccess={onGoogleSuccessfulAuth}
      onFailure={onGoogleFailedAuth}
    />
  )
}

const LoginContainer = withRouter(loginContainer)

export {
  LoginContainer,
}
