import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Button, Col, Row } from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { EType } from '~/components/presenters/FilterBar/FilterSideBar'

class TypeSelect extends Component {
  constructor(props) {
    super(props)

    this.state = {
      typeStatus: "",
      noteContent: "",
    }

    this.handleTypeStatusUpdate = this.handleTypeStatusUpdate.bind(this)
    this.handleAddNote = this.handleAddNote.bind(this)
  }

  componentDidMount() {
    this.setState({ typeStatus: this.props.typeStatus })
  }

  handleTypeStatusUpdate(e) {
    this.setState({ typeStatus: e.target.value }, async () => {
      const res = await this.props.updateTypeStatus(this.state.typeStatus)
      if(res.status === 200) return toast.success("Employment Status Updated!")
    })
  }

  async handleAddNote() {
    const res = await this.props.addNote(this.state.noteContent)
    if(res.status === 200) {
      this.setState({ noteContent: "" })
      return toast.success("Note Added!")
    }
  }
  render() {
    return (
      <Row>
        <ToastContainer />
        <Col className="col-lg-10">
          <Row>
            <Col>
              <p><strong>Type</strong></p>
            </Col>
          </Row>
          <Row>
            <form name="type" >
              <Col className="col-lg">
                <label>
                  <input 
                    type="radio" 
                    name="type" 
                    value={EType.filterUnknown}
                    checked={this.state.typeStatus === EType.filterUnknown}
                    onChange={this.handleTypeStatusUpdate}
                  />
                    &nbsp;Unknown
                </label>
              </Col>
              <Col className="col-lg">
                <label>
                  <input 
                    type="radio" 
                    name="type" 
                    value={EType.filterFreelancer} 
                    checked={this.state.typeStatus === EType.filterFreelancer}
                    onChange={this.handleTypeStatusUpdate}
                  />
                    &nbsp;Freelancer
                </label>
              </Col>
              <Col className="col-lg">
                <label>
                  <input 
                    type="radio" 
                    name="type" 
                    value={EType.filterEmployee}
                    checked={this.state.typeStatus === EType.filterEmployee}
                    onChange={this.handleTypeStatusUpdate} 
                  />
                    &nbsp;Full Time Employee
                </label>
              </Col>
            </form>
          </Row>
          <Row>
            <Col className="col-lg-8">
              <hr />
              <p
                style={{
                  background: "white",
                  padding: "3px",
                  marginTop: "15px",
                }}
              >
                Add a Note
              </p>
              <textarea
                style={{
                  border: "1px solid lightgrey",
                  marginTop: "-15px",
                  width: "100%",
                }}
                rows="4"
                onChange={(e) => this.setState({ noteContent: e.currentTarget.value })}
                value={this.state.noteContent}
              >
              </textarea>
            </Col>
          </Row>
          <Row>
            <Col className="col-lg-8">
              <div style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
                <Button
                  onClick={this.handleAddNote}
                >
                  Add Note
                </Button>
                <small>Last Note Added: {this.props.lastNoteTime}</small>
              </div>
            </Col>
          </Row>
        </Col>
      </Row >
    )
  }
}

TypeSelect.propTypes = {
  addNote: PropTypes.func,
  typeStatus: PropTypes.string,
  updateTypeStatus: PropTypes.func,
  lastNoteTime: PropTypes.string,
}

export { TypeSelect }
