import * as React from "react"

import { flow } from "lodash/fp"
import { Redirect, withRouter } from "react-router-dom"
import { View } from "samx"
import { IProfile } from "~/common/interfaces"
import { profileformValidation } from "~/common/validation-schema"
import { IProfileFormProps, ProfileForm } from "~/components/presenters"
import { createProfile, getProfiles } from "~/samx/actions/collections"
import { getInitialState, ProfileContainer } from "./common"

class NewProfileFormClass extends ProfileContainer {
  public state = getInitialState()

  public async saveData(profileData: IProfile) {
    this.setState({
      isSubmitEnabled: false,
    })

    const returnedProfile = await createProfile(profileData)
    await getProfiles()

    this.setState({
      isSubmitEnabled: true,
      profile: returnedProfile.data,
    })
  }

  public prepareProps() {
    const onSubmit = this.onSubmit.bind(this)
    const onInputChange = this.onInputChange.bind(this)
    const onNumberInputChange = this.onNumberInputChange.bind(this)
    const onFocusChange = this.onFocusChange.bind(this)
    const onChildInput = this.onChildInput.bind(this)
    const saveRef = this.saveRef.bind(this)
    const onInputPictureAdded = this.onInputPictureAdded.bind(this)
    const onInputPictureRemoved = this.onInputPictureRemoved.bind(this)
    const onAvailabilityChange = this.onAvailabilityChange.bind(this)

    return {
      profile: this.state.profile,
      validationError: this.state.validationError,
      onSubmit,
      onInputChange,
      onNumberInputChange,
      onFocusChange,
      onChildInput,
      isSubmitEnabled: this.state.isSubmitEnabled,
      isSubmitSuccessful: this.state.isSubmitSuccessful,
      validationSchema: profileformValidation,
      saveRef,
      onInputPictureAdded,
      onInputPictureRemoved,
      onAvailabilityChange,
    } as IProfileFormProps
  }

  public render() {
    const profileId = this.state.profile.id
    if (profileId) {
      return <Redirect to={`/connect/profile/edit/${profileId}`} />
    }

    return <ProfileForm {...this.prepareProps()} />
  }
}

export const NewProfileForm: any = flow(
  withRouter,
  View,
)(NewProfileFormClass as any)
