import {
  get,
} from "lodash/fp"
import { Propose } from "samx"
import {
  IInvitation,
  InvitationStatus,
  IStatusFormProfile,
} from "~/common/interfaces"
import * as api from "~/services/api"
import { InvitationIncoming } from "~/services/api/adapters"
import {
  AppendToCollection,
} from "../proposals/collections"

const proposer = Propose("collections")

const getLatestInvitation = async (profileId: string) => {
  let invitation: IInvitation = {
    id: profileId,
    referenceId: profileId,
    status: InvitationStatus.undefined,
  }

  try {
    const result = get("data")(await api.getLatestInvitation(profileId))
    invitation = InvitationIncoming(result)
  } catch (e) {
    // tslint:disable-next-line:no-empty
  }

  proposer(AppendToCollection("invitations")(invitation))
}

const sendInvitation = async (invitation: IStatusFormProfile) => {
  return await api
    .sendInvitation(invitation)
    .then(() => getLatestInvitation(invitation.profileId))
}

export {
  getLatestInvitation,
  sendInvitation,
}
