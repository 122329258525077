import { authState } from "~/samx/states/auth"
import { request } from "~/services/api/resources/request"

const getList = async (query) => request({
  method: "GET",
  url: `contributors?${query.queryString}`,
})

const assignToMe = async (query) => request({
  method: "POST",
  url: `contributors/${query.contributorId}/assign/${query.userId}`,
})

const unassign = async (query) => request({
  method: "DELETE",
  url: `contributors/${query.contributorId}/assign/${query.userId}`,
})

const getProfile = async (query) => {
  let scoreSkillsQuery = ""
  if (Array.isArray(query.scoreSkills) && query.scoreSkills.length) {
    scoreSkillsQuery = "?" + query.scoreSkills.map((x) => `scoreSkills[]=${x}`).join("&")
  }

  return request({
    method: "GET",
    url: `contributors/${query.id}${scoreSkillsQuery}`,
  })
}

const patchProfile = async (query) => request({
  method: "PATCH",
  url: `contributors/${query.contributorId}`,
  data: {
    type: query.type,
  },
})

const getProfileContributions = async (query) => request({
  method: "GET",
  url: `contributors/${query.id}/contributions`,
})

const getNotes = async (query) => request({
  method: "GET",
  url: `contributors/${query.id}/notes`,
})

const addNote = async (query) => request({
  method: "POST",
  url: `contributors/${query.contributorId}/notes`,
  data: {
    content: query.body,
    contributorId: query.contributorId,
    authorId: authState.id,
  },
})

const deleteNote = async (query) => request({
  method: "DELETE",
  url: `contributors/${query.contributorId}/notes/${query.id}`,
})

const createHubspotDeal = async (query) => request({
  method: "POST",
  url: `contributors/${query.contributorId}/deals`,
})

export {
    getList,
    assignToMe,
    unassign,
    getProfile,
    patchProfile,
    getProfileContributions,
    getNotes,
    addNote,
    deleteNote,
    createHubspotDeal,
}
