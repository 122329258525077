import {
  GOOGLE_API_CLIENT_URL,
  GOOGLE_AUTH_CLIENT_ID,
  GOOGLE_AUTH_SCOPE,
} from "~/config/env"
import { loadScript } from "~/helpers/common"

const initGApi = () => new Promise((resolve, reject) => {
  loadScript(GOOGLE_API_CLIENT_URL as string)
  .then(
    ({ url, script }) => {
      let timerId
      const resolveWhenGApiIsProcessed = () => {
        if (script.hasAttribute("gapi_processed") && window.gapi) {
          window.gapi.load("auth2", () => {
            if (timerId) {
              clearTimeout(timerId)
            }

            resolve({ script, client: window.gapi.client })
          })
        } else {
          timerId = setTimeout(() => {
            resolveWhenGApiIsProcessed()
          }, 100)
        }
      }

      resolveWhenGApiIsProcessed()

      setTimeout(() => {
        reject("could not load gapi")
      }, 5000)
    },
  )
})

const initGAuthClient = (client): Promise<any> => {
  const googleAuthParams = {
    clientId: GOOGLE_AUTH_CLIENT_ID,
    scope: GOOGLE_AUTH_SCOPE,
  }

  return client
    .init(googleAuthParams)
    .then(() => client)
}

const initGoogleAuthenticationApi = () => new Promise((resolve, reject) => {
  initGApi()
    .then(
      ({ client }) => {
        initGAuthClient(client)
          .then((initiatedClient) => {
            resolve({ client: initiatedClient })
          })
          .catch(reject)
      },
  )
  .catch(reject)
})

const isUserGoogleAuthenticated = (): Promise<{
  isCurrentlySignedIn: boolean,
  googleAuthInstance: any,
}> => new Promise((resolve, reject) => {
  const getUserAuthenticationStatus = () => {
    const googleAuth = window.gapi.auth2.getAuthInstance()
    const currentUser = googleAuth.currentUser.get()

    if (currentUser.hasGrantedScopes(GOOGLE_AUTH_SCOPE)) {
      return resolve({
        isCurrentlySignedIn: true,
        googleAuthInstance: googleAuth,
      })
    }

    return resolve({
      isCurrentlySignedIn: false,
      googleAuthInstance: googleAuth,
    })
  }

  if (!window.gapi || !window.gapi.auth2) {
    initGoogleAuthenticationApi().then(getUserAuthenticationStatus)
  } else {
    try {
      getUserAuthenticationStatus()
    } catch (e) {
      reject(e)
    }
  }
})

const getGoogleAuthInstance = (): Promise<any> => new Promise(
  (resolve, reject) => {
    try {
      window.gapi.load("client:auth2", () => {
        resolve(window.gapi.auth2.getAuthInstance())
      })
    } catch (e) {
      reject(e)
    }
  },
)

const signOutFromGoogle = async () => {
  const gAuthInstance = await getGoogleAuthInstance()

  return new Promise((resolve, reject) => {
    if (gAuthInstance) {
      gAuthInstance
        .signOut()
        .then(gAuthInstance.disconnect)
        .then(resolve)
        .catch(reject)
    } else {
      reject("google auth instance not available")
    }
  })
}

const signUserIn = async () => {
  const authInstance = await getGoogleAuthInstance()
  return authInstance.signIn()
}

const refreshAuthenticatedUserToken = async () => {
  const gAuthInstance = await getGoogleAuthInstance()

  return gAuthInstance
    .currentUser
    .get()
    .reloadAuthResponse()
}

export const GoogleAuthApi = {
  initApi: initGApi,
  initClient: initGAuthClient,
  init: initGoogleAuthenticationApi,
  isUserAuthenticated: isUserGoogleAuthenticated,
  signOut: signOutFromGoogle,
  signIn: signUserIn,
  refreshToken: refreshAuthenticatedUserToken,
  getAuthInstance: getGoogleAuthInstance,
}
