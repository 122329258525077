import * as React from "react"

import { View } from "samx"
import {
  EPlaceStatus,
  EPlaceType,
  IPlace,
} from "~/common/interfaces"
import { placeformValidation } from "~/common/validation-schema"
import {
  IPlaceFormProps,
  PlaceForm,
} from "~/components/presenters"
import { createPlace } from "~/samx/actions/collections"

class NewPlaceFormClass extends React.Component {
  public state = {
    isSubmitEnabled: true,
  }

  public async saveData(placeData: IPlace) {
    await createPlace(placeData)
  }

  public render() {
    return <PlaceForm {...this.prepareChildProps()} />
  }

  public prepareChildProps() {
    const saveData = this.saveData.bind(this)

    return {
      name: "",
      bio: "",
      logo: null,
      city: null,
      type: EPlaceType.organisation,
      status: EPlaceStatus.underReview,
      isSubmitEnabled: this.state.isSubmitEnabled,
      validationSchema: placeformValidation,
      saveData,
    } as IPlaceFormProps
  }
}

export const NewPlaceForm = View(
  NewPlaceFormClass,
)
