import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const linkStyle = { textDecoration: 'none', color: 'white' }
const linkStyleActive = { textDecoration: 'none', color: '#F8D343' }

class SideBar extends Component {

  render() {
    return (
      <div className={this.props.display}>
        <div className="sidebar-item">
          <NavLink to="/supplybooster" style={linkStyle} activeStyle={linkStyleActive} ><div className="sidebar-item-inner"><p><i className="fa fa-eye"></i>Supply</p></div></NavLink>
          <NavLink to="/connect/home" style={linkStyle} activeStyle={linkStyleActive} ><div className="sidebar-item-inner"><p><i className="fa fa-user"></i>Connect</p></div></NavLink>
          { this.props.shouldDisplayAdminBtn && <NavLink to="/admin" style={linkStyle} activeStyle={linkStyleActive} ><div className="sidebar-item-inner"><p><i className="fa fa-lock"></i>Admin</p></div></NavLink>}
        </div>
      </div>
      
    )
  }

}

SideBar.propTypes = {
  display: PropTypes.string,
  shouldDisplayAdminBtn: PropTypes.bool,
}

export default SideBar
