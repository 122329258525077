import * as PropTypes from "prop-types"
import * as React from "react"

import { flow } from "lodash/fp"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
} from "reactstrap"
import { View } from "samx"
import { ISkillNodeProps, SkillsTable } from "~/components/presenters/Table/SkillsTable"
import { typeProps } from "~/helpers/common"

export interface ISkillsConnectTableProps {
  list: Array<ISkillNodeProps>
  renderItem?: (node) => string
}

const skillsConnectTable: React.SFC<ISkillsConnectTableProps> = (props: ISkillsConnectTableProps)
  : React.ReactElement<ISkillsConnectTableProps> => {
    const hasList = props.list && props.list.length > 0

    if (hasList) {
      return (
        <Card className="col-lg mx-auto" id="formContainer">
          <CardBody>
            <Link to="/connect/skill/new">
              <Button size="md" color="success" className="form__button">
                New Skill <i className="fa fa-plus-square" />
              </Button>
            </Link>
            <SkillsTable tree={props.list} renderItem={props.renderItem} />
          </CardBody>
        </Card>
      )
    } else {
      return (
        <Card className="col-lg mx-auto" id="formContainer">
          <CardBody>
            <Link to="/connect/skill/new">
              <Button size="md" color="success" className="form__button">
                New Skill <i className="fa fa-plus-square" />
              </Button>
            </Link>
          </CardBody>
          <CardBody>
            <h3 id="no-item-yet-text">No items to list yet...</h3>
          </CardBody>
        </Card>
      )
    }
}

const propTypes = {
  list: PropTypes.array,
}

export const SkillsConnectTable: any = flow(
  typeProps(propTypes),
  View,
)(skillsConnectTable)
