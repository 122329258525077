import * as React from "react"
import { PageButtonsContainer } from "~/components/presenters"
import { userBelongsToPrimaryOrganization } from "~/helpers"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"

import Tooltip from "react-tooltip-lite"
import { Card, CardBody, Table } from "reactstrap"

import { IListItem } from "~/common/interfaces/supply-booster-list-item"

interface IDataTableProps {
  pageNumber: number,
  totalCount: number,
  perPage: number,
  pageChange: () => void,
  user: string,
  userId: string,
  assignToMe: () => void,
  unassign: () => void,
  assignedStatus: string,
  currentSortValue: string | null,
  list: Array<IListItem>,
  isLoading: boolean,
  assignErrorDisplay: boolean,
  sortOrder: string,
  handleSorting: (object: object) => any,
  fetchList: () => void,
  handleAssignToMe: (contributorId: string) => void,
  handleUnassign: (contributorId: string) => void,
  shouldRenderScoreFields: boolean
  scoreSkills: Array<any>,
}

class DataTable extends React.Component<IDataTableProps, {}> {

  public render() {
    const shouldRenderScoreFields = userBelongsToPrimaryOrganization()
    const developerList = this.props.list.map((dev) => {
      return (
        <TableRow
          dev={dev}
          key={dev.id}
          pageChange={this.props.pageChange}
          pageNumber={this.props.pageNumber}
          handleAssignToMe={this.props.handleAssignToMe}
          handleUnassign={this.props.handleUnassign}
          shouldRenderScoreFields={shouldRenderScoreFields}
          scoreSkills={this.props.scoreSkills}
        />
      )
    })

    return (
      <div className="animated fadeIn">
        <Card>
          <CardBody>
            {this.renderPageNavigation()}
            <Table hover={true} bordered={true} striped={true} responsive={true}>
              <TableHeader
                names={[
                  "Name",
                  "Website",
                  "Location",
                  "Stack",
                  shouldRenderScoreFields &&
                  <React.Fragment key="score">
                    Score
                    <Tooltip
                      content={
                        <p>
                          The score is calculated based on the total lines added, plus total lines removed<br/>
                          from all the retrieved Github contributions belonging to each listed profile for<br/>
                          all contributed languages and frameworks. The sum is then divided by the number of<br/>
                          modified files and multiplied by the accumulated amount of stars for all <br/>
                          corresponding repositories. This influences the score in relation to the importance<br/>
                          of each repository. If a search term has been entered, eg. “Python / Berlin” the <br/>
                          score will be filtered to display based on these criteria. <br/>
                        </p>
                      }
                      direction="down"
                      background="linear-gradient(#3c26de, #291a97)"
                      color="white"
                    >
                      <i className="fa fa-question-circle" />
                    </Tooltip>
                  </React.Fragment>,
                  "Hireable",
                  "Type",
                  "Bio",
                  "Assigned Contact",
                ]}
                handleSorting={this.props.handleSorting}
                currentSortValue={this.props.currentSortValue}
                sortOrder={this.props.sortOrder}
              />
              <tbody>
                {developerList}
              </tbody>
            </Table>
            {this.renderPageNavigation()}
          </CardBody>
        </Card >
      </div >
    )
  }

  private renderPageNavigation = () => (
    <PageButtonsContainer
      pageChange={this.props.pageChange}
      totalCount={this.props.totalCount}
      pageNumber={this.props.pageNumber}
      isLoading={this.props.isLoading}
      perPage={this.props.perPage}
    />
  )
}

export { DataTable }
