import * as React from "react"
import { Button } from "reactstrap"

interface IProps {
  pageChange: (pageNumber: number) => void,
  changeNum: number,
  btnText: string,
  pageNumber: number,
  isLoading: boolean,
  disabled: boolean,
}

const PageButton = (props: IProps) => {
  const handlePageChange = () => {
    const pageNumber = props.pageNumber + props.changeNum
    props.pageChange(pageNumber)
  }

  return (
    <Button
      className="page-btn"
      onClick={handlePageChange}
      disabled={props.isLoading || props.disabled}
    >
      {props.btnText}
    </Button>
  )
}

PageButton.defaultProps = {
  disabled: false,
}

export { PageButton }
