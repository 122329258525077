import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from 'prop-types'
const ConfirmationModal = ({toggle, modal, handleDeleteUserConfirmation}) => {

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle}>Delete User</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this user?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={handleDeleteUserConfirmation}>Confirm</Button>{' '}
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )}

ConfirmationModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
  handleDeleteUserConfirmation: PropTypes.func,
}

export{ConfirmationModal}
