import normalizeUrl from "normalize-url"
import { IRemoteFile, IUploadFile } from "~/common/interfaces"
import { FileOutgoing } from "~/services/api/adapters"
import { externalRequest, request } from "./request"

const getFileUploadUrl = async ({
  bucket,
  mimeType,
}: {
  bucket: string;
  mimeType: string;
}) =>
  request({
    method: "GET",
    url: "file-upload",
    params: { bucket, mimeType },
  })

const fileUpload = async ({ bucket, file }: IUploadFile) => {
  const {
    data: { url, name, html },
  } = (await getFileUploadUrl({ bucket, mimeType: file.type })) as any

  await externalRequest<IRemoteFile>({
    method: "POST",
    baseURL: "//" + normalizeUrl(url, { stripProtocol: true }),
    data: FileOutgoing({ ...html, file }),
  })

  return { name, bucket, mimeType: file.type }
}

// TODO: implement correct endpoint for file deletion
const deleteFile = async ({ bucket, file, url }) =>
  Promise.resolve({
    ...file,
  })

export { fileUpload, deleteFile }
