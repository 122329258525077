import * as Joi from "joi"

export const portfolioformValidation = Joi
  .object()
  .allow({})
  .keys({
    id: Joi.string(),
    title: Joi.string().required(),
    description: Joi.string().allow(""),
    href: Joi.string().uri().allow(""),
    file: Joi.object({
      name: Joi.string(),
      bucket: Joi.string(),
      mimeType: Joi.string(),
    }).required(),
})
