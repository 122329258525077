const ERRORS =  {
  // tslint:disable-next-line: max-line-length
  NotConfigured: "environment store is not configured yet, make sure you have called `configure` method before you tried to retrieve the env state.",
  NotAValidProvider: "Expecting a provide function, got undefined",
}

const envStore = {
  state: {
    isConfigured: false,
    environment: null,
  },

  setState(newRef) {
    this.state = newRef
  },

  getState() {
    if (!this.state.isConfigured) {
      throw new Error(ERRORS.NotConfigured)
    }

    return this.state
  },

  get(query) {
    const state = this.getState()
    return query === "*"
      ? state.environment
      : state.environment[query]
  },

  configure({ provide }) {
    if (!provide) {
      throw new Error(ERRORS.NotAValidProvider)
    }

    const environment = provide()

    this.setState({ isConfigured: true, environment })
  },
}

export {
  envStore,
}
