export enum ENotificationTypes {
  NOTICE = "alert-info",
  ALERT = "alert-warning",
  ERROR = "alert-danger",
  SUCCESS = "alert-success",
}

export interface INotification {
  /**
   * @param timeout  Hide the notification using seconds
   */
  notificationType: ENotificationTypes,
  id?: string,
  message?: string,
  header?: string,
  timeout?: number,
}
