import { request } from "~/services/api/resources/request"

export interface IQueryResource {
  resourceName: string
  resourceField: string
  resourceQuery: string
  extraQueryParams: object
}

interface IQueryFields {
  resourceName: string
  resourceField: string
}

const queryResource = async ({
                               resourceName,
                               resourceField,
                               resourceQuery,
                               extraQueryParams,
                             }: IQueryResource) => {
  let url = `${resourceName}?${resourceField}=${encodeURIComponent(resourceQuery)}`
  if (extraQueryParams) {
    Object.keys(extraQueryParams).forEach((key: string) => url += `&${key}=${extraQueryParams[key]}`)
  }
  return request({
    method: "GET",
    url,
  })
}

const queryFields = (
  { resourceName, resourceField }: IQueryFields,
  inputValue: string,
) => ({
  resourceName,
  resourceField,
  resourceQuery: inputValue,
})

export { queryResource, queryFields }
