import * as React from "react"

import * as logo from "~/assets/expertlead-logo.png"

import { Card, CardBody } from "reactstrap"
import { LoginContainer } from "~/components/containers"

const LoginScreen: React.FC<any> = (): React.ReactElement<any> => (
  <div className="loginScreen animated fadeIn">
    <Card className="loginscreen-out">
      <CardBody className="loginScreen-inner">
        <div>
          <img src={logo} alt="expertlead-logo" />
          <div className="text-white menu-active">portal</div>
        </div>
        <LoginContainer />
      </CardBody>
    </Card>
  </div>
)

export {
  LoginScreen,
}
