import * as React from "react"

import DevTools from "mobx-react-devtools"

export const UseDevTools = ($Component) => (
  <div>
    <$Component />
    <DevTools />
  </div>
)
