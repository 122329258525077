import * as Joi from "joi"

import { first, flow, get, has, join, omit, replace, throttle } from "lodash/fp"
import { PureComponent } from "react"
import {
  ENotificationTypes,
  EProfileGenders,
  EProfilePools, IFocusRole,
  IProfile,
  IRemoteFile,
} from "~/common/interfaces"
import { profileValidationMessages } from "~/common/validation-messages"
import { validationOptions } from "~/common/validation-options"
import {
  communicationformValidation,
  educationformValidation,
  experienceformValidation,
  profileformValidation,
  skillformValidation,
  spokenLanguageformValidation,
} from "~/common/validation-schema"
import { createNotification } from "~/samx/actions"

export enum EStateField {
  validationError = "validationError",
  isSubmitSuccessful = "isSubmitSuccessful",
  isSubmitEnabled = "isSubmitEnabled",
  firstName = "firstName",
  lastName = "lastName",
  slug = "slug",
  gender = "gender",
  dob = "dob",
  pool = "pool",
  addressLine1 = "addressLine1",
  addressLine2 = "addressLine2",
  postalCode = "postalCode",
  bio = "bio",
  headline = "headline",
  location = "location",
  vat = "vat",
  locationVat = "locationVat",
  owner = "owner",
  hourlyRate = "hourlyRate",
  focusRole = "focusRole",
  travelPreferences = "travelPreferences",
  isLongTermProject = "isLongTermProject",
  experiences = "experiences",
  educations = "educations",
  portfolios = "portfolios",
  spokenLanguages = "spokenLanguages",
  skills = "skills",
  testedSkill = "testedSkill",
  communications = "communications",
  badges = "badges",
  availability = "availability",
  referralCode = "referralCode",
  photo = "photo",
  hubspotDealUrl = "hubspotDealUrl",
  note = "note",
}

export interface IProfileContainerState {
  isSubmitEnabled: boolean,
  isLoaded: boolean,
  isSubmitSuccessful: boolean,
  validationError: object,
  profile: any,
}

export const getInitialState = (): IProfileContainerState => ({
  isLoaded: false,
  isSubmitSuccessful: false,
  isSubmitEnabled: true,
  profile: {
    firstName: "",
    lastName: "",
    slug: "",
    gender: EProfileGenders.unspecified,
    dob: "",
    pool: EProfilePools.unspecified,
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    bio: "",
    headline: "",
    hourlyRate: 0,
    location: {
      id: "",
    },
    vat: "",
    locationVat: {
      id: "",
      name: "",
      iso2code: "",
    },
    owner: {
      id: "",
    },
    travelPreferences: "UNLIMITED",
    isLongTermProject: "No",
    experiences: [{}] as any,
    educations: [{}] as any,
    portfolios: [{}] as any,
    spokenLanguages: [{}] as any,
    skills: [{}] as any,
    testedSkill: [{}] as any,
    communications: [{}] as any,
    badges: [],
    focusRole: {} as IFocusRole,
    note: "",
    availability: {
      available: "AVAILABILITY_UNSPECIFIED",
      date: "",
      hours: 40,
    } as any,
    referralCode: "",
    photo: undefined,
    hubspotDealUrl: "",
  },
  validationError: {},
})

export class ProfileContainer extends PureComponent {
  private errorBoxRef: React.RefObject<HTMLDivElement>

  public saveRef(ref: React.RefObject<HTMLDivElement>) {
    this.errorBoxRef = ref
  }

  public setFullProfile = (profile: any) => {
    this.setState(
      (prevState: IProfileContainerState) => ({
        ...prevState,
      profile,
      }),
    )
  }

  public setProfile = (field: EStateField) => (value: any) => {
    this.setState(
      (prevState: IProfileContainerState) => ({
        ...prevState,
        profile: {
          ...prevState.profile,
          [EStateField[field]]: value,
        },
      }),
    )
  }

  public onInputChange = (field: EStateField) => throttle(300)(
    (
      e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
    ): void => {
      e.persist()
      const value = e.currentTarget.value
      this.setProfile(EStateField[field])(value)
    },
  )

  public onFocusChange = (
    focusRole: IFocusRole,
  ): void => {
    this.setProfile(EStateField.focusRole)(focusRole)
  }

  public onNumberInputChange = (field: EStateField) => (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ): void => {
    const { target: { value } } = e as any
    this.setProfile(EStateField[field])(
      Number(value),
    )
  }

  public onChildInput = (field: EStateField) =>
    (childData: any): void => {
      this.setProfile(EStateField[field])(childData)
    }

  public onInputPictureAdded = (files: Array<IRemoteFile>): void => {
    if (files.length > 0) {
      this.setProfile(EStateField.photo)(files[0])
    }
  }

  public onInputPictureRemoved = (): void  => {
    this.setProfile(EStateField.photo)(undefined)
  }

  public onAvailabilityChange = (availability: any): void => {
    this.setProfile(EStateField.availability)(availability)
  }

  public validate() {
    const nestedCollections = {
      experiences: "",
      educations: "",
      skills: "",
      spokenLanguages: "",
      communications: "",
    }

    const { joiError, errorPath, jsonPath, dataKey } = this.validateJoiSchema(
      this.prepDataToSubmit(),
      profileformValidation,
    )

    if (joiError) {
      let humanReadableError: { message: string, domSelector: string }

      // tslint:disable-next-line: no-console

      if (dataKey in nestedCollections) {
        const nestedCollectionData = get(jsonPath)(joiError._object)
        const {
          jsonPath: nestedJsonPath,
        } = this.validateNestedCollection(dataKey, nestedCollectionData) as any

        const nestedCollectionFullJsonPath = `${dataKey}.${nestedJsonPath}`
        const nestedCollectionInvalidItemIndex = errorPath[1]
        const buildMessage = replace("#n", nestedCollectionInvalidItemIndex)

        const buildNestedCollectionMessage = ({ message }) => ({
          message: buildMessage(message),
          domSelector: "", // skipping dom selector for nested elements ftm
        })

        if (has(nestedCollectionFullJsonPath)(profileValidationMessages)) {
          humanReadableError = flow(
            get(nestedCollectionFullJsonPath),
            buildNestedCollectionMessage,
           )(profileValidationMessages) as any

        } else {
          humanReadableError = { message: joiError.message, domSelector: "" }
        }

      } else {
        humanReadableError = has(jsonPath)(profileValidationMessages)
          ? get(jsonPath)(profileValidationMessages)
          : {
              message: joiError.message,
              domSelector: "",
            }
      }

      this.setState({
        [EStateField.validationError]: {
          [dataKey]: humanReadableError,
        },
      })

      setTimeout(() => {
        this.focusAndScrollErrorBox()
      }, 700)

      return false
    }

    this.setState({
      [EStateField.validationError]: {},
    })

    return true
  }

  public focusAndScrollErrorBox = () => {
    if (this.errorBoxRef && this.errorBoxRef.current) {
      this.errorBoxRef.current.focus()
      window.scrollTo(0, this.errorBoxRef.current.offsetTop)
    }
  }

  public prepDataToSubmit() {
    return {
      ...omit(["location.country"])((this.state as any).profile),
    }
  }

  public saveData(data: IProfile) {
    // TODO: override this in your component
  }

  public async onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!this.validate()) {
      return
    }

    try {
      await this.saveData(this.prepDataToSubmit() as any)
      this.setState({ [EStateField.isSubmitSuccessful]: true })
      createNotification({
        header: "Changes saved!",
        message: "Woohoo, you updated the profile!",
        notificationType: ENotificationTypes.SUCCESS,
        timeout: 4,
      })
    } catch (e) {
      this.onSubmitFailure(e)
    } finally {
      this.setState({
        isSubmitEnabled: true,
      })
    }
  }

  public onSubmitFailure(e) {
    this.setState({
      [EStateField.validationError]: {
        server: {
          message: e.message,
          domSelector: "",
        },
      },
    })

    setTimeout(() => {
      this.focusAndScrollErrorBox()
    }, 200)
  }

  private validateJoiSchema(data: object, schema: Joi.AnySchema) {
    const { error } = Joi.validate(data, schema, validationOptions)
    const errorPath: Array<string> = get("details.0.path")(error)
    const dataKey = first(errorPath) as string
    const jsonPath: string = join(".")(errorPath)

    return {
      joiError: error,
      errorPath,
      jsonPath,
      dataKey,
    }
  }

  private validateNestedCollection(collectionName: string, data: object) {
    switch (collectionName) {
      case "experiences":
        return this.validateJoiSchema(data, experienceformValidation)

      case "educations":
        return this.validateJoiSchema(data, educationformValidation)

      case "skills":
        return this.validateJoiSchema(data, skillformValidation)

      case "spokenLanguages":
        return this.validateJoiSchema(data, spokenLanguageformValidation)

      case "communications":
        return this.validateJoiSchema(data, communicationformValidation)

      default:
        return {}
    }
  }

  private slugify = (input: string): string => {
    const a = "àáäâãåăæąçćčđèéėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;"
    const b = "aaaaaaaaacccdeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------"
    const p = new RegExp(a.split("").join("|"), "g")

    return input.toString().toLowerCase()
    .replace(/\s+/g, "-")
    .replace(p, (c) => b.charAt(a.indexOf(c)))
    .replace(/&/g, "-and-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
  }
}
