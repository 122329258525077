import * as querystring from "querystring"
import * as React from "react"
import { Button, Col, Container, Input, Label, Row } from "reactstrap"

interface IPlaceSearchBarProps {
  onSearch: (query: string) => void,
}

interface IPlaceSearchBarLocalState {
  name: string,
}

enum IField {
  name = "name",
}

class PlaceSearchBar extends React.Component<IPlaceSearchBarProps, IPlaceSearchBarLocalState> {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
    }
  }

  public onChange = (field: IField) => (event: any) => {
    const value = event.currentTarget.value

    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  public buildQueryString() {
    return querystring.stringify(this.state)
  }

  public search = () => {
    const query = this.buildQueryString()
    this.props.onSearch(query)
  }

  public render() {
    return (
      <Container>
        <Row>
          <Col>
            <Label>Name</Label>
            <Input
              type="text"
              placeholder="Name"
              onChange={this.onChange(IField.name)}
            />
            <br/>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="success" onClick={this.search}>Search</Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

export { PlaceSearchBar }
