export interface IRemoteFile {
  id?: string
  name: string
  bucket: string,
  mimeType: string,
}

export enum IFileUploadFormDataFields {
  bucket = "bucket",
  key = "key",
  policy =  "policy",
  amzAlgorithm = "x-amz-algorithm",
  amzCredential = "x-amz-credential",
  amzDate = "x-amz-date",
  amzSignature = "x-amz-signature",
  file = "file",
}

export interface IFileUploadPayload {
  [IFileUploadFormDataFields.bucket]: string
  [IFileUploadFormDataFields.key]: string
  [IFileUploadFormDataFields.policy]: string
  [IFileUploadFormDataFields.amzAlgorithm]: string
  [IFileUploadFormDataFields.amzCredential]: string
  [IFileUploadFormDataFields.amzDate]: string
  [IFileUploadFormDataFields.amzSignature]: string
  [IFileUploadFormDataFields.file]: File,
}

export interface IUploadFile {
  file: File,
  bucket: string,
}
