import React from "react"

const NoContent = () => {
  return (
    <div className="fa-5x">
      <div className="no-content-container">
        <i className="icon-user-unfollow" />
        <p>
          <i>"I'm sorry Dave, I'm afraid I can't do that..."</i>
        </p>
        <h3>No Content to Display</h3>
        <p id="bot">Try using less strict search filters</p>
      </div>
    </div>
  )
}

export default NoContent
