import ReactGA from 'react-ga'
import React from 'react'
import PropTypes from 'prop-types'
import { TRACKING_ID } from "~/config/env"

const dev = process.env.NODE_ENV !== 'production'

!dev && ReactGA.initialize(TRACKING_ID)

export default Component =>
  class WithAnalytics extends React.Component {
    static propTypes = {
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
    }

    componentDidMount() {
      const page = this.props.location.pathname
      this.trackPage(page)
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname
      if (currentPage !== nextPage) this.trackPage(nextPage)
    }

    trackPage = page => {
      if (!dev) {
        ReactGA.set({ page })
        ReactGA.pageview(page)
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }
