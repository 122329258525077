import * as React from "react"

import { Card, CardBody } from "reactstrap"
import { NewPlaceForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const NewPlaceFormScreen: React.SFC<{}> = (
  props,
): React.ReactElement<any> => {
  const renderNewPlaceForm = () => (
    <Card>
      <CardBody>
        <h2>New Place</h2>

        <NewPlaceForm />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen {...props} renderListComponent={renderNewPlaceForm} />
  )
}
