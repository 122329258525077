import { IRemoteFile } from "~/common/interfaces"
import {
  CONNECT_STORAGE_SERVICE_URL,
} from "~/config/env"

const constructUploadFileUrl = ({ name, bucket }: {
  name: string,
  bucket: string,
}) => `${CONNECT_STORAGE_SERVICE_URL}/${bucket}/${name}`

const filterOutInvalidFiles = (files: Array<IRemoteFile>) => {
  const nameField = "name"
  const bucketField = "bucket"

  return files.filter((file) => {
    return !!file && nameField in file && bucketField in file
  })
}

const buildImageUrl = ({ bucket, id }): string => {
  return `${CONNECT_STORAGE_SERVICE_URL}/${bucket}/${id}`
}

export {
  constructUploadFileUrl,
  filterOutInvalidFiles,
  buildImageUrl,
}
