import React from 'react'
import PropTypes from 'prop-types'
import { Col, Input, Label } from 'reactstrap'

const NumberInput = props => {
  return (
    <Col>
      <Label className="section-title">{props.labelText}</Label>
      <Input
        type="number"
        name={props.name}
        placeholder={props.placeHolderText}
        onChange={e => props.handleChange(e)}
        value={props.value}
      />
    </Col>
  )
}

NumberInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeHolderText: PropTypes.string,
  labelText: PropTypes.string,
  handleChange: PropTypes.func,
}

export default NumberInput
