export const PAGINATION_RESULTS_PER_PAGE = 20
export const SKILLS_LIMIT = 30
export const TOP_SKILLS_LIMIT = 5
export const DATE_FORMAT = "DD/MM/YYYY"
export const DATETIME_FORMAT = "DD/MM/YYYY HH:MM"
export const ASC = "ASC"
export const DESC = "DESC"
export const DISABLED_SORT_OPTIONS = [
        "STACK",
        "ASSIGNED CONTACT",
        "NAME",
        "TYPE",
        "LOCATION",
        "BIO"]
export const DEBOUNCE_DELAY = 300
export const DEFAULT_SORT_TYPE = "SORT_BY_SCORE"
export const SERVICE_DESK_URL = "https://expertlead.atlassian.net/servicedesk/customer/portals"
