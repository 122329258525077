/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { getPublicProfileUrl } from '~/helpers';
import { authState } from '~/samx/states/auth';

const extractSkillName = ({ skillName }) => skillName;

const joinSkills = (skills) => skills.map(extractSkillName).join(', ');

const ProfileTableRow = (props) => {
    const skills =
        props.rowData.skills && props.rowData.skills.length > 0
            ? joinSkills(props.rowData.skills)
            : 'No Skills Added';
    const focusRoleName = props.rowData.focusRole
        ? props.rowData.focusRole.name
        : '';
    return (
        <tr>
            <td className='dev-row'>
                {props.rowData.firstName} {props.rowData.lastName}
            </td>
            <td className='dev-row'>{focusRoleName}</td>
            <td className='dev-row'>{props.rowData.location.name}</td>
            <td className='dev-row'>{skills}</td>
            <td className='dev-row'>{props.rowData.pool}</td>
            <td className='dev-row'>
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={`${getPublicProfileUrl(authState.organizationId)}/${
                        props.rowData.slug
                    }`}
                >
                    <i className='fa fa-link fa-2x'></i>
                </a>
                <Link to={`./profile/edit/${props.rowData.id}`}>
                    <i className='fa fa-edit fa-2x'></i>
                </Link>
                <Link to={`./profile/status/${props.rowData.id}`}>
                    <i className='fa fa-info-circle fa-2x'></i>
                </Link>
            </td>
        </tr>
    );
};

ProfileTableRow.propTypes = {
    rowData: PropTypes.object
};

export default ProfileTableRow;
