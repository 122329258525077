import { Propose } from "samx"

import { IAuthUser } from "~/common/interfaces"
import { GoogleAuthApi } from "~/lib/google"
import { redirectTo } from "~/samx/actions"
import { ForgetUser, NewUser, NoUser } from "~/samx/proposals/user"
import { authenticateUser } from "~/services/api"
import { selectAuthStatus, selectAuthUser } from "../selectors/auth"

const proposer = Propose("user")

const login = async ({
  email: googleEmail,
  accessToken: googleAccessToken,
  firstName: googleFirstName,
  lastName: googleLastName,
  imageUrl: googleImageUrl,
}: {
  email: string,
  accessToken: string,
  firstName: string,
  lastName: string,
  imageUrl: string,
}) => {
  try {
    const {
      data: {
        id,
        accessToken,
        organizationId,
        createdAt,
        email,
        firstName,
        lastName,
      },
    } = await authenticateUser({
      email: googleEmail,
      accessToken: googleAccessToken,
      firstName: googleFirstName,
      lastName: googleLastName,
      imageUrl: googleImageUrl,
    }) as any

    if (!!id) {
      proposer(
        NewUser({
          id,
          accessToken,
          organizationId,
          createdAt,
          email,
          firstName,
          lastName,
          imageUrl: googleImageUrl,
        } as any),
      )
    } else {
      throw new Error("Server responded successfully, but no userId is present in the response")
    }
  } catch (error) {
    if (error.response && error.response.status >= 500) {
      redirectTo("/500")
    } else if (error.response && error.response.status === 401) {
      redirectTo("/auth/unauthorized")
    } else if (typeof error === "string") {
      console.error(error)
    }
  }
}

const logout = async () => {
  try {
    await GoogleAuthApi.signOut()
  } catch (e) {
    console.error("error from logout action", {
      error: {
        "google-auth-api.signOut": e,
      },
    })
  } finally {
    proposer(NoUser())
  }
}

const flushCachedSession = () => {
  proposer(ForgetUser())
}

const getCachedSession = () => {
  const hasCachedSession = selectAuthStatus() === true
  return hasCachedSession
    ? selectAuthUser()
    : null
}

const updateCachedSession = (user: IAuthUser) => {
  proposer(
    NewUser(user),
  )
}

export {
  login,
  logout,
  flushCachedSession,
  getCachedSession,
  updateCachedSession,
}
