import * as React from "react"
import { Link } from "react-router-dom"
import { Button, Col, Row } from "reactstrap"

interface IState {
    clicked: boolean,
}

class CancelButton extends React.Component<{}, IState> {
  constructor(props) {
    super(props)
    this.state = {
        clicked: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  public handleClick() {
      this.setState({ clicked: !this.state.clicked })
  }

  public render() {
    const cancelButton = (
        <Button
          color="danger"
          type="submit"
          onClick={this.handleClick}
        >
          Cancel
        </Button>
    )

    const confirmationButtons = (
      <div>
        <Row>
          <Col
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Link
              to="/connect/home"
            >
              <Button
                outline={true}
                color="danger"
                type="submit"
              >
                Yes
              </Button>
            </Link>
            <Button
              outline={true}
              type="submit"
              style={{ marginLeft: "5px" }}
              onClick={this.handleClick}
            >
              No
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "center" }}>
            <p>Are you sure?</p>
          </Col>
        </Row>
      </div>
    )

    return (
      this.state.clicked ? confirmationButtons : cancelButton
  )
  }
}

export { CancelButton }
