import { isEmpty } from "lodash/fp"
import * as React from "react"
import { Button, Col, Label, Row } from "reactstrap"

import { AutoCompleteMulti } from "~/components/presenters"
import { SKILLS_LIMIT, TOP_SKILLS_LIMIT } from "~/config/constants"
import { queryFields, queryResource } from "~/services/api"

interface ISkillsProps {
  defaultSkills?: Array<any>
  setSkills: (collection: Array<any>) => any
}
interface ISkillsState {
  skills: Array<any>
}

class Skills extends React.Component<ISkillsProps, ISkillsState> {
  public static getDerivedStateFromProps(nextProps, prevState) {
    const nextIds = nextProps.defaultSkills.map((i) => i.id)
    const prevIds = prevState.skills.map((i) => i.id)
    if (nextIds !== prevIds) {
      return {
        skills: nextProps.defaultSkills,
      }
    }
    return null
  }

  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()
    this.handleChange = this.handleChange.bind(this)
    this.handleAddSkill = this.handleAddSkill.bind(this)
    this.onSkillSelected = this.onSkillSelected.bind(this)
  }

  public onSkillSelected(index) {
    return (input, data) => {
      try {
        const { value } = data[0]
        const skills = this.state.skills.map((skill, sidx) => {
          if (index !== sidx) {
            return skill
          }
          return { ...skill, ...value, skillId: value.id }
        })
        const orderedSkills = this.assignOrder(skills)
        this.props.setSkills(orderedSkills)
      } catch (e) {
        console.error(e)
      }
    }
  }

  public resultsMapper = ({ data }) => {
    const existentSkills = this.state.skills.map((skill) => skill.skillName)
    let items = []

    try {
      items = data.data.map((item, index) => ({
        label: item.name,
        isDisabled: existentSkills.includes(item.name),
        value: {
          id: item.id,
          name: item.name,
          skillName: item.name,
          order: index,
        },
      }))
    } catch (e) {
      console.error(e)
    }
    return items
  }

  public handleChange(i) {
    return (evt) => {
      const skills = this.state.skills.map((s, sidx) => {
        if (i !== sidx) {
          return s
        }
        return { ...s, [evt.target.name]: evt.target.value }
      })
      const orderedSkills = this.assignOrder(skills)

      this.props.setSkills(orderedSkills)
    }
  }

  public handleAddSkill() {
    const skills = [...this.state.skills, {}]

    this.props.setSkills(skills)
  }

  public handleRemoveSkill(idx) {
    const skills = this.state.skills.filter((s, sidx) => idx !== sidx)
    const orderedSkills = this.assignOrder(skills)

    this.props.setSkills(orderedSkills)
  }

  public assignOrder(skills: any) {
    let order = 0
    return skills.map((s) => {
      if (isEmpty(s)) {
        return {}
      } else {
        order++
        return { ...s, order }
      }
    })
  }

  public clearForm(index) {
    const skills = this.state.skills.map((skillObj, skillIndex) =>
      skillIndex === index ? {} : skillObj,
    )

    this.props.setSkills(skills)
  }

  public render() {
    return (
      <Col>
        <Label className="section-title">Skills</Label>
        {this.state.skills.map((skill, idx) => (
          <Row className="multiField" key={skill.id}>
            <Col className="col-sm-7">
              <AutoCompleteMulti
                resourceName="category"
                resourceField="name"
                stateName=""
                resourceQueryMethod={queryResource}
                queryFields={queryFields}
                onSelected={this.onSkillSelected(idx)}
                resultsMapper={this.resultsMapper}
                isMulti={false}
                defaultInputValue={skill.skillName}
                defaultValue={this.resultsMapper({
                  data: {
                    data: [
                      {
                        id: skill.skillId,
                        name: skill.skillName || skill.name,
                        skillName: skill.skillName || skill.name,
                      },
                    ],
                  },
                })}
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                {idx > TOP_SKILLS_LIMIT - 1 ? (
                  "Skill Name"
                ) : (
                  <React.Fragment>
                    <i className="fa fa-star" style={{ color: "#f9d224" }} />
                    &nbsp;Top Skill Name
                  </React.Fragment>
                )}
              </small>
            </Col>
            <Col className="col-sm-3">
              <input
                name={"startedAt"}
                type="date"
                className="form-control"
                onChange={this.handleChange(idx)}
                value={skill.startedAt}
                min="1900-01-01"
                max="9999-01-01"
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                {idx > TOP_SKILLS_LIMIT - 1
                  ? "Started Using Professionally"
                  : "Started Using Professionally*"}
              </small>
            </Col>
            <Col>
              <i
                className="fa fa-trash fa-2x"
                onClick={
                  this.state.skills.length > 1
                    ? this.handleRemoveSkill.bind(this, idx)
                    : this.clearForm.bind(this, idx)
                }
                style={{ color: "grey", cursor: "pointer" }}
              />
            </Col>
          </Row>
        ))}
        <Button
          className="alert-success"
          onClick={this.handleAddSkill}
          disabled={this.state.skills.length >= SKILLS_LIMIT}
        >
          Add Skill
        </Button>
      </Col>
    )
  }

  private retrieveStateFromProps() {
    return !isEmpty(this.props.defaultSkills)
      ? {
          skills: this.props.defaultSkills as any,
        }
      : {
          skills: [{}],
        }
  }
}

export default Skills
