import * as React from "react"

import {
  DropzoneComponent,
  DropzoneComponentProps,
} from "react-dropzone-component"
import { Col, Label } from "reactstrap"
import { IRemoteFile } from "~/common/interfaces"

import "dropzone/dist/min/dropzone.min.css"
import "react-dropzone-component/styles/filepicker.css"
import { filterOutInvalidFiles } from "~/helpers/file-upload"
import "./FileUpload.css"

interface IFileUploadState {
  files: Array<File | IRemoteFile>,
}

export interface IDropzoneFile {
  name: string,
  url: string,
}

export interface IFileUploadProps {
  label: string
  multiple: boolean
  files: Array<IRemoteFile>,
  onDrop: (files: Array<IRemoteFile | File>) => any | boolean
  onRemove: (file: File) => any
  handleRemove?: boolean
  onExistingFiles: (files: Array<IRemoteFile>) => Array<IDropzoneFile>,
}

class FileUploadClass extends React.Component<
  IFileUploadProps,
  IFileUploadState
> {
  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()

    this.init = this.init.bind(this)
    this.onRemove = this.onRemove.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  public arePropsValid() {
    return Array.isArray(this.props.files)
      && this.props.files.length > 0
  }

  public retrieveStateFromProps(): IFileUploadState {
    return this.arePropsValid() ? {
      files: this.props.files,
    } : {
      files: [],
    }
  }

  public shouldComponentUpdate(_: IFileUploadProps, nextState: IFileUploadState) {
    return this.state.files.length !== nextState.files.length
  }

  public componentDidUpdate(prevProps) {
    const havePropsChanged = this.arePropsValid()
      && (
        prevProps.files !== this.props.files
        || prevProps.files.length !== this.props.files.length
      )

    if (havePropsChanged) {
      const state = this.retrieveStateFromProps()
      this.setState(state)
    }
  }

  public init(dropzoneObject: any) {
    const shouldPreloadExistingFiles = this.props.onExistingFiles && this.state.files.length > 0

    if (shouldPreloadExistingFiles) {
      const files = this.props.onExistingFiles(this.state.files as Array<IRemoteFile>)
      files.forEach(
        (file) => {
          const url = `${file.url}${file.name}`
          dropzoneObject.emit("addedfile", file)
          dropzoneObject.emit("thumbnail", file, url)
          dropzoneObject.emit("complete", file)
        },
      )
    }
  }

  public async onDrop(files: Array<File>) {
    await this.props.onDrop(files)
  }

  public onRemove(file: File) {
    this.props.onRemove(file)
  }

  public componentWillUnmount() {
    // Make sure to revoke the data uris to avoid memory leaks
    // @ts-ignore
    filterOutInvalidFiles(this.state.files).forEach((f: File) =>
      // @ts-ignore
      URL.revokeObjectURL(f.preview),
    )
  }

  public render() {
    const showFiletypeIcon = this.state.files.length === 0

    const dropzoneProps: DropzoneComponentProps = {
      config: {
        iconFiletypes: [".jpg", ".png", ".gif"],
        showFiletypeIcon,
        postUrl: "no-url",
      },
      djsConfig: {
        autoProcessQueue: false,
        uploadMultiple: this.props.multiple,
        addRemoveLinks: true,
      },
      eventHandlers: {
        init: this.init,
        addedfiles: this.onDrop,
        removedfile: this.onRemove,
      },
    }

    return (
      <Col>
        <Label className="section-title" for="exampleFile">
          {this.props.label}
        </Label>
        <DropzoneComponent {...dropzoneProps} />
      </Col>
    )
  }
}

export const FileUpload = FileUploadClass
