import * as React from "react"

import { flow } from "lodash/fp"
import { View } from "samx"
import { SkillsConnectTable } from "~/components/presenters/Table/SkillsConnectTable"
import { connectState } from "~/samx/states/connect"

import { addLifecyleMethods } from "~/helpers/common"
import { getSkills } from "~/samx/actions/collections"

const componentDidMount = async () => {
  await getSkills()
}

const skillsTable: React.SFC<{}> = (): React.ReactElement<{}> => {
  return (
    <SkillsConnectTable
      list={connectState.skills}
    />
  )
}

export const SkillsTable: React.SFC<{}> = flow(
  addLifecyleMethods({ componentDidMount }),
  View,
)(skillsTable) as any
