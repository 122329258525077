import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const PlacesTableRow = props => {

  return (
    <tr>
      <td className="dev-row">{props.rowData.name} {props.rowData.lastName}</td>
      <td className="dev-row">{props.rowData.city.name}</td>
      <td className="dev-row">{props.rowData.logo}</td>
      <td className="dev-row">
        <Link to={`./profile/${props.rowData.id}`}>
          <i className="fa fa-edit fa-2x"></i>
        </Link>
      </td>
    </tr>
  )
}

PlacesTableRow.propTypes = {
  rowData: PropTypes.object,
}

export default PlacesTableRow
