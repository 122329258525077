import * as React from "react"

import { flow } from "lodash/fp"
import { Link } from "react-router-dom"
import { Button, CardBody } from "reactstrap"
import { View } from "samx"
import { Datatable } from "~/components/presenters"
import { addLifecyleMethods } from "~/helpers/common"
import { getSpokenLanguages } from "~/samx/actions/collections"
import { connectState } from "~/samx/states/connect"

const componentDidMount = () => {
  getSpokenLanguages()
}

const spokenLanguagesTable: React.SFC<{}> = (): React.ReactElement<{}> => {

  const datatableProps = {
    list: connectState.spokenLanguages,
    columns: [
      "name",
    ],
    columnsHeads: [
      "Name",
    ],
  }

  return (
    <Datatable
      {...datatableProps}
    />
  )
}

export const SpokenLanguagesTable: React.SFC<{}> = flow(
  addLifecyleMethods({ componentDidMount }),
  View,
)(spokenLanguagesTable) as any
