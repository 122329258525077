import * as React from "react"

const CoreUiIconsPro = (): React.ReactElement<any> => (
  <div>
    <h4>Summary</h4>

    <p>
      This is a legal agreement between you, the customer, and creativeLabs Lukasz Holeczek, the seller. By downloading
      icons from <a href="https://coreui.io/icons/pro/">https://coreui.io/icons/pro/</a> you agree to be bound by the
      terms of this license terms.
    </p>

    <p>
      The license is non-exclusive, non-sublicensable and non-transferable and it is always tied with one email address,
      unique order ID and the name of the customer.
    </p>

    <h4>Manage Seats</h4>

    <p>
      The Customer’s Seat Count is the number of licenses the Customer bought through the
      <a href="https://coreui.io/icons/pro/">
        https://coreui.io/icons/pro/
      </a>.
    </p>

    <section>
      <h4>Things you can do with the CoreUI Icons PRO</h4>

      <ol>
        <li>You may use icons in personal or commercial projects, repeatedly and you do not need to buy an extra license
          for every new project.
        </li>
      </ol>
    </section>

    <section>
      <h4>Things you can’t do with the CoreUI Icons PRO</h4>

      <ol>
        <li>You cannot use icons in any product intended for further processing or where your customer is not a final
          owner. It is strictly prohibited to bundle icons in any HTML themes, frameworks, as a library of shapes/images
          or wherever icons would generate a significant value of the final product.
        </li>

        <li>Also, it isn’t possible to use them on a product where the icons would be used repeatedly in larger series.
          For example as prints of t-shirts, mugs, posters or any other product which have a character of repeated
          production.
        </li>

        <li>It is not allowed to resell the icons (license) as such, because the icons remain the intellectual property
          of the author.
        </li>
      </ol>
    </section>

    <p>
      COREUI ICONS PRO ARE PROVIDED “AS THEY ARE”, WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT
      LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT
      SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
      OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER
      DEALINGS IN THE SOFTWARE. CREATIVELABS IS NOT LIABLE FOR ANY DAMAGES OR LOSS PROFITS CAUSED OF ANY DEFECTS IN
      THESE ICON SETS.
    </p>
  </div>
)

export { CoreUiIconsPro }
