import * as React from "react"

import { flow } from "lodash/fp"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { ICity } from "~/common/interfaces"
import { CitiesDropdown } from "~/components/presenters"
import { PAGINATION_RESULTS_PER_PAGE } from "~/config/constants"
import { arrifyEnumeration, buildProfileQueryString, getFullCityName, removeMetaKeysIfIsEmptyParams } from "~/helpers"
import { saveQueryMeta, saveQueryParams } from "~/samx/actions"
import { listFilteredProfiles } from "~/samx/actions/collections"
import { queryFields, queryResource } from "~/services/api"
import { AutoCompleteMulti, ISelectItem } from "./AutoCompleteMulti"

export interface ISearchBarLocalState {
  name: string,
  countriesId: Array<string>,
  citiesId: Array<string>,
  pool: Array<string>,
  referralCode: string,
  skillsId: Array<string>,
  testedSkill: Array<string>,
  hourlyRateMin: string,
  hourlyRateMax: string,
  spokenLanguagesId: Array<string>,
  page?: number,
  perPage?: number,
}

enum IField {
  name = "name",
  countriesId = "countriesId",
  citiesId = "citiesId",
  pool = "pool",
  referralCode = "referralCode",
  skillsId = "skills",
  testedSkill = "testedSkill",
  hourlyRateMin = "hourlyRateMin",
  hourlyRateMax = "hourlyRateMax",
  spokenLanguagesId = "spokenLanguagesId",
}

enum IPools {
  emerging = "Emerging",
  senior = "Senior",
  principal = "Principal",
}

class SearchBar extends React.Component<{}, ISearchBarLocalState> {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      countriesId: [],
      citiesId: [],
      pool: [],
      referralCode: "",
      skillsId: [],
      testedSkill: [],
      hourlyRateMin: "",
      hourlyRateMax: "",
      spokenLanguagesId: [],
      perPage: PAGINATION_RESULTS_PER_PAGE,
    }

    this.onChange = this.onChange.bind(this)
    this.onSelect = this.onSelect.bind(this)
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this)
    this.setCitiesId = this.setCitiesId.bind(this)
  }

  public onChange = (field: IField) => (event: any) => {
    const { value } = event.target

    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  public handleCheckboxClick(e) {
    const pool = this.state.pool
    const updatedPool = pool.indexOf(e.target.name) > -1 ?
      pool.filter((i) => i !== e.target.name)
      :
      [...pool, e.target.name]

    this.setState({
      [IField.pool]: updatedPool,
    })
  }

  public onSelect(field: IField, value: Array<ISelectItem>) {
    const selectedValues = value.map((v) => v.value)
    this.setState((prevState) => ({
      ...prevState,
      [field]: selectedValues,
    }))
  }

  public setCitiesId(input, cities: Array<ICity | any>) {
    const citiesIds = cities.map(({ id }) => id)
    this.setState(
      (prevState) => ({
        ...prevState,
        [IField.citiesId]: citiesIds,
      }),
    )
  }

  public handleSubmit = (event: any) => {
    event.preventDefault()
    const queryProfile = {
      ...this.state,
      page: 1,
    }

    const query = flow(
      removeMetaKeysIfIsEmptyParams,
      buildProfileQueryString,
    )(queryProfile)

    saveQueryMeta({ total: -1 })
    listFilteredProfiles(query)
    saveQueryParams(queryProfile)
  }

  public resultsMapper = (res) => {
    return res && res.data && res.data.data ? res.data.data.map(
      (item) => ({ label: item.name, value: item.id }),
    ) : []
  }

  public resultsMapperCities = (res) => {
    const newItems = res.data.data.map((item) => (
      { label: getFullCityName(item), value: item.id }
    ))
    return newItems
  }

  public renderCheckbox = (pools) => {
    return pools.map((st: string) => {
      return (
        <Col key={st}>
          <FormGroup
            check={true}
            className="checkbox"
          >
            <Label className="form-check-label">
              <Input
                className="form-check-input"
                type="checkbox"
                name={st.toUpperCase()}
                onChange={this.handleCheckboxClick}
                disabled={false}
                defaultChecked={false}
              />
              {st}
            </Label>
          </FormGroup>
        </Col>
      )
    })
  }

  public render() {
    return (
      <Form
        className="container"
        onSubmit={this.handleSubmit}
      >
        <Row>
          <Col>
          <Label>Name</Label>
            <Input
              type="text"
              placeholder="Name"
              onChange={this.onChange(IField.name)}
            />
          </Col>
          <Col>
          <Label>Referral Code</Label>
            <Input
              type="text"
              placeholder="Referral Code"
              onChange={this.onChange(IField.referralCode)}
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <Label>Rate</Label>
                <Input
                  type="number"
                  min="0"
                  placeholder="Min"
                  onChange={this.onChange(IField.hourlyRateMin)}
                />
              </Col>
              <Col>
              <Label>&nbsp;</Label>
                <Input
                  type="number"
                  min="0"
                  placeholder="Max"
                  onChange={this.onChange(IField.hourlyRateMax)}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Label>&nbsp;</Label>
            <Row>
              {this.renderCheckbox(arrifyEnumeration(IPools))}
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <Label>Skills</Label>
            <AutoCompleteMulti
              resourceName="category"
              resourceField="name"
              stateName="skillsId"
              resourceQueryMethod={queryResource}
              queryFields={queryFields}
              onSelected={this.onSelect}
              resultsMapper={this.resultsMapper}
              isMulti={true}
            />
          </Col>
          <Col>
            <Label>Languages</Label>
            <AutoCompleteMulti
              resourceName="language"
              resourceField="name"
              stateName="spokenLanguagesId"
              resourceQueryMethod={queryResource}
              queryFields={queryFields}
              onSelected={this.onSelect}
              resultsMapper={this.resultsMapper}
              isMulti={true}
            />
          </Col>
          </Row>
          <br/>
          <Row>
          <Col>
            <Label>Country</Label>
            <AutoCompleteMulti
              resourceName="country"
              resourceField="name"
              stateName="countriesId"
              resourceQueryMethod={queryResource}
              queryFields={queryFields}
              onSelected={this.onSelect}
              resultsMapper={this.resultsMapper}
              isMulti={true}
            />
          </Col>
          <Col>
            <CitiesDropdown
              label={"City"}
              isMulti={true}
              onChange={this.setCitiesId}
            />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col>
            <Button type="submit" color="success">Search</Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

export { SearchBar }
