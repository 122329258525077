import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import normalizeUrl from 'normalize-url'
import { EHireable, EType } from "~/components/presenters/FilterBar/FilterSideBar"
import { AssignButton } from "~/components/presenters/Buttons/AssignButton"
import { Switcher } from "~/components/hocs"

const TableRow = (props) => {

  const map = {
    [EHireable.filterYes]: { text: "YES", color: "success" },
    [EHireable.filterNo]: { text: "NO", color: "danger" },
    [EType.filterFreelancer]: { text: "YES", color: "success" },
    [EType.filterEmployee]: { text: "NO", color: "danger" },
    [EType.filterUnknown]: { text: "Unknown", color: "secondary" },
  }

  const {
    skills: devSkills = [],
  } = props.dev

  const score = Number.parseFloat(props.dev.score.value).toFixed(2) || 0
  const freelancerName = props.dev.name ? props.dev.name : props.dev.username
  const normalizeWebsiteUrl = (url) => {
    try {
      return normalizeUrl(url)
    } catch (e) {
      return ""
    }
  }

  return (
    <tr>
      <td className="dev-row">
        <Link
          to={`./supplybooster/profile/${props.dev.id}${props.scoreSkills}`}
          target="_blank"
        >
          {freelancerName}
        </Link>
      </td>
      <td className="dev-row">
        {props.dev.websiteUrl &&
          <a
            href={normalizeWebsiteUrl(props.dev.websiteUrl)}
            target="_blank"
            rel="noopener noreferrer">
            Website
          </a>
        }
      </td>
      <td className="dev-row">{props.dev.location}</td>
      <td className="dev-row">{devSkills.join(', ')}</td>
      <Switcher
        shouldRenderComponent={props.shouldRenderScoreFields}
        component={
          <td className="dev-row">{score}</td>
        }
      />
      <td className="dev-row">
        <Badge color={map[props.dev.isHireable].color}>{map[props.dev.isHireable].text}</Badge >
      </td>
      <td className="dev-row">
        <Badge color={map[props.dev.type].color}>{map[props.dev.type].text}</Badge >
      </td>
      <td className="dev-row__bio">
        {props.dev.bio}
      </td>
      <td className="dev-row">
        <AssignButton
          contributor={props.dev}
          handleAssignToMe={props.handleAssignToMe}
          handleUnassign={props.handleUnassign}
        />
      </td>
    </tr>
  )
}

TableRow.propTypes = {
  dev: PropTypes.object,
  pageChange: PropTypes.func,
  pageNumber: PropTypes.number,
  handleAssignToMe: PropTypes.func,
  handleUnassign: PropTypes.func,
  shouldRenderScoreFields: PropTypes.bool,
  scoreSkills: PropTypes.array,
}

export default TableRow
