import React, { Component } from "react"

import PropTypes from "prop-types"

import { RichTextArea, AutoCompleteMulti, CitiesDropdown } from "~/components/presenters"
import { queryFields, queryResource } from "~/services/api"
import { Col, Row, Label, Button } from "reactstrap"
import { omit, isEqual } from "lodash/fp"
import { addKeys } from "~/helpers"
import {EPlaceStatus} from "~/common/interfaces"

class Educations extends Component {
  retrieveStateFromProps() {
    const arePropsValid = Array.isArray(this.props.value) && this.props.value.reduce(
      (allElementsAreObjects, element) => allElementsAreObjects
        && typeof element === 'object' && !Array.isArray(element),
      true
    )

    return arePropsValid ? {
      educations: addKeys(this.props.value),
    } : {
      educations: [
        {},
      ],
    }
  }

  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()
    this.handleAddEducation = this.handleAddEducation.bind(this)
    this.onSchoolChanged = this.onSchoolChanged.bind(this)
    this.onCityChanged = this.onCityChanged.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState.educations, this.state.educations)
  }

  omitAutoCompleteProperties(education) {
    return omit([
      "companyName",
      "locationName",
    ])(education)
  }

  deleteEmptyEducations(education) {
    const keys = [
      "startDate",
      "endDate",
      "description",
      "schoolId",
      "degree",
      "cityId",
    ]

    const isEmpty = keys.reduce(
      (areKeysEmpty, key) => key in education
        ? areKeysEmpty && education[key] === "" || education[key] === undefined
        : areKeysEmpty,
      true
    )

    return !isEmpty ? education : {}
  }

  setEducations(educations) {
    const eduWithKeys = addKeys(educations)

    this.setState({
      educations: eduWithKeys,
    }, () => {
      if (this.props.setEducations) {
        const processedEducations = eduWithKeys
          .map(this.omitAutoCompleteProperties)
          .map(this.deleteEmptyEducations)

        this.props.setEducations(processedEducations)
      }
    })
  }

  handleChange(i) {
    return evt => {
      const newEducations = this.state.educations.map(
        (education, sidx) => {
          if (i !== sidx) return education
          return { ...education, [evt.target.name]: evt.target.value }
        }
      )
      this.setEducations(newEducations)
    }
  }

  handleAddEducation() {
    const educations = [
      ...this.state.educations,
      {},
    ]
    this.setEducations(educations)
  }

  handleRemoveEducation(idx) {
    const educations = this
      .state
      .educations
      .filter(
        (s, sidx) => idx !== sidx
      )

    this.setEducations(educations)
  }

  setEducationDescription(idx, value) {
    const educations = this.state.educations.map(
      (education, sidx) => {
        if (idx !== sidx) return education
        return { ...education, description: value }
      }
    )

    this.setEducations(educations)
  }

  filterBySchoolName(schoolName, schools) {
    return schools.filter(
      ({ name }) => name.toLowerCase().startsWith(schoolName.toLowerCase())
    )
  }

  clearForm(index) {
    const educations = this.state.educations.map(
      (educationObj, educationIndex) => educationIndex === index
        ? {
          schoolName: "",
          cityName: "",
          cityCountryName: "",
          cityId: "",
          schoolId: "",
          degree: "",
          startDate: "",
          endDate: "",
          description: "",
        }
        : educationObj
    )

    this.setEducations(educations)
  }

  onSchoolChanged(index) {
    return (input, [ { value: { id: schoolId, name: schoolName } } ]) => {
      if (schoolId) { // id being the only required
        const newEducations = this
          .state
          .educations
          .map(
            (education, sidx) =>
              index !== sidx
                ? education
                : {
                  ...education,
                  schoolId,
                  schoolName,
                },
          )

        this.setEducations(newEducations)
      }
    }
  }

  onCityChanged(index) {
    return (input, [ { id: cityId, name: cityName, country: { name: cityCountryName } } ]) => {
      if (cityId) { // id being the only required
        const newEducations = this
          .state
          .educations
          .map(
            (education, sidx) =>
              index !== sidx
                ? education
                : {
                  ...education,
                  cityId,
                  cityName,
                  cityCountryName,
                },
          )

        this.setEducations(newEducations)
      }
    }
  }

  resultsMapper({ data }) {
    // eslint-disable-next-line array-bracket-newline
    let items = []

    try {
      items = data.data.map((item) => ({
        label: item.name,
        value: {
          id: item.id,
          name: item.name,
        },
      }))
    } catch (e) {
      console.error(e)
    }

    return items
  }

  onDropdownItemSelected(field) {
    return (idx) => (input, data) => {
      try {
        const { value: { id } } = data[0]

        const newEducations = this
          .state
          .educations
          .map(
            (education, sidx) =>
              idx !== sidx
                ? education
                : { ...education, [field]: id  },
          )

        this.setState({ educations: newEducations }, () => {
          this.setEducations(this.state.educations)
        })
      } catch (e) {
        console.error(e)
      }
    }
  }

  render() {
    return (
      <Col>
        <Label className="section-title">Educations</Label>

        {this.state.educations.map((education, idx) => (
          <div key={education.key}>
            <Row className="multiField">
              <Col className="col-sm-6">
                <AutoCompleteMulti
                  resourceName="place"
                  resourceField="name"
                  stateName=""
                  resourceQueryMethod={queryResource}
                  queryFields={queryFields}
                  onSelected={this.onSchoolChanged(idx)}
                  resultsMapper={this.resultsMapper}
                  isMulti={false}
                  defaultInputValue={education.schoolName}
                  defaultValue={this.resultsMapper({
                    data: {
                      data: education? [
                        {
                          id: education.schoolId,
                          name: education.schoolName,
                        },
                      ] : [],
                    },
                  })}
                  extraQueryParams={{ status: EPlaceStatus.published }}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Establishment
                </small>
              </Col>
              <Col className="col-sm-5">
                <input
                  name={"degree"}
                  type="text"
                  className="form-control"
                  placeholder="Qualification Name"
                  onChange={this.handleChange(idx)}
                  value={education.degree}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Qualification
                </small>
              </Col>
              <Col>
                <i
                  className="fa fa-trash fa-2x"
                  onClick={
                    this.state.educations.length > 1
                      ? this.handleRemoveEducation.bind(this, idx)
                      : this.clearForm.bind(this, idx)
                  }
                  style={{ "color": "grey", "cursor": "pointer" }}
                />
              </Col>
            </Row>
            <Row className="multiField">
              <Col className="col-sm-3">
                <input
                  name={"startDate"}
                  type="date"
                  className="form-control"
                  onChange={this.handleChange(idx)}
                  value={education.startDate}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  Start Date
                </small>
              </Col>
              <Col className="col-sm-3">
                <input
                  name={"endDate"}
                  type="date"
                  className="form-control"
                  onChange={this.handleChange(idx)}
                  value={education.endDate}
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  End Date
                </small>
              </Col>
              <Col className="col-sm-5">
                <CitiesDropdown
                  isMulti={false}
                  onChange={this.onCityChanged(idx)}
                  value={this.props.value[idx]
                    ? [{
                      id: education.cityId,
                      name: education.cityName,
                      country: {
                        name: education.cityCountryName,
                      },
                    }]
                    : undefined
                  }
                />
                <small
                  id="passwordHelpBlock"
                  className="form-text text-muted"
                >
                  City
                </small>
              </Col>
            </Row>
            <Row className="multiField">
              <Col className="col-sm-12">
                <RichTextArea
                  name={"description"}
                  className="form-control"
                  updateParent={this.setEducationDescription.bind(this, idx)}
                  value={education.description}
                />
              </Col>
            </Row>
            <br />
          </div>
        ))}
        <br />
        <Button
          className="alert-success"
          onClick={this.handleAddEducation}
        >
          Add Education
        </Button>
      </Col>
    )
  }
}

Educations.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  setEducations: PropTypes.func,
  edit: PropTypes.bool,
}

export default Educations
