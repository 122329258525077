import { isEmpty, isEqual } from "lodash/fp"
import * as React from "react"
import { Button, Col, Label, Row } from "reactstrap"
import { ECommunicationTypes } from "~/common/interfaces"
import { addKeys } from "~/helpers"

interface ICommunication {
  id?: string,
  type?: string,
  value?: string,
  key?: string,
}

interface IProps {
  value?: any,
  setCommunications: any,
}

interface IState {
  communications: Array<ICommunication>,
}

class Communications extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)

    this.state = this.retrieveStateFromProps()
    this.handleAddCommunication = this.handleAddCommunication.bind(this)
  }

  public retrieveStateFromProps() {
    const arePropsValid = Array.isArray(this.props.value)
      && !isEmpty(this.props.value)
      && !isEmpty(this.props.value[0])

    if (arePropsValid) {
      return {
        communications: addKeys(this.props.value),
      }
    } else {
      return {
        communications: [
          {
            value: "",
            type: ECommunicationTypes.email,
          },
        ],
      }
    }
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState.communications, this.state.communications)
  }

  public setCommunications(communications) {
    const commWithKeys = addKeys(communications)

    this.setState({
      communications: commWithKeys,
    }, () => {
      if (this.props.setCommunications) {
        this.props.setCommunications(commWithKeys)
      }
    })
  }

  public handleChange(i) {
    return (evt) => {
      const newCommunications = this.state.communications.map(
        (communication, sidx) => {
          if (i !== sidx) { return communication }
          return { ...communication, [evt.target.name]: evt.target.value }
        },
      )

      this.setCommunications(newCommunications)
    }
  }

  public handleAddCommunication() {
    const communications = [
      ...this.state.communications,
      {},
    ]

    this.setCommunications(communications)
  }

  public handleRemoveCommunication(idx) {
    const communications = this.state.communications.filter(
      (s, sidx) => idx !== sidx,
    )

    this.setCommunications(communications)
  }

  public clearForm(index) {
    const communications = this.state.communications.map(
      (communicationObject, communicationIndex) => communicationIndex === index
        ? {}
        : communicationObject,
    )

    this.setCommunications(communications)
  }

  public render() {
    return (
      <Col>
        <Label className="section-title">Communications</Label>
        {this.state.communications.map((communication: ICommunication, idx) => (
          <Row className="multiField" key={communication.key}>
            <Col className="col-sm-7">
              <input
                name="value"
                className="form-control"
                placeholder="Details of communication..."
                onChange={this.handleChange(idx)}
                value={communication.value || ""}
              />
              <small id="passwordHelpBlock" className="form-text text-muted">
                Details
              </small>
            </Col>
            <Col className="col-sm-3">
              <select
                name={"type"}
                className="form-control"
                id="exampleFormControlSelect1"
                onChange={this.handleChange(idx)}
                value={communication.type}
              >
                <option>-</option>
                <option value={ECommunicationTypes.email}>Email</option>
                <option value={ECommunicationTypes.mobile}>Mobile</option>
                <option value={ECommunicationTypes.website}>Website</option>
                <option value={ECommunicationTypes.phone}>Phone</option>
              </select>
              <small id="passwordHelpBlock" className="form-text text-muted">
                Method of Communication
              </small>
            </Col>
            <Col>
              <i
                className="fa fa-trash fa-2x"
                onClick={
                  idx > 0
                    ? this.handleRemoveCommunication.bind(this, idx)
                    : this.clearForm.bind(this, idx)
                }
                title={idx > 0 ? "Remove additional form" : "Clear the form data"}
                style={{ color: "grey", cursor: "pointer" }}
              />
            </Col>
          </Row>
        ))}
        <Button className="alert-success" onClick={this.handleAddCommunication}>
          Add Communication
        </Button>
      </Col>
    )
  }
}

export { Communications }
