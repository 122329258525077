import * as React from "react"

import { Card, CardBody } from "reactstrap"
import { NewProfileForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const NewProfileFormScreen: React.SFC<{}> = (
  props,
): React.ReactElement<any> => {
  const renderNewProfileForm = () => (
    <Card>
      <CardBody>
        <h2>New Profile</h2>

        <NewProfileForm />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen {...props} renderListComponent={renderNewProfileForm} />
  )
}
