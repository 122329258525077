import * as PropTypes from "prop-types"
import * as React from "react"
import Pagination from "react-js-pagination"

import { flow } from "lodash/fp"
import { difference, forEach, get, isEmpty, keys as ObjectKeys } from "lodash/fp"
import { Card, CardBody, Table } from "reactstrap"
import { View } from "samx"
import { typeProps } from "~/helpers/common"
import { InvalidRenderKeyType, NonAllowedRenderKey } from "./errors"

const uncappedForeach = (forEach as any).convert({ cap: false })

const makeTableHead = (columns: Array<string>) => {
  return (
    <tr>
      {
        columns.map(
          (columnName) => (
            <th key={columnName}> {columnName} </th>
          ),
        )
      }
    </tr>
  )
}

interface IRow {
  id?: string
}

const makeTableRow = (columns: Array<string>, rowData: IRow) => {
  return (
    <tr key={`${rowData.id}`}>
      {
        columns.map(
          (columnName: string) => (
            <td key={columnName}> {get(columnName)(rowData)} </td>
          ),
        )
      }
    </tr>
  )
}

const applyRenderers = (renderCols: object | undefined) => (rowData: object) => {
  let item = rowData

  uncappedForeach((renderer, columnName) => {
    const oldValue = get(columnName)(item)
    item = { ...item, [columnName]: renderer(oldValue, item) }
  })(renderCols)

  return item
}

export interface IDatatableProps {
  list: Array<object>,
  columns: Array<string>,
  columnsHeads: Array<string>,
  renderColumns?: { [columnName: string]: (columnValue: any, listItem: any) => any } | undefined
  renderTopControls?: () => React.ReactNode | undefined,
  renderNewButton?: () => React.ReactNode | undefined,
  pagination?: object,
}

const datatable: React.SFC<IDatatableProps> = ({
  list,
  columns,
  columnsHeads,
  renderColumns,
  renderTopControls,
  renderNewButton,
  pagination,
}: IDatatableProps) => {

  const hasCols = !isEmpty(columns)
  const hasColsHeads = !isEmpty(columnsHeads) && columnsHeads.length === columns.length
  const hasData = !isEmpty(list)
  const hasTopControls = renderTopControls && typeof renderTopControls === "function"

  if (!hasCols) {
    console.warn("Datatable: prop `columns` is empty, expecting an array of strings")
  }

  if (!hasColsHeads) {
    throw new Error("Datatable: props `columns` and `columnsHeads` are of different lengths, expected them to be equal")
  }

  const data = (!isEmpty(renderColumns) && list)
    ? list.map(applyRenderers(renderColumns))
    : list

  if (hasData && hasCols) {
    return (
      <Card className="col-lg mx-auto" id="formContainer">
        {
          renderNewButton ? renderNewButton() : null
        }
        <CardBody>
          {
            hasTopControls && (renderTopControls as any)()
          }
          <Table>
            <thead>
              {makeTableHead(columnsHeads)}
            </thead>
            <tbody>
              {
                data.map(
                  (row) => makeTableRow(columns, row),
                )
              }
            </tbody>
          </Table>
        </CardBody>
        {pagination && <Pagination
          {...pagination}
        />}
      </Card>
    )
  } else {
    return (
      <Card className="col-lg mx-auto" id="formContainer">
        {
          renderNewButton ? renderNewButton() : null
        }
        <CardBody>
          <h3 id="no-item-yet-text">No items yet...</h3>
        </CardBody>
        {pagination && <Pagination
          {...pagination}
        />}
      </Card>
    )
  }
}

const isValidRendersObject = (props, propName, componentName) => {
  const propValue = get(props, propName)
  const keys = ObjectKeys(propValue)
  const nonAllowedKeys = difference(keys, props.columns)
  const hasNonAllowedKeys = !isEmpty(nonAllowedKeys)

  if (hasNonAllowedKeys) {
    return new NonAllowedRenderKey(componentName, propName, nonAllowedKeys)
  }

  forEach(keys as any, (key) => {
    const value = get(propValue, key)

    if (typeof value !== "function") {
      return new InvalidRenderKeyType(componentName, propName, key)
    }
  })
}

const propTypes = {
  list: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.string),
  renderColumns: isValidRendersObject,
}

const Datatable: React.SFC<IDatatableProps> = flow(
  typeProps(propTypes),
  View,
)(datatable) as any

export {
  Datatable,
}
