import { get } from "lodash"
import * as React from "react"
import { Button, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap"
import { View } from "samx"
import { InvitationStatus, IStatusFormProfile, IUser } from "~/common/interfaces"
import { Switcher } from "~/components/hocs"
import { Changelog } from "~/components/presenters"
import { DATE_FORMAT } from "~/config/constants"
import { formatDate, formatUnixDateTime } from "~/helpers"

const styles = {
  textStyles: {
    marginTop: "3px",
    marginLeft: "5px",
  },

  container: {
    display: "flex",
    marginTop: "5px",
  },

  accepted: {
    color: "orange",
    icon: "cui-task fa-2x",
  },

  active: {
    color: "green",
    icon: "cui-circle-check fa-2x",
  },

  deactivated: {
    color: "grey",
    icon: "cui-circle-x fa-2x",
  },

  expired: {
    color: "red",
    icon: "cui-ban fa-2x",
  },

  // invited
  sent: {
    color: "blue",
    icon: "cui-arrow-right fa-2x",
  },

  undefined: {
    color: "grey",
    icon: "cui-ban fa-2x",
  },

  changelogContainer: {
    marginTop: "4vh",
  },
  statusContainer: {
    marginTop: "2vh",
  },
}

const informationText = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
}

export interface IStatusFormProps {
  profile: IStatusFormProfile,
  user: IUser,
  onInputEmailChange: (email: string) => any,
  onSend: () => any,
  isSendDisabled: boolean
  isDeleteProfileEnabled: boolean,
  onDeleteProfile: (id: string) => any,
  shouldRenderInvitationFields: boolean
}

export interface IStatusFormState {
  isDropdownOpen: boolean,
}

class StatusFormClass extends React.Component<IStatusFormProps,
  IStatusFormState> {
  constructor(props) {
    super(props)

    this.state = {
      isDropdownOpen: false,
    }

    this.toggleDropdown = this.toggleDropdown.bind(this)
    this.onInputEmailChangeFactory = this.onInputEmailChangeFactory.bind(this)
  }

  public toggleDropdown() {
    this.setState({
      isDropdownOpen: !this.state.isDropdownOpen,
    })
  }

  public onSendFactory() {
    return (evt) => {
      this.props.onSend()
    }
  }

  public onDeleteProfile(id: string) {
    return (evt) => {
      const deleteProfile = confirm("Are you sure you want to delete the profile?")
      if (deleteProfile) {
        this.props.onDeleteProfile(id)
      }
    }
  }

  public onInputEmailChangeFactory(value: string) {
    return (evt) => {
      this.props.onInputEmailChange(value)
    }
  }

  public renderEmails(emails: Array<string>) {
    return (
      <DropdownMenu>
        {emails.map((email: string, index) => (
          <DropdownItem
            onClick={this.onInputEmailChangeFactory(email)}
            key={`${email}-${index}`}
          >
            {email}
          </DropdownItem>
        ))}
      </DropdownMenu>
    )
  }

  public render() {
    const {
      shouldRenderInvitationFields,
      profile,
      isSendDisabled: isDisabled,
      isDeleteProfileEnabled,
      user,
    } = this.props

    const invitationStatus = get(profile, "invitation.status", "")
    const targetStyle = styles[this.mapStatusToStyle(get(profile, "invitation.status", ""))]

    return (
      <div>
        <Row>
          <Col xs="auto">
            <h2>{`${profile.firstName} ${profile.lastName}`}</h2>
          </Col>
          <Col>
            <Switcher
              shouldRenderComponent={shouldRenderInvitationFields}
              component={
                <React.Fragment>
                  <div style={styles.container}>
                    <i className={targetStyle.icon} style={targetStyle}/>
                    <div style={styles.textStyles}>
                      <p style={targetStyle}>
                        {invitationStatus}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              }
            />
          </Col>
          {
            isDeleteProfileEnabled && <Col xs="auto">
              <Button
                color="danger"
                onClick={this.onDeleteProfile(profile.profileId)}
              >
                Delete Profile
              </Button>
            </Col>
          }
          <Col xs="auto">
            <Button
              color="primary"
              href={`/connect/profile/edit/${this.props.profile.profileId}`}
            >
              To Profile
            </Button>
          </Col>
        </Row>

        <hr/>

        <Col>
          <Switcher
            shouldRenderComponent={shouldRenderInvitationFields}
            component={
              <React.Fragment>
                {profile.emails.length &&
                <Row>
                  <Col xs={3}>
                    <Dropdown isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdown}>
                      <DropdownToggle caret={true} disabled={isDisabled} className="btn-block">
                        {this.props.profile.selectedEmail}
                      </DropdownToggle>

                      {this.renderEmails(this.props.profile.emails)}
                    </Dropdown>
                  </Col>
                  <Col className="pl-0">
                    <Button
                      color={!isDisabled ? "primary" : "secondary"}
                      outline={isDisabled}
                      disabled={isDisabled}
                      onClick={this.onSendFactory()}
                      id="btn-send"
                    >
                      <i className="fa fa-paper-plane"/>
                      &nbsp;
                      Send Invitation email
                    </Button>
                  </Col>
                </Row>

                ||

                <div className="text-muted">
                  Freelancer has not specified any emails
                </div>
                }
              </React.Fragment>
            }
          />

          <Row style={styles.statusContainer}>
            <Col style={informationText as any}>
              <p>
                Created on:
                <br/>
                {
                  formatUnixDateTime(parseInt(this.props.profile.createdAt, 10), DATE_FORMAT)
                }
              </p>
            </Col>

            <Switcher
              shouldRenderComponent={this.props.shouldRenderInvitationFields}
              component={
                <React.Fragment>
                  {shouldRenderInvitationFields && invitationStatus !== InvitationStatus.undefined &&
                  <Col style={informationText as any}>
                    <p>
                      Invited on:
                      <br/>
                      {
                         formatDate({ date: profile.invitation.createdAt as string })}
                    </p>
                  </Col>
                  }

                  {shouldRenderInvitationFields && invitationStatus === InvitationStatus.active &&
                  <Col style={informationText as any}>
                    <p>
                      Active since:
                      <br/>
                      {
                        formatDate({ date: profile.invitation.updatedAt as string })}
                    </p>
                  </Col>
                  }
                  {!!user.lastActiveTime &&
                  <Col style={informationText as any}>
                    <p>
                      Last login:
                      <br/>
                      {
                        formatUnixDateTime(parseInt(user.lastActiveTime, 10))
                      }
                    </p>
                  </Col>
                  }
                </React.Fragment>
              }
            />
          </Row>
        </Col>

        <Row style={styles.changelogContainer}>
          <Col>
            <h4>Changelog</h4>
            <Changelog
              id={profile.profileId}
            />
          </Col>
        </Row>
      </div>
    )
  }

  private mapStatusToStyle(status) {
    const map = {
      [InvitationStatus.accepted]: "accepted",
      [InvitationStatus.invited]: "sent",
      [InvitationStatus.created]: "sent",
      [InvitationStatus.undefined]: "undefined",
      [InvitationStatus.active]: "active",
      [InvitationStatus.expired]: "expired",
    }

    return map[status]
  }

}

export const StatusForm = View(StatusFormClass)
