import { Model } from "samx"
import { PAGINATION_RESULTS_PER_PAGE } from "~/config/constants"

const schema = {
  queryProfile: {
    params: {
      name: "",
      countriesId: [],
      citiesId: [],
      pool: [],
      referralCode: "",
      skillsId: [],
      hourlyRateMin: "",
      hourlyRateMax: "",
      spokenLanguagesId: [],
      page: 1,
      perPage: PAGINATION_RESULTS_PER_PAGE,
    },
    meta: {
      total: 0,
    },
  },
}

const acceptor = (
  accept: (mutation: any) => any,
  proposition: any,
  currentState: any,
): any => {
  const { key } = proposition

  switch (key) {
    case "queryProfile.meta":
      if ("total" in proposition.value) {
        const total = proposition.value.total

        // -1 is used to "reset" the count value to zero
        // this is because of the curret logic where we cannot
        // change the `total` value if it's not currently 0 or -1
        if (total === -1) {
          proposition = {
            ...proposition,
            value: {
              ...proposition.value,
              total: 0,
            },
          }
        } else if (currentState.queryProfile.meta.total !== 0) {
          return
        }
      }

      break

    default:
      break
  }

  accept(proposition)
}

Model("queries", {
  schema,
  acceptor,
})
