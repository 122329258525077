import * as React from "react"

import { Card, CardBody } from "reactstrap"
import { StatusForm } from "~/components/containers"
import { ConnectListingScreen } from "~/components/screens"

export const StatusFormScreen: React.SFC<{}> = (
  props: any,
): React.ReactElement<any> => {
  const renderNewProfileForm = () => (
    <Card>
      <CardBody>
        <StatusForm profileId={props.match.params.profileId} />
      </CardBody>
    </Card>
  )

  return (
    <ConnectListingScreen {...props} renderListComponent={renderNewProfileForm} />
  )
}
